const singleInitialState = {
  singleIsLoading: false,
  singleText: '',
  singleTextValid: null,
  singleLongLink: '',
  singleShortLink: null,
  singleShortLinkError: false,
  singleCopyButtonLoading: false,
  singleCopyButtonText: 'copyLink',
  singleCreateButtonText: 'createLink',
};

const multiInitialState = {
  multiIsLoading: false,
  multiText: '',
  multiTextValid: null,
  multiLongLink: '',
  multiShortLink: null,
  multiShortLinkError: false,
  multiCopyButtonLoading: false,
  multiCopyButtonText: 'copyLink',
  multiCreateButtonText: 'createLink',
};

const initialState = {
  linkBuilderType: 'single', // single or multi
  ...singleInitialState,
  ...multiInitialState,
};

const amazonLinkBuilder = (state = initialState, action) => {
  const { type, payload } = action;

  switch(type) {
    case 'CHANGE_LINK_BUILDER_TYPE': {
      return {
        ...state,
        linkBuilderType: payload.type
      };
    }
    case 'CHANGE_LINK_BUILDER_TEXT': {
      return {
        ...state,
        [`${payload.singleMulti}Text`]: payload.text
      };
    }
    case 'VALIDATE_URL_SUCCESS': {
      return {
        ...state,
        [`${payload.singleMulti}TextValid`]: true,
        [`${payload.singleMulti}LongLink`]: payload.link
      }
    }
    case 'VALIDATE_URL_FAILURE': {
      return {
        ...state,
        [`${payload.singleMulti}TextValid`]: false,
        [`${payload.singleMulti}LongLink`]: ""
      }
    }
    case 'SHORTEN_LINK_FETCHING': {
      return {
        ...state,
        [`${payload.singleMulti}IsLoading`]: true
      };
    }
    case 'SHORTEN_LINK_SUCCESS': {
      return {
        ...state,
        [`${payload.singleMulti}ShortLink`]: payload.shortLink,
        [`${payload.singleMulti}ShortLinkError`]: false,
        [`${payload.singleMulti}CreateButtonText`]: 'createAnotherLink',
        [`${payload.singleMulti}IsLoading`]: false,
      };
    }
    case 'SHORTEN_LINK_ERROR': {
      return {
        ...state,
        [`${payload.singleMulti}ShortLink`]: null,
        [`${payload.singleMulti}ShortLinkError`]: true,
        [`${payload.singleMulti}CreateButtonText`]: 'startOver',
        [`${payload.singleMulti}IsLoading`]: false,
      };
    }
    case 'COPY_LINK_SUCCESS':
      return {
        ...state,
        [`${payload.singleMulti}CopyButtonLoading`]: true,
        [`${payload.singleMulti}CopyButtonText`]: 'copied'
      };
    case 'DISPLAY_LINK_COPY_SUCCESS':
      return {
        ...state,
        [`${payload.singleMulti}CopyButtonLoading`]: false
      };
    case 'START_OVER': {
      if (payload.singleMulti === "single") {
        // reset the single link builder state
        return {
          ...state,
          ...singleInitialState,
          singleIsLoading: true,
        };
      } else if (payload.singleMulti === "multi") {
        // reset multi link builder state
        return {
          ...state,
          ...multiInitialState,
          multiIsLoading: true,
        };
      } else {
        // hmm... not sure, this shouldn't happen, but if it does, just don't break
        return state;
      }
    }
    case 'START_OVER_COMPLETE': {
      if (payload.singleMulti === "single") {
        // reset the single link builder state
        return {
          ...state,
          singleIsLoading: false,
        };
      } else if (payload.singleMulti === "multi") {
        // reset multi link builder state
        return {
          ...state,
          multiIsLoading: false,
        };
      } else {
        // same thought as above
        return state;
      }
    }
    default:
      return state;
  };
};

export default amazonLinkBuilder;
