import React, { Component } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import PageNumbers from "./PageNumbers";
import PrevButton from "./PrevButton";
import NextButton from "./NextButton";
import { gaPagingEvent } from "../../../redux/actions/ga";

/**
 * Pagination Component
 * Expects a page prop for the current page, totalPages prop and a handleClick function
 * Returns the handleClick function with an integer for the desired page number to laod passed as a paramater
 */

class Pagination extends Component {
  constructor(props) {
    super(props);
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  handlePageClick(e) {
    const { target } = e;
    const { handleClick, page, type } = this.props;

    if (typeof handleClick !== "function" || +target.textContent === page) {
      return;
    }

    if (target.className.split(" ").includes("prev")) {
      this.props.dispatch(gaPagingEvent({ pageType: type, page: "previous" }));
      handleClick(page - 1);
    } else if (target.className.split(" ").includes("next")) {
      this.props.dispatch(gaPagingEvent({ pageType: type, page: "next" }));
      handleClick(page + 1);
    } else {
      this.props.dispatch(
        gaPagingEvent({ pageType: type, page: +target.textContent }),
      );
      handleClick(+target.textContent);
    }
  }

  render() {
    const { totalPages = 0, page = 1 } = this.props;

    if (totalPages > 1) {
      return (
        <div className="pagination-wrapper">
          <ul>
            {page !== 1 && <PrevButton handleClick={this.handlePageClick} />}
            <PageNumbers
              page={page}
              totalPages={totalPages}
              handleClick={this.handlePageClick}
            />
            {page < totalPages && (
              <NextButton handleClick={this.handlePageClick} />
            )}
          </ul>
        </div>
      );
    }
    return null;
  }
}

export default connect()(Pagination);

Pagination.propTypes = {
  page: PropTypes.number,
  totalPages: PropTypes.number,
  handleClick: PropTypes.func,
  type: PropTypes.string, // documents, opportunities, etc.
};
