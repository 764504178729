import React from 'react';
import { FormattedMessage } from "react-intl";

const PlaylistHeader = () => {
  return [
    <li key="playlist-name">
      <FormattedMessage id="playlistName" />
    </li>,
    <li key="watch-time">
      <FormattedMessage id="watchTime" />
    </li>
  ];
}

export default PlaylistHeader;
