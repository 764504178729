import React from 'react'
import { FormattedMessage } from "react-intl";

const ThreeColHeader = () => {
  return [
    <li key="title">
      <FormattedMessage id="title" />
    </li>,
    <li key="clicks">
      <FormattedMessage id="clicks" />
    </li>,
    <li key="ctr">
      <FormattedMessage id="ctr" />
    </li>
  ];
}

export default ThreeColHeader;
