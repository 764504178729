import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { toggleMainNav } from "../../redux/actions/ui";
import BREAKPOINTS from "../../constants/breakpoints";
import Tooltip from "../shared/tooltip/Tooltip";

const NavToggle = () => {
  const dispatch = useDispatch();
  const navVisibilityClass = useSelector(
    (state) => state.mainNav.navVisibilityClass,
  );
  const toggleNav = useCallback(() => dispatch(toggleMainNav()), [dispatch]);

  if (window.innerWidth < BREAKPOINTS.DESKTOP) {
    return <span className="fa fa-bars menu-toggle" onClick={toggleNav} />;
  }

  return (
    <Tooltip
      className="nav-tooltip"
      content={
        <FormattedMessage
          id={navVisibilityClass === "opened" ? "hideMenu" : "showMenu"}
        />
      }
      placement="right"
    >
      <div className="menu-toggle-desktop" onClick={toggleNav}>
        <span className="fa fa-angle-double-right"></span>
      </div>
    </Tooltip>
  );
};

export default NavToggle;
