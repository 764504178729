const initialState = {
  loading: true,
  error: false,
  errorMessage: 'There was an error loading this blog post.  Please try again.',
  pageNotFound: false,
  id: null,
  postTitle: '',
  postContent: '',
  postDate: null,
  postAuthor: '',
  featuredImage: null,
};

const blogDetail = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'INITIALIZE_BLOG_DETAIL_PAGE':
      return initialState;

    case 'GET_BLOG_DETAIL_FETCHING':
      return {
        ...state,
        loading: true,
        error: false,
        pageNotFound: false,
      };

    case 'GET_BLOG_DETAIL_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        pageNotFound: payload.pageNotFound || false,
      };

    case 'GET_BLOG_DETAIL_SUCCESS':
      return {
        ...state,
        loading: false,
        error: false,
        pageNotFound: false,
        id: payload.id || null,
        postTitle: payload.post_title || '',
        postContent: payload.post_content || '',
        postDate: payload.post_date || null,
        postAuthor: payload.post_author || '',
        featuredImage: payload.featured_image || null
      };

    default:
      return state;
  };
};

export default blogDetail;
