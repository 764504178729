import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { copyLink } from '../../../redux/actions/linkBuilder';
import { FormattedMessage } from 'react-intl';

class ShortLinkDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.linkRef = React.createRef();
    this.copy = this.copy.bind(this);
  }

  copy() {
    this.props.copyLink(this.linkRef.current, this.props.singleMulti);
  }

  render() {
    const {
      singleMulti,
      shortLink,
      longLink,
      copyButtonText,
      copyButtonLoading,
    } = this.props;

    return (
      <Fragment>
        <div className="short-link-display">
          <input type="text" ref={this.linkRef} className="short-link" defaultValue={shortLink} readOnly />
          <p className="short-link">{shortLink}</p>
          <button className="primary" onClick={this.copy}>
            {copyButtonLoading ? <span className="spinner-border"></span> : <FormattedMessage id={copyButtonText} />}
          </button>
        </div>
        {singleMulti === "single" ? (
          <p className="original-link">
            <span><FormattedMessage id="originalLink" /></span>
            {longLink}
          </p>
        ) : (
          <div className="original-link">
            <span><FormattedMessage id="originalLinks" /></span>
            {longLink.split("\n").map((link, i) => {
              return <p key={i} className="original-link-part">{link}</p>;
            })}
          </div>
        )}
      </Fragment>
    );
  }
};

const mapDispatchToProps = { copyLink };
const mapStateToProps = ({ amazonLinkBuilder }, { singleMulti }) => {
  return {
    shortLink: amazonLinkBuilder[`${singleMulti}ShortLink`],
    longLink: amazonLinkBuilder[`${singleMulti}LongLink`],
    copyButtonLoading: amazonLinkBuilder[`${singleMulti}CopyButtonLoading`],
    copyButtonText: amazonLinkBuilder[`${singleMulti}CopyButtonText`],
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShortLinkDisplay);
