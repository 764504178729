import React from "react";
import { FormattedMessage } from "react-intl";

const noAuthDomains = ["sc", "pc"];

const StatusArea = ({ status, domain, handleClick }) => {
  const cantAuth = noAuthDomains.filter((d) => d === domain).length;
  let statusButtonClass = "primary";
  const statusMessageMap = {
    primary: {
      id: "add",
      defaultMessage: "Add",
    },
    authorized: {
      id: "connected",
      defaultMessage: "Connected",
    },
    secondary: {
      id: "needsAuth",
      defaultMessage: "Needs Auth",
    },
  };

  if (status === true) {
    statusButtonClass = "authorized";
  } else if (status === false) {
    statusButtonClass = cantAuth ? "authorized" : "secondary";
  }

  if (status === true || (status === false && cantAuth)) {
    return (
      <div className={`channel-status ${statusButtonClass}`}>
        <FormattedMessage
          id={statusMessageMap[statusButtonClass]?.id}
          defaultMessage={statusMessageMap[statusButtonClass]?.defaultMessage}
        />
      </div>
    );
  }

  return (
    <button
      className={`channel-status ${statusButtonClass}`}
      onClick={handleClick}
    >
      <i className="fas fa-plus" />
      <FormattedMessage
        id={statusMessageMap[statusButtonClass]?.id}
        defaultMessage={statusMessageMap[statusButtonClass]?.defaultMessage}
      />
    </button>
  );
};

export default StatusArea;
