import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const styles = {
  control: () => ({ width: '100%'})
};

const ReactSelect = props => {
  const { items, selected, handleChange, placeholder, labelText } = props;

  return (
    <div className="desktop-dropdown">
      <label>
        <span className="label">{labelText}</span>
        <Select
          //menuIsOpen={true} //use this to keep the menu open for easier inspection
          styles={styles}
          options={items}
          value={selected}
          onChange={handleChange}
          placeholder={placeholder}
          labelText={labelText || placeholder}
          className="custom-dropdown-desktop"
          classNamePrefix="custom-dropdown"
        />
      </label>
    </div>
  );
};

export default ReactSelect;

ReactSelect.propTypes = {
  labelText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  placeholder: PropTypes.string,
  items: PropTypes.array,
  handleChange: PropTypes.func,
  selected: PropTypes.object,
};
