import React from 'react';
import { FormattedMessage }  from 'react-intl';
import { PropTypes } from 'prop-types';

/*
 * Props:
 * type: "blog post", "opportunity", "resource",
 * container: "dashboard", ...
 * className -> Passed in from ContainerTile
 */
const EmptyTile = (props) => {
  let type = props.type || "item";
  let container = props.container || "";
  let className = props.className || "";

  // There are no ___ right now vs. ___ not available
  let defaultMessage = `${type.charAt(0).toUpperCase() + type.slice(1)} not available`;

  if (container === "dashboard") {
    defaultMessage = `There are no ${type}s right now.`;
    type = `${type}s`;
  } else {
    type = type.charAt(0).toUpperCase() + type.slice(1);
  }

  return (
    <article className={`empty-tile ${className}`}>
      <div className="opaque-container">
        <FormattedMessage
          id={container === "dashboard" ? "emptyDashboardTile" : "emptyContainerTile"}
          defautMessage={defaultMessage}
          values={{ type }}
        />
      </div>
    </article>
  );
};

export default EmptyTile;

EmptyTile.propTypes = {
  type: PropTypes.string,
  container: PropTypes.string,
  className: PropTypes.string,
};
