import api from "./api";
import { gaCreateShortLink } from "./ga";

export const linkBuilderTypeChange = (type) => {
  return (dispatch) => {
    dispatch({ type: "CHANGE_LINK_BUILDER_TYPE", payload: { type } });
  };
};

export const linkBuilderTextChange = (singleMulti, text) => {
  return (dispatch) => {
    // update the text input
    dispatch({
      type: "CHANGE_LINK_BUILDER_TEXT",
      payload: { singleMulti, text },
    });
    if (singleMulti === "single") {
      dispatch(validateSingleAffiliateLink());
    } else if (singleMulti === "multi") {
      dispatch(validateMultiAffiliateLink());
    }
  };
};

export const validateMultiAffiliateLink = () => {
  return (dispatch, getState) => {
    let multiLink = getState().amazonLinkBuilder[`multiText`].slice();

    let links = multiLink.split("\n").filter((l) => l !== "");

    let multiValid = true;
    // make sure each link in the multi text is valid
    links.forEach((link) => {
      if (!/^https:\/\/www\..*/.test(link)) {
        // does this link begin with http://www ? it needs to
        if (/(amazon|joyo).*/i.exec(link)) {
          // wait, but it also needs at least the word amazon or joyo in it somewhere
          link = "https://www." + /(amazon|joyo).*/i.exec(link)[0];
        }
      }

      const tld = link.match(
        /www\.(amazon.com|amazon.co.uk|amazon.de|amazon.fr|amazon.co.jp|amazon.ca|joyo.com.cn|amazon.cn)/i,
      );
      if (!tld || !tld.length) {
        multiValid = false;
      }
    });

    if (multiValid) {
      dispatch({
        type: "VALIDATE_URL_SUCCESS",
        payload: { singleMulti: "multi", link: links.join("\n") },
      });
    } else {
      dispatch({
        type: "VALIDATE_URL_FAILURE",
        payload: { singleMulti: "multi" },
      });
    }
  };
};

export const validateSingleAffiliateLink = () => {
  return (dispatch, getState) => {
    let link = getState().amazonLinkBuilder[`singleText`].slice();

    if (!/^https:\/\/www\..*/.test(link)) {
      // does this link begin with http://www ? it needs to
      if (/(amazon|joyo).*/i.exec(link)) {
        // wait, but it also needs at least the word amazon or joyo in it somewhere
        link = "https://www." + /(amazon|joyo).*/i.exec(link)[0];
      }
    }

    const tld = link.match(
      /www\.(amazon.com|amazon.co.uk|amazon.de|amazon.fr|amazon.co.jp|amazon.ca|joyo.com.cn|amazon.cn)/i,
    );

    if (tld && tld.length) {
      dispatch({
        type: "VALIDATE_URL_SUCCESS",
        payload: { singleMulti: "single", link },
      });
    } else {
      dispatch({
        type: "VALIDATE_URL_FAILURE",
        payload: { singleMulti: "single" },
      });
    }
  };
};

export const createSingleShortLink = () => {
  return (dispatch, getState) => {
    const state = getState().amazonLinkBuilder;
    const affiliateCode = getState().user.affiliateCode;

    if (state.singleTextValid) {
      const longLink = state.singleLongLink;
      const symbol = /\?/.exec(longLink) ? "&" : "?";
      let longUrl = `${longLink}${symbol}tag=${affiliateCode}`;

      if (longUrl.indexOf("http://www.") !== -1) {
        //longUrl = longUrl;
      } else if (longUrl.indexOf("https://www.") !== -1) {
        //longUrl = longUrl;
      } else if (longUrl.indexOf("www.") !== -1) {
        longUrl = "https://" + longUrl;
      } else {
        longUrl = "https://www." + longUrl;
      }

      dispatch(
        gaCreateShortLink({
          s71_urlType: "single",
          s71_longUrl: longUrl,
        }),
      );

      dispatch(shortenUrl(longUrl, "single"));
    }
  };
};

export const copyLink = (link, singleMulti) => {
  return (dispatch) => {
    link.select();
    try {
      document.execCommand("copy");
      dispatch({ type: "COPY_LINK_SUCCESS", payload: { singleMulti } });
      setTimeout(() => {
        dispatch({
          type: "DISPLAY_LINK_COPY_SUCCESS",
          payload: { singleMulti },
        });
      }, 500);
    } catch (err) {
      dispatch({ type: "COPY_LINK_ERROR" });
    }
  };
};

export const resetLinkBuilder = (singleMulti) => {
  return (dispatch) => {
    dispatch({ type: "START_OVER", payload: { singleMulti } });
    setTimeout(() => {
      dispatch({ type: "START_OVER_COMPLETE", payload: { singleMulti } });
    }, 500);
  };
};

export const createMultiShortLink = () => {
  return (dispatch, getState) => {
    const state = getState().amazonLinkBuilder;
    const affiliateCode = getState().user.affiliateCode;

    if (state.multiTextValid) {
      let multiLink = getState().amazonLinkBuilder.multiText.slice();
      let links = multiLink.split("\n").filter((l) => l !== "");

      if (links.length <= 1) {
        // not multi... just single
      } else {
        let firstLink = links[0];
        let firstLinkParts = firstLink.split(/(https?:\/\/)/);
        // need to get amazon.com or amazon.co.uk or whatever
        let baseUrl = firstLinkParts[firstLinkParts.length - 1].split(/www\./);
        baseUrl = baseUrl[baseUrl.length - 1];
        baseUrl = baseUrl.split("/")[0];

        let baseLink = `https://www.${baseUrl}/s/?hidden-keywords=`;

        let asinsCodes = links.map((l) => {
          let code = /([A-Z0-9]{10})/.exec(l)[0];
          return code;
        });

        baseLink += asinsCodes.join("|");

        let symbol = /\?/.exec(baseLink) ? "&" : "?";
        let longUrl = `${baseLink}${symbol}tag=${affiliateCode}`;

        dispatch(
          gaCreateShortLink({
            s71_urlType: "multi",
            s71_longUrl: longUrl,
          }),
        );

        dispatch(shortenUrl(longUrl, "multi"));
      }
    }
  };
};

export const shortenUrl = (url, singleMulti) => {
  return (dispatch) => {
    dispatch({
      type: "SHORTEN_LINK_FETCHING",
      payload: { singleMulti: singleMulti },
    });
    api.post("/api/shorten", { url }).then((resp) => {
      if (!resp.error) {
        dispatch({
          type: "SHORTEN_LINK_SUCCESS",
          payload: { singleMulti, shortLink: resp.data?.data },
        });
      } else {
        dispatch({
          type: "SHORTEN_LINK_ERROR",
          payload: { singleMulti, error: resp?.error },
        });
      }
    });
  };
};
