import api from "./api";
import { getNavItems, removeSiteLoader } from "./ui";
import config from "../../config";

export const checkAuth = () => {
  return (dispatch, getState) => {
    const { siteLoading } = getState().ui;

    return api
      .get("/api/authcheck")
      .then((resp) => {
        if (!resp.data.loggedIn) {
          siteLoading && dispatch(removeSiteLoader());
        } else if (resp.data.loggedIn && !resp.data.matchedGroups?.length) {
          dispatch({ type: "USER_GROUP_AUTH_ERROR" });
        } else {
          dispatch({
            type: "UPDATE_USER_LOGIN_STATUS",
            payload: { status: resp.data.loggedIn },
          });
        }
      })
      .catch((error) => {
        if (error.data.message === "Unauthorized: Unauthorized user group") {
          dispatch({
            type: "USER_GROUP_AUTH_ERROR",
            payload: { error: error.data },
          });
        }
      });
  };
};

export const init = (destinationPage) => {
  return (dispatch) => {
    dispatch(getUserInfo()).then((userInfo) => {
      /* for testing multi affiliate code dropdown
      const affiliateCodeList = [{
        affiliate_id: "code1",
        feed_domain: "yt",
        feed_id: "1234",
        partner: "amazon",
      },{
        affiliate_id: "code2",
        feed_domain: "yt",
        feed_id: "1234",
        partner: "amazon",
      }];*/

      const affiliateCodeList = Object.keys(
        (userInfo?.payload?.affiliates || [])
          .filter((item) => {
            return item.partner === "amazon";
          })
          .reduce((prev, next) => {
            prev[next.affiliate_id] = next;
            return prev;
          }, {}),
      ).map((item) => {
        return {
          value: item,
          label: item,
        };
      });

      const affiliateCode = affiliateCodeList?.[0]?.value;

      //filtered feeds used for reports
      const filteredFeeds = userInfo?.payload?.feeds
        ?.filter((feed) => {
          return feed.domain === "yt" && feed.cms;
        })
        .sort((a, b) => {
          return a.title > b.title;
        });

      const selectedChannel = filteredFeeds?.length ? filteredFeeds[0] : null;
      const { matchedRestrictionGroup } = userInfo?.payload || {};
      const navItems = dispatch(
        getNavItems({ filteredFeeds, matchedRestrictionGroup }),
      )?.payload?.items;

      const payload = {
        ...userInfo.payload,
        destinationPage,
        affiliateCodeList,
        affiliateCode,
        filteredFeeds,
        navItems,
        selectedChannel,
      };
      dispatch({ type: "USER_LOGIN_SUCCESS", payload });
    });
  };
};

/**
 * Login / Logout actions
 */
export const getLoginToken = (referer) => {
  return (dispatch) => {
    dispatch({ type: "GET_AUTH_URL_FETCHING", paylaod: { referer } });

    api
      .post("/api/authurl", { referer })
      .then((resp) => {
        const { url } = resp.data;
        if (!url) {
          return dispatch({
            type: "GET_AUTH_URL_ERROR",
            payload: { error: "No url returned from api" },
          });
        }
        window.open(url, "_self");
      })
      .catch((err) => {
        dispatch({ type: "GET_AUTH_URL_ERROR", payload: err });
      });
  };
};

export const completeLogin = (url) => {
  return (dispatch) => {
    dispatch({ type: "USER_LOGIN_PENDING" });
    api
      .post("/api/login", {
        url: url,
      })
      .then((resp) => {
        if (!resp.data.matchedGroups?.length) {
          return dispatch({ type: "USER_GROUP_AUTH_ERROR" });
        }

        dispatch(init(resp.data.destinationPage));
      })
      .catch((err) => {
        dispatch({ type: "USER_LOGIN_ERROR", payload: err });
      });
  };
};

export const logoutClicked = () => {
  return (dispatch) => {
    dispatch({ type: "USER_LOGOUT_BUTTON_CLICKED" });
  };
};

export const logout = (userGroupAuthError = false, userGroups = []) => {
  return (dispatch, getState) => {
    const userClickedLogoutButton = getState().user.userClickedLogoutButton;
    const environment = process.env.NODE_ENV || "production";

    dispatch({ type: "USER_LOGOUT_FETCHING" });

    api
      .post("/api/logout")
      .then((resp) => {
        const { accessToken, apiKey } = resp?.data || {};
        dispatch({
          type: "USER_LOGOUT_SUCCESS",
          payload: { userGroupAuthError, userGroups },
        });
        if (userClickedLogoutButton) {
          window.location.href = `${config[environment].baseURL}/auth/oauth/logout?access_token=${accessToken}&apikey=${apiKey}`;
        }
      })
      .catch((err) => {
        dispatch({ type: "USER_LOGOUT_ERROR", payload: err });
      });
  };
};

export const getUserInfo = () => {
  return (dispatch) => {
    dispatch({ type: "_GET_USER_INFO_FETCHING" });
    return api
      .get("/api/user/get")
      .then((resp) => {
        let info = resp.data || {};
        return dispatch({ type: "_GET_USER_INFO_SUCCESS", payload: info });
      })
      .catch((err) => {
        return dispatch({ type: "_GET_USER_INFO_ERROR", payload: err });
      });
  };
};

export const setAffiliateCode = (code) => {
  return (dispatch) => {
    dispatch({ type: "SET_AFFILIATE_CODE", payload: { code } });
  };
};

export const monthlyStatementEmailUnsubscribe = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: "MONTHLY_STATEMENT_EMAIL_UNSUBSCRIBE_LOADING" });
      const resp = await api.post(
        "/api/user/monthly-statement-email-unsubscribe",
      );
      if (resp?.data?.success) {
        dispatch({
          type: "MONTHLY_STATEMENT_EMAIL_UNSUBSCRIBE_SUCCESS",
          payload: { resp },
        });
      } else {
        throw resp;
      }
    } catch (error) {
      dispatch({
        type: "MONTHLY_STATEMENT_EMAIL_UNSUBSCRIBE_ERROR",
        payload: { error },
      });
    }
  };
};
