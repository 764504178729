import React, { useState, useRef, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import NavItems from './NavItems';
import BottomNavItems from './BottomNavItems';
import Tooltip from '../shared/tooltip/Tooltip';
import BREAKPOINTS from '../../constants/breakpoints';

const NavContent = () => {
  const navVisibilityClass = useSelector(state => state.mainNav.navVisibilityClass || '');
  const menuItems = useSelector(state => state.mainNav.menuItems || [], shallowEqual);
  const activePage = useSelector(state => state.mainNav.activePage || '');
  const thumbnail = useSelector(state => state.user.thumbnail || null);
  const firstName = useSelector(state => state.user.firstName || '');
  const lastName = useSelector(state => state.user.lastName || '');
  const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`;

  const navRef = useRef(null);
  const [scrollBarWidth, setScrollBarWidth] = useState(0);

  useEffect(() => {
    const newScrollBarWidth = navRef.current ? navRef.current.offsetWidth - navRef.current.clientWidth : 0;
    newScrollBarWidth !== scrollBarWidth && setScrollBarWidth(newScrollBarWidth);
  }, [navVisibilityClass, navRef, scrollBarWidth]);

  const userImageStyle = {
    backgroundImage: `url(${thumbnail})`,
  };
  const navStyle = {
    paddingRight: scrollBarWidth,
    width: `calc(100% + ${scrollBarWidth}px)`,
  };
  const disableTooltip = (navVisibilityClass === 'opened' || window.innerWidth < BREAKPOINTS.DESKTOP);

  return (
    <div className="nav-content">
      <div className="user-info-wrapper">
        <NavLink to="/settings" className="user-info">
          <div className="initials" style={thumbnail ? userImageStyle : {}}>
            <Tooltip
              className="nav-tooltip"
              content={<FormattedMessage id="settings" />}
              placement="right"
              disable={disableTooltip}
            >
              <span>{thumbnail ? '' : initials}</span>
            </Tooltip>
          </div>
          <span className="link-text">{firstName} {lastName}</span>
        </NavLink>
      </div>
      <nav>
        <div ref={navRef} style={navStyle}>
          <ul>
            <NavItems menuItems={menuItems} activePage={activePage} />
          </ul>
          <BottomNavItems />
        </div>
      </nav>
    </div>
  );
};

export default NavContent;
