import en from "./en";
import de from "./de";
import fr from "./fr";

const translations = {
  en, // English
  de, // German
  fr, // French
};

export default translations;
