import React from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { navigateToPath } from "../../../redux/actions/ui";
import Tooltip from "../../shared/tooltip/Tooltip";
import UgcFormContent from "./UgcFormContent";

const UgcFormContainer = () => {
  const dispatch = useDispatch();
  const goToResources = () => dispatch(navigateToPath("/resources"));

  return (
    <div className="ugc-form-container">
      <button className="button pill-button primary" onClick={goToResources}>
        <FormattedMessage id="allResources" defaultValue={`All Resources`} />
      </button>
      <header>
        <h2>
          <FormattedMessage id="reportContentInfringements" />
          <Tooltip
            content={<FormattedMessage id="ugcHeaderTooltip" />}
            placement="top-right"
          >
            <span className="icon">
              <i className="fas fa-question-circle" />
            </span>
          </Tooltip>
        </h2>
      </header>
      <UgcFormContent />
    </div>
  );
};

export default UgcFormContainer;
