import React from "react";
import { useDispatch } from "react-redux";
import AmazonInstructions from "../../../assets/amazonLinkBulderInstructions.pdf";
import { FormattedMessage } from "react-intl";
import { gaClickthroughEvent } from "../../../redux/actions/ga";

const Instructions = () => {
  const dispatch = useDispatch();
  const ga = () => {
    dispatch(
      gaClickthroughEvent({
        page: "opportunities_amazon",
        itemName: "guidelines",
      }),
    );
  };

  return (
    <div className="amazon-link-builder-instructions opaque-container">
      <h3>
        <FormattedMessage id="getStarted" />
      </h3>
      <div className="instruction">
        <div>
          <h5>
            <FormattedMessage id="findAProduct" />
          </h5>
          <FormattedMessage id="theFirstStep" />
        </div>
        <i className="fa fa-search"></i>
      </div>
      <div className="instruction">
        <i className="fa fa-link"></i>
        <div>
          <h5>
            <FormattedMessage id="copyAndPaste" />
          </h5>
          <FormattedMessage id="copyTheLink" />
        </div>
      </div>
      <div className="full-guidelines-button">
        <a
          onClick={ga}
          href={AmazonInstructions}
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="primary pill-button">
            <FormattedMessage id="viewFullGuidelines" />
          </button>
        </a>
      </div>
    </div>
  );
};

export default Instructions;
