const initialState = {
  resource: {
    loading: true,
    error: false,
    errorMessage: '',
    item: {},
  },
  blog: {
    loading: true,
    error: false,
    errorMessage: '',
    item: {},
  },
  opportunity: {
    loading: true,
    error: false,
    errorMessage: '',
    item: {},
  },
};

const dashboard = (state = initialState, action) => {
  const { type, payload } = action;

  switch(type) {
    case 'GET_DASHBOARD_FEATURED_POST_FETCHING': {
      const { key } = payload;
      return {
        ...state,
        [key]: {
          ...state[key],
          loading: true,
          error: false,
          errorMessage: '',
        }
      };
    }
    case 'GET_DASHBOARD_FEATURED_POST_SUCCESS': {
      const { key, item } = payload;
      return {
        ...state,
        [key]: {
          ...state[key],
          loading: false,
          error: false,
          errorMessage: '',
          item: item,
        }
      };
    }
    case 'GET_DASHBOARD_FEATURED_POST_ERROR': {
      const { key } = payload;
      return {
        ...state,
        [key]: {
          ...state[key],
          loading: false,
          error: true,
          errorMessage: `Error loading ${key}. Please try again later!`,
        }
      };
    }
    default:
       return state;
    }
}

export default dashboard;
