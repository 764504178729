import React from "react";
import { connect } from "react-redux";
import FeaturedImage from "../FeaturedImage";
import { PropTypes } from "prop-types";
import EmptyTile from "./EmptyTile";
import PageLoader from "../../loader/PageLoader";
import { FormattedMessage } from "react-intl";
import { gaClickthroughEvent } from "../../../redux/actions/ga";

// We'll eventually need placeholder images in the images directory then import them so might as well mimics that here
// const imagePlaceholder = 'https://www.nintendo.com/content/dam/noa/en_US/hardware/switch/nintendo-switch-new-package/gallery/package_redblue.jpg';

class ContainerTile extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);

    // For testing the featured images since there are currenly none coming back from the API
    // This method could be used for defaulting when a image url is a broken image though
    //this.state = {
    //  featuredImage: null
    //};
  }

  handleClick(e, d) {
    if (
      this.props?.container === "survey" &&
      this.props?.notification_url?.length
    ) {
      // only open the link on button click
      if (e.target.tagName === "BUTTON") {
        this.props.handleTileClick(this.props?.notification_url);
      } else {
        return;
      }
    } else if (this.props?.ID) {
      this.props.handleTileClick(this.props?.ID);
    }

    if (this.props?.container === "dashboard") {
      this.props.dispatch(
        gaClickthroughEvent({
          page: this.props?.container,
          itemName: `featured_${this.props.type}`,
        }),
      );
    } else {
      this.props.dispatch(
        gaClickthroughEvent({
          page: `${this.props?.container}_overview`,
          itemName: this.props?.post_title,
        }),
      );
    }
  }

  createMarkup() {
    let { post_content, textLength = 142 } = this.props;

    let decodedContent = decodeURIComponent(post_content || "")
      .trim()
      .slice(0, textLength)
      .concat("...");
    return { __html: decodedContent };
  }

  render() {
    const {
      post_title,
      featured_image,
      defaultImage,
      type,
      container,
      loading,
      error,
      errorMessage,
    } = this.props;

    const featuredImageUrl = featured_image || defaultImage;
    const hasImageClass = featuredImageUrl ? "has-image" : "";
    const className = this.props.className || "col-12 col-md-6 col-lg-4";

    if (loading || error || this.props.ID) {
      return (
        <article className={`container-tile ${className} ${hasImageClass}`}>
          <div onClick={this.handleClick} className={`opaque-container`}>
            <PageLoader
              loading={loading}
              error={error}
              errorMessage={errorMessage}
              size="medium"
            >
              {featuredImageUrl && (
                <FeaturedImage imageUrl={featuredImageUrl} />
              )}
              <div className="container-tile-content">
                <h4>{post_title}</h4>
                <p dangerouslySetInnerHTML={this.createMarkup()}></p>
              </div>
              {type === "survey" && (
                <button className="secondary button">
                  <FormattedMessage id="surveyButton" />
                </button>
              )}
            </PageLoader>
          </div>
        </article>
      );
    } else {
      // render a placeholder tile for this
      return (
        <EmptyTile
          type={type}
          container={container}
          className={`container-tile ${className}`}
        />
      );
    }
  }
}

export default connect()(ContainerTile);

ContainerTile.propTypes = {
  // returned from wordpress
  post_content: PropTypes.string,
  ID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  post_title: PropTypes.string,
  featured_image: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  // component allows for passing in a default image
  // to display if there is no featured_image, and
  // controllable text length before truncation
  defaultImage: PropTypes.string,
  textLength: PropTypes.number,
  // type of post this is: resource, opportunity, etc...
  type: PropTypes.string,
};
