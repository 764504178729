const initialState = {
  loading: false,
  error: false,
  errorMessage: 'There was an error getting the blog posts.  Please try again.',
  items: [],
  pagination: {
    per: 5,
    total: 0,
    page: 1,
    totalPages: 1,
  }
};

const blogOverview = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'INITIALIZE_BLOG_PAGE':
      return initialState;

    case 'GET_BLOG_POSTS_FETCHING':
      return {
        ...state,
        loading: true,
        error: false,
      };

    case 'GET_BLOG_POSTS_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
      };

    case 'GET_BLOG_POSTS_SUCCESS':
      return {
        ...state,
        loading: false,
        error: false,
        items: payload.items || [],
        pagination: {
          ...state.pagination,
          per: payload.pagination.per,
          total: payload.pagination.total,
          page: payload.pagination.page,
          totalPages: payload.pagination.totalPages,
        },
      };

    default:
      return state;
  };
};

export default blogOverview;
