import React from 'react';
import {toPercent} from '../../../util/helpers';

const OverlapRows = (props) => {
  const overlap = props?.data?.overlap || [];

  return overlap.map(item => {
    return (
      <ul className="report-row" key={item.id}>
        <li>{item.title}</li>
        <li>{toPercent(item.overlap)}</li>
      </ul>
    );
  });
}

export default OverlapRows;
