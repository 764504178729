const en = {
  signIn: "Sign In",
  goBack: "Go Back",
  backToHome: "Back to Home",

  // Nav Items
  dashboard: "Dashboard",
  notifications: "Notifications",
  documents: "Documents",
  reports: "Reports",
  opportunities: "Opportunities",
  resources: "Resources",
  contactUs: "Contact Us",
  showMenu: "Show Menu",
  hideMenu: "Hide Menu",

  // Footer Items
  privacyPolicy: "{company} Privacy Policy",
  termsOfService: "{company} Terms of Service",
  allRightsReserved: "Studio71 All Rights Reserved",
  redArrowCompany: "A Red Arrow Studios Company",
  needHelpContactUs: "Need help? Contact Us",

  // Documents Page Items
  yourDocuments: "Your Documents",
  documentsSubheader:
    "View and download important documents. Please Note: Statement documents are typically published 5-6 weeks after a month has completed. For example, a January statement will be available within the first week of March.",
  date: "Date",
  account: "Account",
  type: "Type",
  save: "Save",
  confirm: "Confirm",
  cancel: "Cancel",
  done: "Done",
  unknownAccountName: "Unknown Account Name",
  noDocuments: "No documents are available at this time.",
  filterByAccount: "Filter by Account",
  allAccounts: "All Accounts",

  // Blog Page Items
  blog: "Blog",
  allBlogPosts: "All Blog Posts",

  // Resources Page
  resourcesSubheader:
    "Resources for creating your content and growing your audience.",

  // Reports
  reportsTitle: "Reports",
  channelPerformance: "CHANNEL PERFORMANCE",
  monthlyReport: "MONTHLY REPORT",
  topCards: "Top Cards",
  topPlaylists: "Top Playlists",
  videosDrivingComments: "Videos Driving The Most Comments",
  videosDrivingSubscribers: "Videos Driving The Most Subscribers",
  topSubscriberOverlap: "Top Subscriber Overlap (with S71 Channels)",
  title: "Title",
  overlapPercentage: "Overlap Percentage",
  number: "Number",
  playlistName: "Playlist Name",
  watchTime: "Watch Time (mins)",
  clicks: "Clicks",
  ctr: "CTR",
  metric: "Metric",
  monthlyReportHeader: "{monthAndYear}",

  // Labeled Dropdown
  dropdownLabel: "Select A Channel",

  // Months
  January: "January",
  February: "February",
  March: "March",
  April: "April",
  May: "May",
  June: "June",
  July: "July",
  August: "August",
  September: "September",
  October: "October",
  November: "November",
  December: "December",

  // Channel Performance (snake cased to avoid mapping b/e vals to camel)
  views: "Views",
  watch_time: "Watch Time (mins)",
  avg_view_duration: "Avg. View Duration (mins)",
  net_subscribers: "Net Subscribers",
  watch_page_net_subscribers: "Watch Page Net Subs",
  likes: "Likes",
  comments: "Comments",
  shares: "Shares",
  card_teaser_clicks: "Card Teaser Clicks",
  card_teaser_ctr: "Card Teaser CTR",
  card_clicks: "Card Clicks",
  card_ctr: "Card CTR",
  playlist_watch_time: "Playlist WatchTime (min)",
  playlist_starts: "Playlist Starts",
  avg_time_in_playlist: "Avg Time in Playlist (mins)",

  // default
  default: "",

  // amazon link builder
  amazonAffiliateLinkBuilder: "Amazon Associates Program Link Builder",
  singleItemLink: "SINGLE ITEM LINK",
  multiItemLink: "MULTI-ITEM LINK",
  linkBuilder: "LINK BUILDER",
  linkBuilderNotSetup1:
    "This account has not yet been configured for Amazon Affiliate Links.",
  linkBuilderNotSetup2:
    "Please reach out to your account manager to complete this setup.",
  forMoreInfo: "For more information about the Amazon Affiliate Program",
  clickHere: "Click here",
  pleaseNoteThat:
    ". Please note due to tax laws residents of AR & ME are not eligible.",
  singleItemLinkBuilder: "SINGLE ITEM LINK BUILDER",
  multiItemLinkBuilder: "MULTI-ITEM LINK BUILDER",
  pasteALink:
    "Paste a link to any Amazon product, department, or custom store, to generate your custom affiliate link.",
  pasteMultipleLinks:
    "Paste multiple Amazon product links, on separate lines, to generate your custom affiliate link.",
  enterAUrlHere: "ENTER A URL HERE",
  enterUrlsHere: "ENTER URLS HERE",
  enterAValidAmazon: "Enter a valid Amazon or Joyo link.",
  enterValidAmazons: "Enter valid Amazon or Joyo links.",
  originalLink: "Original link:",
  originalLinks: "Original links:",
  anErrorMulti:
    "An error occured while creating your Amazon affiliate link! Please check your links and try again later.",
  anErrorSingle:
    "An error occured while creating your Amazon affiliate link! Please check your link and try again later.",
  createLink: "Create Link",
  copyLink: "Copy Link",
  copied: "Copied!",
  createAnotherLink: "Create Another Link",
  startOver: "Start Over",
  getStarted: "Get Started",
  findAProduct: "1. Find A Product On Amazon",
  theFirstStep:
    "The first step is to find a product on Amazon you want to share with your audience.",
  copyAndPaste: "2. Copy & Paste in Link Builder",
  copyTheLink:
    "Copy the link of the product and paste it into the Link builder to the left.",
  viewFullGuidelines: "View Full Guidelines",
  toolToBuild:
    "Tool to build links to products on Amazon as an Amazon Associate.",
  click: "Click",
  here: "here",
  forTermsAndConditions: "for terms and conditions",

  // Settings pages
  settings: "Settings",
  channels: "Channels",
  surveys: "Surveys",
  creatorProfiles: "Creator Profile(s)",
  accountSettings: "Account Settings",
  paymentSettings: "Payment Settings",
  paymentSettingsDescription:
    "Review your bank and payment settings. Please contact your Studio71 Creator Partnerships Manager to make any updates.",
  accountSettingsDescription:
    "Edit your account’s email address, password, change language preferences.",
  channelsSettingsDescription:
    "View and edit all of your channels - social accounts, podcasts and more.",
  surveysSettingsDescription:
    "We want to learn more about you! Click here to fill out some questions that will help us find the best opportunities for you!",
  creatorProfilesSettingsDescription:
    "Edit your creator profile and create additional profiles for each creator on your account. This profile data helps our Sales Team pitch you for deals!",
  notificationsSettingsDescription: "Edit your notification preferences.",
  surveyButton: "Go to survey",

  // Channel Settings
  channelSettings: "Channel Settings",
  socialPlatforms: "Social Platforms",
  podcasts: "Podcasts",
  noPodcastsFound: "No podcasts found!",
  noAccountsFound: "No accounts found!",
  connected: "Connected",
  needsAuth: "Needs Auth",
  add: "Add",
  close: "Close",
  addAChannel: "Add a Channel",
  selectAnAccount: "Select an account",
  platform: "Platform",
  selectAPlatform: "Select a platform",
  channelUrl: "Channel URL",
  enterTheChannelUrl: "Enter the channel URL",
  enterAValidChanneUrl: "Enter a valid channel URL",
  addChannelSuccess: "Channel added successfully!",
  addChannelError: "There was an error adding the channel.",
  dataUnavailable: "Data Unavailable",

  // DetailPages
  allResources: "All Resources",

  // account settings
  firstName: "First Name",
  enterFirstName: "Enter first name",
  lastName: "Last Name",
  enterLastName: "Enter last name",
  language: "Language",
  emailAddress: "Email Address",
  password: "Password",
  changePassword: "Change Password",
  english: "English",
  french: "French",
  german: "German",
  enterCurrentPassword: "Enter Current Password",
  enterNewPassword: "Enter New Password",
  confirmNewPassword: "Confirm New Password",
  contactUsChangeEmail: "Please contact us to have your email address changed.",
  passwordsDontMatch: "Passwords don't match",
  savePasswordInvalidPassword: "Please enter a more secure password.",
  savePasswordInvalidCurrentPassword: "Your current password is incorrect.",
  savePasswordInvalidPasswordExplaination:
    "Please enter a more secure password. Passwords must have a minimum of 8 characters, and a combination of letters and numbers. Common passwords such as “abc123” are prohibited.",
  genericAccountSettingsError:
    "There was an error saving your account settings",
  changesSaved: "Changes successfully saved!",
  uploadNewPhoto: "Upload New Photo",
  photoRequirements: "1MB limit preferably a square (1:1) ratio",
  fileTooLargeError: "File size is too big",
  genericImageUploadError: "Error uploading image",
  remove: "Remove",
  removePhoto: "Remove Photo",
  removePhotoConfirmation: "Are you sure you want to remove this photo?",
  surveysLoadingError: "Error loading surveys. Please try again later!",

  // payment settings
  noTaxForm:
    "No active tax form. Please contact your Studio71 Creator Partnerships Manager to resolve.",
  noTaxFormShort:
    "Missing - Please contact your Studio71 Creator Partnerships Manager.",
  noBank:
    "No active bank account. Please contact your Studio71 Creator Partnerships Manager to resolve.",
  noBankShort:
    "Missing -  Please contact your Studio71 Creator Partnerships Manager.",
  activeTaxFormType: "Active Tax Form Type",
  expirationDate: "Expiration Date",
  accountHolder: "Account Holder",
  paymentMethod: "Payment Method",
  taxDetails: "Tax Details",
  bankDetails: "Bank Details",
  bankName: "Bank Name",
  genericPaymentLoadingError:
    "Error loading payment settings. Please try again later!",
  noAccessPaymentLoadingError:
    "You do not have access to the data for this account",
  paymentSettingsBetaTooltip:
    "Please note, this feature is in beta testing and will be undergoing updates before its official release. If you have any comments or questions, please reach out to your Studio71 Creator Partnerships Manager",

  // page not found
  pageNotFound: "Page not found :(",
  oops: "Oooooups! Looks like you got lost.",
  allOpportunities: "All Opportunities",
  opportunitiesSubheader: "Opportunities for you to earn revenue.",

  // dashboard
  greeting: "Welcome, {firstName}!",
  greetingNoFirstName: "Welcome!",
  today: "Today is {today}",
  featuredResource: "Featured Resource",
  resourcesLoadError: "Could not load resources right now.",
  emptyDashboardTile: "There are no {type} right now.",
  emptyContainerTile: "{type} not available",
  fromTheBlog: "From the Blog",
  blogLoadError: "Could not load blog right now.",
  featuredOpportunity: "Featured Opportunity",
  opportunitiesLoadError: "Could not load opportunities right now.",
  notificationsUpToDate: "You are up-to-date on all notifications.",
  genericErrorMessage: "Whoops, something went wrong. Try again later.",

  // contact us form
  contactSuccess: "Message sent. We'll be in touch soon!",
  subjectPlaceholder: "Subject (Optional)",
  messagePlaceholder: "Hi, I have a question about...",
  submit: "Submit",
  missingMessage: "Please enter a message",
  reset: "Send another message",

  // navbar
  appName: "Studio 71 Creator App",
  logout: "Logout",

  // user group error messages
  noAppPermissionTitle: "This user does not have access to Creator Studio",
  nonBetaCreatorTitle: "No peeking!",
  noAppPermissionMessage:
    "If you believe you have gotten this message in error, please contact your Studio71 talent manager or business contact for further assistance.",
  nonBetaCreatorMessage:
    "The new Studio71 Creator Studio is still in testing and we're working to release to all creators soon. In the meantime, please log in to <a>creator.studio</a> to access the current Studio71 Creator App.",

  // ugc form
  reportContentInfringements: "Report Content Infringements",
  ugcWhichPlatform: "On what platform did you find the infringing content?",
  ugcCopyrightLink:
    "Link to the original content that you retain a copyright to.",
  ugcCopyrightPlaceholder: "Enter Original Content URL",
  ugcInfringingLink: "Link to the infringing content you are reporting.",
  ugcInfringingPlaceholder: "Enter Infringing Content URL",
  globalPolicyLabel: "Desired Global Policy.",
  monetize: "Monetize",
  other: "Other",
  ugcEnterOtherPolicy: "Enter other policy here",
  ugcAdditionalNotesLabel:
    "Please add any additional notes you have for the Studio71 Team.",
  urlValidationError: "Enter a valid url.",
  enterAPolicyError: "Enter a policy.",
  timestamps: "Timestamps",
  enterTimestampLabel: "Enter the time(s) of the infringing content",
  entireVideo: "Entire Video",
  timestampError: 'Enter a time or select "Entire Video".',
  ugcSubmitError: "There was an error submitting the form. Try again.",
  ugcSuccessMessage: "Form submitted successfully!",
  ugcSendAnother: "Send Another",
  ugcHeaderTooltip: "Report content being uploaded or used without permission.",

  // Auth roadblock modal
  authRoadblockHeader: "Missing Authentication",
  authRoadblockContent: "One or more of your channels needs authentication.",
  authenticateNow: "Authenticate Now",
  finishLater: "Finish Later",

  // monthly statement unsubscribe
  monthlyStatementEmailUnsubscribeInstructions:
    "Click Below to Unsubscribe from Monthly Statement Emails",
  monthlyStatementEmailUnsubscribeSuccess:
    "You’ve successfully unsubscribed from Monthly Statement Emails",
  monthlyStatementEmailUnsubscribeError:
    "An error occured while unsubscribing, please try again",
};

export default en;
