import React, { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import PageLoader from "../loader/PageLoader";
import ListHeader from "./ListHeader";
import ListContent from "./ListContent";
import PageHeader from "../shared/PageHeader";
import Pagination from "../shared/pagination/Pagination";
import getQueryStringParams from "../../util/getQueryStringParams";
import {
  getDocumentsList,
  showLoader,
  changeDocumentsSort,
} from "../../redux/actions/documents";
import CustomDropdown from "../shared/customDropdown/CustomDropdown";

const DocumentsPageContainer = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const messages = defineMessages({
    allAccounts: { id: "allAccounts" },
    filterLabel: { id: "filterByAccount" },
  });

  const {
    page,
    sortBy = "date",
    sortDirection = "desc",
    per,
    accountId = "all",
  } = getQueryStringParams(props.location?.search) || {};

  const { loading, error, items, pagination, accounts, accountsError } =
    useSelector((state) => {
      return {
        loading: state.documents.loading,
        error: state.documents.error,
        items: state.documents.items || [],
        pagination: state.documents.pagination || {},
        accounts: state.user.accounts || [],
      };
    }, shallowEqual);

  useEffect(() => {
    dispatch(getDocumentsList({ page, per, sortBy, sortDirection, accountId }));

    return () => {
      //remove the rendered content from the dom to prevent flicker on next documents page visit
      dispatch(showLoader());
    };
  }, [dispatch, page, sortBy, sortDirection, per, accountId]);

  const handlePaginationClick = (page) => {
    dispatch(
      changeDocumentsSort({ page, per, sortBy, sortDirection, accountId }),
    );
  };

  const handleSortClick = (nextSort) => {
    dispatch(
      changeDocumentsSort({
        accountId,
        sortBy: nextSort,
        ...(nextSort === sortBy
          ? { sortDirection: sortDirection === "desc" ? "asc" : "desc" }
          : {}),
      }),
    );
  };

  const handleAccountChange = (accountId) => {
    dispatch(
      changeDocumentsSort({ page, per, sortBy, sortDirection, accountId }),
    );
  };

  const headerItems = ["date", "account", "type"];

  const accountFilterOptions = [
    { label: intl.formatMessage(messages.allAccounts), value: "all" },
  ].concat(
    accounts
      ?.filter(({ id }) => id !== undefined && id !== "undefined")
      ?.map(({ id, title }) => ({ label: title, value: id }))
      .sort(({ label: a }, { label: b }) => (a || "").localeCompare(b || "")),
  );

  return (
    <div className="documents-page">
      <PageHeader headerId="yourDocuments" subheaderId="documentsSubheader" />
      <div className="opaque-container">
        <PageLoader loading={loading} error={error} size="medium">
          {accountsError || (
            <CustomDropdown
              labelText={intl.formatMessage(messages.filterLabel)}
              items={accountFilterOptions}
              selected={accountId}
              onChange={handleAccountChange}
            />
          )}
          {!items.length ? (
            <p className="no-documents">
              <FormattedMessage
                id="noDocuments"
                defaultMessage="No documents are available at this time."
              />
            </p>
          ) : (
            <ul className="documents-list">
              <ListHeader
                headerItems={headerItems}
                onClick={handleSortClick}
                sortValues={{ sortBy, sortDirection }}
              />
              <ListContent items={items} />
            </ul>
          )}
          <Pagination
            page={pagination.page}
            totalPages={pagination.totalPages}
            handleClick={handlePaginationClick}
            type="documents"
          />
        </PageLoader>
      </div>
    </div>
  );
};

export default DocumentsPageContainer;
