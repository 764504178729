import React from 'react';

const PageNumbers = ({ page, totalPages, handleClick }) => {
  //  Limit page numbers to 5 at a time and keep the active page as the 3rd in the list when possible
  totalPages = totalPages || 1;
  let pageNumbers = [];

  for(let i = 1; i < totalPages + 1; i++) {
    pageNumbers.push(i);
  };

  let firstSlice = (page - 3 >= 1) ? page - 3 : 0;
  let lastSlice = firstSlice + 5;

  if (lastSlice > totalPages) {
    firstSlice = (totalPages - 5 > 0 ? totalPages - 5 : 0);
  }

  const pages = pageNumbers.slice(firstSlice, lastSlice);

  return pages.map(pageNumber => {
    const activeClassName = page === pageNumber ? 'active' : '';
    return (
      <li key={pageNumber} className={`page ${activeClassName}`}>
        <button className={`page ${activeClassName}`} onClick={handleClick}>
          {pageNumber}
        </button>
      </li>
    );
  });
};

export default PageNumbers;
