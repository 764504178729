import React from "react";
import { connect } from "react-redux";
import { sendEmail, showMessageError } from "../../../redux/actions/contactUs";

import { FormattedMessage } from "react-intl";

const FormActions = ({
  message,
  ignoreUpdates,
  showMessageError,
  sendEmail,
}) => {
  const handleSubmit = () => {
    if (!ignoreUpdates) {
      if (!message.length) {
        return showMessageError();
      }
      sendEmail({ openedFrom: "dashboard" });
    }
  };

  return (
    <div className="actions">
      <button onClick={handleSubmit} className="secondary">
        <FormattedMessage id="submit" defaultMessage="Submit" />
      </button>
    </div>
  );
};

const mapStateToProps = ({ contactUs }, passedProps) => {
  return {
    message: contactUs.message || "",
    ignoreUpdates: passedProps?.ignoreUpdates || false,
  };
};

const mapDispatchToProps = {
  sendEmail,
  showMessageError,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormActions);
