import React from 'react';
import { FormattedMessage } from 'react-intl';
import Tooltip from '../../shared/tooltip/Tooltip';

const ErrorMessage = ({ errorMessage }) => {
  if (!errorMessage) {
    return null;
  }

  return (
    <em className="error-message">
      <FormattedMessage id={errorMessage} defaultMessage="There was an error saving your account settings" />
      {
        errorMessage === 'savePasswordInvalidPassword' &&
        <Tooltip className="password-tooltip" placement="top-left" content={<FormattedMessage id="savePasswordInvalidPasswordExplaination" />}>
          <i className="fas fa-question-circle" />
        </Tooltip>
      }
    </em>
  );
};

export default ErrorMessage;
