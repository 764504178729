import React from "react";
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PageLoader from '../loader/PageLoader';

const LoginWaitingScreen = () => {
  const loggedIn = useSelector(state => state.user.loggedIn);
  const userGroupAuthError = useSelector(state => state.user.userGroupAuthError);
  const destinationPage = useSelector(state => state.ui.destinationPage);

  return (
    <PageLoader loading={!loggedIn && !userGroupAuthError}>
      <Redirect to={destinationPage} />
    </PageLoader>
  );
};

export default LoginWaitingScreen;
