import React from 'react';

const Thumbnail = props => {
  if (!props.last_fetch && !props.title) {
    return null;
  }

  if (!props.last_fetch && props.title) {
    return <i className="channel-thumbnail fas fa-sync" />;
  }

  return <div className="channel-thumbnail" style={{ backgroundImage: `url(${props.thumb_url})` }} />;
};

export default Thumbnail;
