import React from 'react';
import { useDispatch } from 'react-redux';
import { closeContactUsModal, resetContactUsForm } from "../../../redux/actions/contactUs";
import { FormattedMessage } from "react-intl";

// "modal" offers a Close button
// "dashboard" offers a Reset button
const FormButton = ({ location }) => {
  const dispatch = useDispatch();
  const close = () => { dispatch(closeContactUsModal()); }
  const reset = () => { dispatch(resetContactUsForm()); }

  if (location === "dashboard") {
    return (
      <button onClick={reset} className="secondary reset">
        <FormattedMessage id="reset" defaultMessage="Send Another Message" />
      </button>
    );
  } else {
    return (
      <button onClick={close} className="secondary">
        <FormattedMessage id="close" defaultMessage="Close" />
      </button>
    );
  }
};

export default FormButton;
