import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { navigateToPath } from "../../redux/actions/ui";
import { FormattedMessage } from "react-intl";

const ErrorPage = (props) => {
  const dispatch = useDispatch();
  const { message, buttonText } = props;

  const handleButtonClick = () => {
    dispatch(navigateToPath(props.buttonPath || "/"));
  };

  return (
    <div className="error-page">
      <div className="overlay" />
      <h4>Uh Oh :(</h4>
      <p>{message ? message : "Something went wrong."}</p>
      <button
        className="primary"
        onClick={
          props.handleButtonClick ? props.handleButtonClick : handleButtonClick
        }
      >
        {buttonText ? (
          buttonText
        ) : (
          <FormattedMessage id="goBack" defaultMessage="Go Back" />
        )}
      </button>
    </div>
  );
};

export default ErrorPage;

ErrorPage.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object, // ReactIntl FormattedMessage
  ]),
  buttonText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object, // ReactIntl FormattedMessage
  ]),
  handleButtonClick: PropTypes.func,
};
