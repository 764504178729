import React from "react";
import { FormattedMessage } from "react-intl";

const arrowDirection = (str, direction) =>
  `${str[0]}${direction === "asc" ? "up" : "down"}${str[1]}`;

const ListHeader = ({ headerItems, onClick, sortValues }) => (
  <li className="list-header">
    <div className="item-data">
      {(headerItems || []).map((item) => {
        const itemClassName = (item || "").toLowerCase().replace(" ", "-");
        return (
          <div key={item} className={`list-item ${itemClassName}`}>
            <span onClick={() => onClick(item)}>
              <FormattedMessage id={item} />
            </span>
            {sortValues?.sortBy === item && (
              <i
                className={arrowDirection`fas fa-caret-${sortValues?.sortDirection}`}
              />
            )}
          </div>
        );
      })}
    </div>
    <div className="save">
      <FormattedMessage id="save" />
    </div>
  </li>
);

export default ListHeader;
