import React from "react";
import BarsLoader from "../../svg/barsLoader";

const LoadingComponent = ({ loadingMessage, removeLoader, loading, size }) => {
  /**
   * if a fadeOut prop is passed to pageLoader, the removeLoader flag is set after a timeout completes to remove this component from the dom
   * if loading is false, the page-loaded class is added for a css animation to hook to for the fade out
   *
   * The setTimout is duration is slightly longer than the css animation duration to remove the component
   * after the animation is complete.
   */

  if (!removeLoader) {
    const additionalClassName = !loading ? "page-loaded" : "";
    const sizeClass = size || "";

    return (
      <div className={`page-loader ${additionalClassName}`}>
        <div className={`loader-container ${sizeClass}`}>
          <BarsLoader />
          {loadingMessage && (
            <span className="loading-message">{loadingMessage}</span>
          )}
        </div>
      </div>
    );
  }
  return null;
};

export default LoadingComponent;
