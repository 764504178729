import React from "react";

/*
 * Props:
 *   fields: Fields for banking or tax section [{ display: "", fieldName: "" }, ...]
 *   paymentInfo: [{ account_id: "", banking: { bank_tax_fields... }}]
 *   accounts: array of objects, like [{ id: 1, title: "Lilly Singh", ....}]
 *   noDataField: if this field is empty, display noDataMessage for the row
 *   noDataMessage: string to display when there is no data
 **/
const PaymentInfoTable = ({
  fields,
  accounts,
  paymentInfo,
  noDataField,
  bankOrTax,
  noDataMessage,
}) => {
  const accountIdToTitle = accounts.reduce((acc, next) => {
    if (!acc?.[next.id]) acc[next.id] = next.title;
    return acc;
  }, {});

  const noDataFn = (banking, fieldName) =>
    fieldName === noDataField && !banking[fieldName];

  return (
    <table>
      <thead>
        <tr>
          {fields?.map(({ display }) => {
            return <th>{display}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {paymentInfo.map(({ account_id, banking }) => {
          let data = banking;
          if (bankOrTax === "tax") {
            data = banking?.tax?.[0] || {};
          }

          const noDataRow =
            fields.filter(({ fieldName }) => {
              return noDataFn(data, fieldName);
            }).length > 0;

          return (
            <tr className={`${noDataRow ? "noDataRow" : ""}`}>
              {fields.map(({ fieldName }) => {
                if (fieldName === "account_id") {
                  return <td>{accountIdToTitle?.[account_id]}</td>;
                }
                if (noDataFn(data, fieldName)) {
                  return <td className="noDataMessage">{noDataMessage}</td>;
                }
                if (noDataRow) {
                  return null;
                }
                if (data?.[fieldName]) {
                  return <td>{data?.[fieldName]}</td>;
                }
                return <td className="data-not-available"></td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default PaymentInfoTable;
