import React from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import history from "./history";
import ErrorHandler from "./ErrorHandler";
import AppContent from "./AppContent";

import store from "./store";

const App = () => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <ErrorHandler>
          <AppContent />
        </ErrorHandler>
      </Router>
    </Provider>
  );
};

export default App;
