import api from "./api";
import { navigateToPath } from "./ui";

export const initializeResourcesPage = (page) => {
  return (dispatch) => {
    dispatch({ type: "INITIALIZE_RESOURCES_PAGE", payload: { page } });
    dispatch(getResources(page));
  };
};

export const getResources = (page = 1) => {
  return (dispatch, getState) => {
    dispatch({ type: "GET_RESOURCES_FETCHING" });
    api
      .post("/api/posts/list", { page, per: 6, postType: "resource" })
      .then((resp) => {
        let totalItems = resp.data?.pagination?.total;
        let totalPages = null;

        // calculate total pages if you haven't already
        if (getState().resources.pagination.totalPages === null) {
          totalPages = Math.ceil(totalItems / 6);
        }

        let pagination = {
          page: +page,
        };

        if (totalPages !== null) {
          pagination.totalPages = +totalPages;
        }

        const items = resp.data.items;

        dispatch({
          type: "GET_RESOURCES_SUCCESS",
          payload: {
            items,
            pagination,
          },
        });
      })
      .catch((err) => {
        dispatch({ type: "GET_RESOURCES_ERROR", payload: { err } });
      });
  };
};

export const getResource = (id) => {
  return (dispatch) => {
    dispatch({ type: "GET_RESOURCE_FETCHING", payload: { id } });
    if (isNaN(+id)) {
      return dispatch({
        type: "GET_RESOURCE_ERROR",
        payload: { pageNotFound: true },
      });
    }
    api
      .post(`/api/posts/detail`, { id })
      .then((resp) => {
        let item = resp?.data?.items?.[0];
        if (!item) {
          return dispatch({
            type: "GET_RESOURCE_ERROR",
            payload: { pageNotFound: true },
          });
        }
        dispatch({ type: "GET_RESOURCE_SUCCESS", payload: { item } });
      })
      .catch((err) => {
        dispatch({ type: "GET_RESOURCE_ERROR", payload: { err } });
      });
  };
};

export const goToResourcesContainer = () => {
  return (dispatch) => {
    dispatch({ type: "_GO_TO_RESOURCES_CONTAINER" });
    dispatch(navigateToPath(`/resources?page=1`));
  };
};

export const changeResourcesContainerPage = (pageNumber) => {
  return (dispatch) => {
    dispatch({
      type: "_CHANGE_RESOURCE_CONTAINER_PAGE",
      payload: { pageNumber },
    });
    dispatch(navigateToPath(`/resources?page=${pageNumber}`));
  };
};
