const getQueryStringParams = query => {
  const regex = /^[?]/;

  if (!query) {
    return {};
  }

  return (regex.test(query) ? query.slice(1) : query).split('&').reduce((params, param) => {
    const [key, value] = param.split('=');
    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
    return params;
  }, {});
};

export default getQueryStringParams;
