import React, { Component } from "react";
import StatusArea from "./StatusArea";
import { FormattedMessage } from "react-intl";
import TikTokIcon from "../../../svg/TikTokIcon";
import channelsMap from "../../../util/channelsMap";
import Thumbnail from "./Thumbnail";

class ChannelSettingsItem extends Component {
  constructor(props) {
    super(props);
    this.handleAuthClick = this.handleAuthClick.bind(this);
  }

  handleAuthClick() {
    this.props.handleAuthClick(
      this.props.channel.feed_id,
      this.props.channel.domain,
    );
  }

  render() {
    const {
      id,
      thumb_url,
      domain,
      title,
      noAccounts,
      domain_url,
      progress,
      last_fetch,
    } = this.props.channel;

    const url = domain === "yt" ? `youtube.com/channel/${id}` : domain_url;

    const titleItem =
      last_fetch || (domain === "pc" && title)
        ? title
        : (url || "").replace(/^https?:\/\//, "");

    const titleDisplay = url ? (
      <a
        href={`//${url.replace(/^https?:\/\//, "")}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {titleItem}
      </a>
    ) : (
      titleItem
    );
    const noAccountsMessage =
      domain === "pc" ? "No podcasts found" : "No accounts found!";

    const noAccountsId =
      domain === "pc" ? "noPodcastsFound" : "noAccountsfound!";

    return (
      <div className="col-12 col-xl-6">
        <div className="channel-settings-item">
          <div className="icon-container">
            {domain === "tt" ? (
              <div>
                <TikTokIcon />
              </div>
            ) : (
              <i className={channelsMap[domain].icon} />
            )}
            <span>{channelsMap[domain].name}</span>
          </div>
          <div className="detail-container">
            <Thumbnail
              last_fetch={last_fetch}
              title={title}
              thumb_url={thumb_url}
            />
            <div className="channel-title">
              {noAccounts ? (
                <FormattedMessage
                  defaultMessage={noAccountsMessage}
                  id={noAccountsId}
                />
              ) : (
                titleDisplay
              )}
            </div>
            <StatusArea
              domain={domain}
              status={progress?.authorized}
              handleClick={this.handleAuthClick}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ChannelSettingsItem;
