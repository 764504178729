import React, { useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { trackPageView } from "./redux/actions/ga";
import PrivateRoute from "./components/PrivateRoute";
import LoginContainer from "./components/login/LoginContainer";
import LoginWaitingScreen from "./components/login/LoginWaitingScreen";
import DashboardContainer from "./components/dashboard/DashboardContainer";
import OpportunitiesContainer from "./components/opportunities/OpportunitiesContainer";
import PageNotFoundContainer from "./components/pageNotFound/PageNotFoundContainer";
import DocumentsPageContainer from "./components/documents/DocumentsPageContainer";
import BlogOverviewContainer from "./components/blog/BlogOverviewContainer";
import BlogDetailContainer from "./components/blog/BlogDetailContainer";
import ReportsPageContainer from "./components/reportsPage/ReportsPageContainer";
import AmazonLinkBuilder from "./components/opportunities/AmazonLinkBuilder/AmazonLinkBuilder";
import OpportunitiesDetailPage from "./components/opportunities/OpportunitiesDetailPage";
import SettingsRoutes from "./components/settings/SettingsRoutes";
import ResourcesContainer from "./components/resources/ResourcesContainer";
import ResourcesDetailPage from "./components/resources/ResourcesDetailPage";
import UIElementExamples from "./components/examples/UIElementExamples";
import UgcFormContainer from "./components/resources/ugcForm/UgcFormContainer";
import MonthlyStatementEmailUnsubscribe from "./components/MonthlyStatementEmailUnsubscribe";

const formatRoute = (page) => `/${page.toLowerCase().replace(/ /g, "-")}`;

const AppRoutes = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const matchedRestrictionGroup = useSelector(
    (state) => state.user.matchedRestrictionGroup || {},
    shallowEqual,
  );
  const menuItems = useSelector(
    (state) => state.mainNav.menuItems || [],
    shallowEqual,
  );
  // Make the home page the first nav item not in the restricted routes array.
  const homePage = menuItems.find((item) => {
    return (
      matchedRestrictionGroup.restrictedRoutes?.indexOf(formatRoute(item)) < 0
    );
  });
  const homeRoute = homePage ? formatRoute(homePage) : "/dashboard";
  const redirectPath = loggedIn ? homeRoute : "/login";

  useEffect(() => {
    dispatch(trackPageView(location.pathname));
  }, [dispatch, location.pathname]);

  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to={redirectPath} />} />
      <Route
        exact
        path="/login"
        render={(routeProps) => <LoginContainer {...routeProps} />}
      />
      <Route
        exact
        path="/auth"
        render={(routeProps) => <LoginWaitingScreen {...routeProps} />}
      />
      <PrivateRoute
        path="/dashboard"
        render={(routeProps) => <DashboardContainer {...routeProps} />}
      />
      <PrivateRoute
        path="/examples"
        render={(routeProps) => <UIElementExamples {...routeProps} />}
      />
      <PrivateRoute
        path="/documents"
        render={(routeProps) => <DocumentsPageContainer {...routeProps} />}
      />
      <PrivateRoute
        path="/reports"
        render={(routeProps) => <ReportsPageContainer {...routeProps} />}
      />
      <PrivateRoute
        exact
        path="/blog"
        render={(routeProps) => <BlogOverviewContainer {...routeProps} />}
      />
      <PrivateRoute
        path="/blog/:id"
        render={(routeProps) => <BlogDetailContainer {...routeProps} />}
      />
      <PrivateRoute
        exact
        path="/opportunities"
        render={(routeProps) => <OpportunitiesContainer {...routeProps} />}
      />
      <PrivateRoute
        path="/opportunities/:id"
        render={(routeProps) => <OpportunitiesDetailPage {...routeProps} />}
      />
      <PrivateRoute
        path="/amazon-link-builder"
        render={(routeProps) => <AmazonLinkBuilder {...routeProps} />}
      />
      <PrivateRoute
        exact
        path="/resources"
        render={(routeProps) => <ResourcesContainer {...routeProps} />}
      />
      <PrivateRoute
        exact
        path="/resources/ugc-form"
        render={() => <UgcFormContainer />}
      />
      <PrivateRoute
        path="/resources/:id"
        render={(routeProps) => <ResourcesDetailPage {...routeProps} />}
      />
      <PrivateRoute
        path="/settings"
        render={(routeProps) => <SettingsRoutes {...routeProps} />}
      />
      <PrivateRoute
        path="/monthly-statement-email-unsubscribe"
        render={(routeProps) => (
          <MonthlyStatementEmailUnsubscribe {...routeProps} />
        )}
      />
      <Route
        path="*"
        render={(routeProps) => <PageNotFoundContainer {...routeProps} />}
      />
    </Switch>
  );
};

export default AppRoutes;
