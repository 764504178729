import React from 'react';
import { FormattedMessage } from 'react-intl';

const ShortLinkError = ({ singleMulti }) => {
  if (singleMulti === "multi") {
    return (
      <p className="link-creation-error">
        <FormattedMessage id="anErrorMulti" />
      </p>
    );
  }

  return (
    <p className="link-creation-error">
      <FormattedMessage id="anErrorSingle" />
    </p>
  );
}

export default ShortLinkError;

