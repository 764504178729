// automatically tracks every page that loads
export const trackPageView = (page) => {
  return () => {
    if (window.gtag) {
      window.gtag("event", "page_view", {
        s71_page: page,
        debug_mode: true,
      });
    }
  };
};

export const gaSendEmail = ({ openedFrom }) => {
  return () => {
    if (window.gtag) {
      window.gtag("event", "send_email", {
        s71_page: openedFrom,
        debug_mode: true,
      });
    }
  };
};

export const gaModalOpen = ({ modalName }) => {
  return () => {
    if (window.gtag) {
      window.gtag("event", "modal_open", {
        s71_modalName: modalName,
        debug_mode: true,
      });
    }
  };
};

export const gaModalClose = ({ modalName }) => {
  return () => {
    if (window.gtag) {
      window.gtag("event", "modal_close", {
        s71_modalName: modalName,
        debug_mode: true,
      });
    }
  };
};

export const gaSettingsAction = (params) => {
  return () => {
    if (window.gtag) {
      window.gtag("event", "settings_action", {
        ...params,
        debug_mode: true,
      });
    }
  };
};

export const navLinkItemClick = ({ item }) => {
  return () => {
    if (window.gtag) {
      window.gtag("event", "nav_link_item_click", {
        s71_link_item: item,
        debug_mode: true,
      });
    }
  };
};

export const gaFooterItemClick = ({ item }) => {
  return () => {
    if (window.gtag) {
      window.gtag("event", "footer_item_click", {
        s71_footer_item: item,
        debug_mode: true,
      });
    }
  };
};

export const gaLogoutClick = () => {
  return () => {
    if (window.gtag) {
      window.gtag("event", "logout_click", {
        debug_mode: true,
      });
    }
  };
};

export const gaSaveDocumentEvent = ({
  document_id,
  reference_date,
  document_type,
}) => {
  return () => {
    if (window.gtag) {
      window.gtag("event", "save_document", {
        s71_document_id: document_id,
        s71_reference_date: reference_date,
        s71_document_type: document_type,
        debug_mode: true,
      });
    }
  };
};

export const gaPagingEvent = ({ pageType, page }) => {
  return () => {
    if (window.gtag) {
      window.gtag("event", "paging_click", {
        s71_pageType: pageType,
        s71_page: page,
        debug_mode: true,
      });
    }
  };
};

export const gaSelectChannelEvent = ({ page, channel }) => {
  return () => {
    if (window.gtag) {
      window.gtag("event", "select_channel", {
        s71_page: page,
        s71_channel: channel,
        debug_mode: true,
      });
    }
  };
};

export const gaSideBarExpand = () => {
  return () => {
    if (window.gtag) {
      window.gtag("event", "sidebar_expand", {
        debug_mode: true,
      });
    }
  };
};

export const gaSideBarCollapse = () => {
  return () => {
    if (window.gtag) {
      window.gtag("event", "sidebar_collapse", {
        debug_mode: true,
      });
    }
  };
};

export const gaCreateShortLink = ({ s71_urlType, s71_longUrl }) => {
  return () => {
    if (window.gtag) {
      window.gtag("event", "create_short_link", {
        s71_urlType,
        s71_longUrl,
        debug_mode: true,
      });
    }
  };
};

export const gaClickthroughEvent = ({ page, itemName }) => {
  return () => {
    if (window.gtag) {
      window.gtag("event", "clickthrough", {
        s71_page: page,
        s71_itemName: itemName,
        debug_mode: true,
      });
    }
  };
};

export const gaNotificationClick = ({ notificationName, notificationUrl }) => {
  return () => {
    if (window.gtag) {
      window.gtag("event", "notification_click", {
        s71_notificationName: notificationName,
        s71_notificationUrl: notificationUrl,
        debug_mode: true,
      });
    }
  };
};

export const gaUnsubscribeClick = ({ page, value }) => {
  return () => {
    if (window.gtag) {
      window.gtag("event", "unsubscribe_click", {
        s71_page: page,
        s71_value: value,
        debug_mode: true,
      });
    }
  };
};
