import React from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useIntl, FormattedMessage, defineMessages } from 'react-intl';
import CustomDropdown from '../../shared/customDropdown/CustomDropdown';
import channelsMap from '../../../util/channelsMap';
import {
  selectAddChannelPlatform,
  updateAddChannelUrl,
  selectAddChannelAccount,
  postAddChannel
} from '../../../redux/actions/settings';

const AddChannelModalContents = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    addChannelSelectedPlatform,
    addChannelSelectedAccount,
    addChannelURL,
    accountOptions,
    urlValidationError,
    addChannelError,
  } = useSelector(state => {
    return {
      addChannelSelectedPlatform: state.settings.channel.addChannelSelectedPlatform,
      addChannelSelectedAccount: state.settings.channel.addChannelSelectedAccount,
      addChannelURL: state.settings.channel.addChannelURL,
      accountOptions: state.settings.channel.accountOptions,
      urlValidationError: state.settings.channel.urlValidationError,
      addChannelError: state.settings.channel.addChannelError,
    }
  }, shallowEqual);

  const messages = defineMessages({
    channelUrl: {
      id: 'enterTheChannelUrl',
      defaultMessage: 'Enter the channel URL',
    },
    selectPlatform: {
      id: 'selectAPlatform',
      defaultMessage: 'Select a platform',
    },
    selectAccount: {
      id: 'selectAnAccount',
      defaultMessage: 'Select an account',
    }
  });
  const channelOptions = Object.keys(channelsMap).map(channel => {
    return { value: channel, label: channelsMap[channel].name };
  });
  const disableButtonClass = (urlValidationError || !addChannelURL.length) ? ' disabled' : '';

  const selectPlatform = (value) => dispatch(selectAddChannelPlatform(value));
  const selectAccount = (value) => dispatch(selectAddChannelAccount(value));
  const updateUrlField = (e) => dispatch(updateAddChannelUrl(e.target.value));
  const submit = () => dispatch(postAddChannel());

  return (
    <div className="form">
      <div className="account">
        <CustomDropdown
          items={accountOptions}
          selected={addChannelSelectedAccount}
          onChange={selectAccount}
          labelText={<FormattedMessage id="account" defaultMessage="Account" />}
          placeholderText={intl.formatMessage(messages.selectAccount)}
        />
      </div>
      <div className="platform">
        <CustomDropdown
          items={channelOptions}
          selected={addChannelSelectedPlatform}
          onChange={selectPlatform}
          labelText={<FormattedMessage id="platform" defaultMessage="Platform" />}
          placeholderText={intl.formatMessage(messages.selectPlatform)}
        />
      </div>
      <div className="url">
        <label>
          <span className="label" data-testid="channel-url">
            <FormattedMessage id="channelUrl" defaultMessage="Channel URL" />
          </span>
          <input
            type="text"
            value={addChannelURL}
            placeholder={intl.formatMessage(messages.channelUrl)}
            onChange={updateUrlField}
          />
        </label>
        {urlValidationError && <p className="validation-error">
          <FormattedMessage id="enterAValidChannelUrl" defaultMessage="Enter a valid channel URL" />
        </p>}
        {addChannelError && <p className="validation-error">
          <FormattedMessage id="addChannelError" defaultMessage="There was an error adding the channel" />
        </p>}
      </div>
      <div className="actions">
        <button onClick={submit} className={`primary${disableButtonClass}`}>
          <FormattedMessage id="add" defaultMessage="Add" />
        </button>
      </div>
    </div>
  );
};

export default AddChannelModalContents;
