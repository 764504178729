import api from "./api";
import { gaModalOpen, gaModalClose, gaSendEmail } from "../../redux/actions/ga";

export const resetContactUsForm = () => {
  return (dispatch) => {
    dispatch({ type: "RESET_CONTACT_US_FORM" });
  };
};

export const updateSubject = (value) => {
  return (dispatch) => {
    dispatch({ type: "UPDATE_CONTACT_US_SUBJECT", payload: { value } });
  };
};

export const updateMessage = (value) => {
  return (dispatch) => {
    dispatch({ type: "UPDATE_CONTACT_US_MESSAGE", payload: { value } });
  };
};

export const showMessageError = () => {
  return (dispatch) => {
    dispatch({ type: "SHOW_MISSING_MESSAGE_ERROR" });
  };
};

// the openedFrom flow is confusing. DashboardContactForm passes
// openedFrom in as a prop, other pages set it in state when the modal gets opened
export const sendEmail = ({ openedFrom }) => {
  return (dispatch, getState) => {
    const { contactUs, user } = getState();

    const fullName = `${user?.firstName || ""} ${user?.lastName || ""}`;
    const accounts = user?.accounts || [];

    const data = {
      fullName: fullName || "A Creator",
      accounts,
      body: contactUs?.message || "",
      subject: contactUs?.subject || "",
      from: user?.email || "DO_NOT_REPLY@creator.studio",
    };

    dispatch(gaSendEmail({ openedFrom: contactUs?.openedFrom || openedFrom }));

    dispatch({ type: "SEND_CONTACT_US_EMAIL", payload: { data } });

    api
      .post("/api/email/send", data)
      .then(() => {
        dispatch({ type: "SEND_CONTACT_US_EMAIL_SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "SEND_CONTACT_US_EMAIL_ERROR", payload: { err } });
      });
  };
};

/* Modal specific actions */
export const openContactUsModal = ({ openedFrom }) => {
  return (dispatch) => {
    dispatch(gaModalOpen({ modalName: "contact_us" }));
    dispatch({
      type: "OPEN_CONTACT_US_MODAL",
      payload: { openedFrom },
    });
  };
};

export const closeContactUsModal = () => {
  return (dispatch) => {
    dispatch(gaModalClose({ modalName: "contact_us" }));
    dispatch({ type: "CLOSE_CONTACT_US_MODAL" });
  };
};
