const initialState = {
  loading: true,
  error: false,
  errorMessage: "There was an error getting your channels.  Please try again.",
  channels: [],
  addChannelModalOpened: false,
  addChannelSelectedPlatform: "",
  addChannelSelectedAccount: "",
  addChannelURL: "",
  urlValidationError: false,
  accountOptions: [],
  addChannelSuccess: false,
  addChannelError: false,
};

const settings = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "GET_CHANNEL_DETAILS_FETCHING":
      return {
        ...state,
        loading: true,
        error: false,
      };

    case "GET_CHANNEL_DETAILS_ERROR":
      return {
        ...state,
        loading: false,
        error: true,
      };

    case "GET_CHANNEL_DETAILS_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        channels: payload.channels,
      };

    case "OPEN_ADD_CHANNEL_MODAL":
      return {
        ...state,
        addChannelModalOpened: true,
        addChannelSelectedPlatform: payload.domain,
        addChannelSelectedAccount: payload.selectedAccount,
        accountOptions: payload.accountOptions,
      };

    case "CLOSE_ADD_CHANNEL_MODAL":
      return {
        ...state,
        addChannelModalOpened: false,
        addChannelSuccess: false,
        addChannelError: false,
        urlValidationError: false,
        addChannelURL: "",
      };

    case "UPDATE_CHANNELS_DATA":
      return {
        ...state,
        channels: payload.channels,
      };

    case "SELECT_ADD_CHANNEL_PLATFORM":
      return {
        ...state,
        addChannelSelectedPlatform: payload.value,
        addChannelError: false,
      };

    case "SELECT_ADD_CHANNEL_ACCOUNT":
      return {
        ...state,
        addChannelSelectedAccount: payload.value,
        addChannelError: false,
      };

    case "UPDATE_ADD_CHANNEL_URL":
      return {
        ...state,
        addChannelURL: payload.value,
        urlValidationError: payload.validationError,
        addChannelError: false,
      };

    case "POST_ADD_CHANNEL_SUCCESS":
      return {
        ...state,
        addChannelSuccess: true,
        addChannelError: false,
      };

    case "POST_ADD_CHANNEL_ERROR":
      return {
        ...state,
        addChannelError: true,
      };

    default:
      return state;
  }
};

export default settings;
