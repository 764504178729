import React from "react";
import { FormattedMessage } from "react-intl";

const NotSetUpMessage = () => {
  return (
    <div className="amazon-link-builder-area-not-set-up opaque-container">
      <h3>
        <FormattedMessage id="linkBuilder" defaultMessage="Link Builder" />
      </h3>
      <p>
        <FormattedMessage
          id="linkBuilderNotSetup1"
          defaultMessage="This account has not yet been configured for Amazon Affiliate Links."
        />
      </p>
      <p>
        <FormattedMessage
          id="linkBuilderNotSetup2"
          defaultMessage="Please reach out to your account manager to complete this setup."
        />
      </p>
    </div>
  );
};

export default NotSetUpMessage;
