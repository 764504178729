const channelsMap = {
  fb: {
    name: "Facebook",
    icon: "fa-brands fa-facebook-f",
  },
  ig: {
    name: "Instagram",
    icon: "fa-brands fa-instagram",
  },
  pc: {
    name: "Podcasts",
    icon: "fa-solid fa-microphone",
  },
  sc: {
    name: "Snapchat",
    icon: "fa-brands fa-square-snapchat",
  },
  tt: {
    name: "TikTok",
    icon: "",
  },
  tw: {
    name: "Twitter",
    icon: "fa-brands fa-x-twitter",
  },
  yt: {
    name: "YouTube",
    icon: "fa-brands fa-youtube",
  },
};

export default channelsMap;
