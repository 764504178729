import api from './api';

export const initializeDashboard = () => {
  return dispatch => {
    dispatch({ type: 'INITIALIZE_DASHBOARD' });
    dispatch(getPosts());
    dispatch(getNotifications());
  }
};

const getPosts = () => {
  return dispatch => {
    const postTypes = ['latest-news', 'resource', 'opportunity'];

    postTypes.forEach(postType => {
      const key = postType === 'latest-news' ? 'blog' : postType;
      const params = { postType, page: 1, per: 1 };

      dispatch({ type: 'GET_DASHBOARD_FEATURED_POST_FETCHING', payload: { key, params }});

      api.get('/api/posts/featured', { params }).then(({ data }) => {
        const item = Object.keys(data).length ? data : null;

        dispatch({ type: 'GET_DASHBOARD_FEATURED_POST_SUCCESS', payload: { key, item }});
      }).catch(err => {
        dispatch({ type: 'GET_DASHBOARD_FEATURED_POST_ERROR', payload: { key, err }});
      });
    });
  };
};

const getNotifications = () => {
  return dispatch => {
    dispatch({ type: 'GET_NOTIFICATIONS_FETCHING' });

    api.get('/api/notifications').then(({ data }) => {
      const notifications = (data.items || []).map(notification => ({
        title: notification.post_title || '',
        content: decodeURIComponent(notification.post_content || ''),
        postDate: notification.post_date,
        linkUrl: notification.notification_url,
      }));

      dispatch({ type: 'GET_NOTIFICATIONS_SUCCESS', payload: { notifications } });
    }).catch(error => {
      dispatch({ type: 'GET_NOTIFICATIONS_ERROR', payload: { error } });
    })
  }
};
