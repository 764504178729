import React from 'react';
import { connect } from 'react-redux';
import CustomDropdown from '../../shared/customDropdown/CustomDropdown';
import { setAffiliateCode } from '../../../redux/actions/user';

const AffiliateCodeDropdown = ({ dispatch, items, selected }) => {
  const onChange = (value) => {
    dispatch(setAffiliateCode(value));
  };

  return (
    <CustomDropdown
      placeholderText="Select Affiliate Account"
      items={items}
      selected={selected}
      onChange={onChange}
    />
  );
}

const mapStateToProps = ({ user }) => {
  return {
    selected: user.affiliateCode,
    items: user.affiliateCodeList,
  };
};

export default connect(mapStateToProps)(AffiliateCodeDropdown);
