import api from "./api";
import { navigateToPath } from "./ui";

export const initializeOpportunitiesPage = (page) => {
  return (dispatch) => {
    dispatch({ type: "INITIALIZE_OPPORTUNITIES_PAGE", payload: { page } });
    dispatch(getOpportunities(page));
  };
};

export const goToOpportunitiesContainer = () => {
  return (dispatch) => {
    dispatch({ type: "_GO_TO_OPPORTUNITIES_CONTAINER" });
    dispatch(navigateToPath(`/opportunities?page=1`));
  };
};

export const changeOpportunityContainerPage = (pageNumber) => {
  return (dispatch) => {
    dispatch({
      type: "_CHANGE_OPPORTUNITY_CONTAINER_PAGE",
      payload: { pageNumber },
    });
    dispatch(navigateToPath(`/opportunities?page=${pageNumber}`));
  };
};

export const setNewOpportunityPage = (nextPage) => {
  return (dispatch) => {
    dispatch({ type: "SET_NEW_OPPORTUNITY_PAGE", payload: { page: nextPage } });
  };
};

export const getOpportunity = (id) => {
  return (dispatch) => {
    dispatch({ type: "GET_OPPORTUNITY_FETCHING" });
    if (isNaN(+id)) {
      return dispatch({
        type: "GET_OPPORTUNITY_ERROR",
        payload: { pageNotFound: true },
      });
    }
    api
      .post(`/api/posts/detail`, { id })
      .then((resp) => {
        let item = resp?.data?.items?.[0];
        if (!item) {
          return dispatch({
            type: "GET_OPPORTUNITY_ERROR",
            payload: { pageNotFound: true },
          });
        }
        dispatch({ type: "GET_OPPORTUNITY_SUCCESS", payload: { item } });
      })
      .catch((err) => {
        dispatch({ type: "GET_OPPORTUNITY_ERROR", payload: { err } });
      });
  };
};

export const getOpportunities = (page = 1) => {
  return (dispatch, getState) => {
    dispatch({ type: "GET_OPPORTUNITIES_FETCHING" });
    let per = 6;

    api
      .post("/api/posts/list", { page, per, postType: "opportunity" })
      .then((resp) => {
        let totalItems = resp.data?.pagination?.total;
        let totalPages = null;
        // haven't calculated total pages yet
        if (getState().opportunities.pagination.totalPages === null) {
          if (totalItems > resp.data.items.length) {
            // more exist than came back in page one
            totalPages = Math.ceil(totalItems / 6);
          } else {
            totalPages = 1;
          }
        }

        let pagination = {
          page: +page,
        };

        if (totalPages !== null) {
          pagination.totalPages = +totalPages;
        }

        const items = resp.data.items;

        dispatch({
          type: "GET_OPPORTUNITIES_SUCCESS",
          payload: {
            items,
            pagination,
          },
        });
      })
      .catch((err) => {
        dispatch({ type: "GET_OPPORTUNITY", payload: { err } });
      });
  };
};
