import en from './en';

const fr = {
  ...en,
  greeting: 'Bonjour {name}, bienvenue sur votre tableau de bord',
  // Nav Items
  dashboard: 'Tableau de bord',
  notifications: 'Notifications',
  documents: 'Les documents',
  reports: 'Rapports',
  opportunities: 'Opportunités',
  resources: 'Ressources',
  contactUs: 'Nous contacter',
  // Footer Items
  privacyPolicy: 'Politique de confidentialité de {company}',
  termsOfService: "{company} Conditions d'utilisation",
  allRightsReserved: 'Studio71 Tous les droits sont réservés',
  redArrowCompany: 'Une entreprise de Red Arrow Studios',
  needHelpContactUs: "Besoin d'aide? Nous contacter",
  // amazon link builder
  amazonAffiliateLinkBuilder: "Amazon Affiliate Link Builder",
  singleItemLink: "LIEN UNIQUE",
  multiItemLink: "LIEN MULTI-ARTICLES",
  forMoreInfo: "Pour plus d'informations sur le programme d'affiliation Amazon",
  clickHere: "cliquez ici",
  pleaseNoteThat: ". Veuillez noter qu'en raison des lois fiscales, les résidents d'AR & ME ne sont pas éligibles.",
  singleItemLinkBuilder: "CONSTRUCTEUR DE LIEN UNIQUE",
  multiItemLinkBuilder: "CONSTRUCTEUR DE LIENS MULTI-ARTICLES",
  pasteALink: "Collez un lien vers n'importe quel produit, département ou magasin personnalisé d'Amazon pour générer votre lien d'affiliation personnalisé.",
  pasteMultipleLinks: "Collez plusieurs liens de produits Amazon, sur des lignes distinctes, pour générer votre lien d'affiliation personnalisé.",
  enterAUrlHere: "ENTREZ UNE URL ICI",
  enterUrlsHere: "ENTREZ LES URLS ICI",
  enterAValidAmazon: "Saisissez un lien Amazon ou Joyo valide.",
  enterValidAmazons: "Saisissez des liens Amazon ou Joyo valides.",
  originalLink: "Lien d'origine:",
  originalLinks: "Liens originaux:",
  anErrorMulti: "Une erreur s'est produite lors de la création de votre lien d'affiliation Amazon! Veuillez vérifier vos liens et réessayer plus tard.",
  anErrorSingle: "Une erreur s'est produite lors de la création de votre lien d'affiliation Amazon! Veuillez vérifier votre lien et réessayer plus tard.",
  createLink: "Créer un lien",
  copyLink: "Copier le lien",
  copied: "Copié!",
  createAnotherLink: "Créer un autre lien",
  startOver: "Recommencer",
  getStarted: "Commencer",
  findAProduct: "1. Trouver un produit sur Amazon",
  theFirstStep: "La première étape consiste à trouver un produit sur Amazon que vous souhaitez partager avec votre public.",
  copyAndPaste: "2. Copiez et collez dans Link Builder",
  copyTheLink: "Copiez le lien du produit et collez-le dans le générateur de liens à gauche.",
  viewFullGuidelines: "Voir les directives complètes",
  toolToBuild: "Outil pour créer des liens vers des produits sur Amazon en tant qu'Amazon Associate.",
  click: "Cliquez sur",
  here: "ici",
  forTermsAndConditions: "pour les termes et conditions",
  // opportunities
  allOpportunities: "Toutes les opportunités",
};

export default fr;
