import React from 'react';
import { FormattedMessage } from 'react-intl';

const PhotoUploadError = ({ error }) => {
  if (error) {
    return (
      <em className="error">
        <FormattedMessage id={error} defaultMessage="Error uploading photo" />
      </em>
    );
  }
  return null;
};

export default PhotoUploadError;
