import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { gaLogoutClick } from "../../redux/actions/ga";
import { handleContactNavLinkClick } from "../../redux/actions/ui";
import { logout, logoutClicked } from "../../redux/actions/user";
import Tooltip from "../shared/tooltip/Tooltip";
import BREAKPOINTS from "../../constants/breakpoints";

const BottomNavItems = () => {
  const dispatch = useDispatch();
  const navVisibilityClass = useSelector(
    (state) => state.mainNav.navVisibilityClass,
  );
  const disableTooltip =
    navVisibilityClass === "opened" || window.innerWidth < BREAKPOINTS.DESKTOP;

  const handleLogout = () => {
    dispatch(logoutClicked());
    dispatch(gaLogoutClick());
    dispatch(logout());
  };

  const handleContactUsClick = (e) => {
    e.preventDefault();
    dispatch(handleContactNavLinkClick());
  };

  return (
    <ul>
      <li onClick={handleContactUsClick}>
        {/* for styling purposes, replaces NavLink  */}
        <button className="link">
          <Tooltip
            className="nav-tooltip"
            content={<FormattedMessage id="contactUs" />}
            placement="right"
            disable={disableTooltip}
          >
            <span className="icon">
              <i className="far fa-envelope" />
            </span>
          </Tooltip>
          <span className="link-text">
            <FormattedMessage id="contactUs" defaultMessage="Contact Us" />
          </span>
        </button>
      </li>
      <li>
        <button className="link" onClick={handleLogout}>
          <Tooltip
            className="nav-tooltip"
            content={<FormattedMessage id="logout" />}
            placement="right"
            disable={disableTooltip}
          >
            <span className="icon">
              <i className="fa fa-sign-out-alt" />
            </span>
          </Tooltip>
          <span className="link-text">
            <FormattedMessage id="logout" defaultMessage="Logout" />
          </span>
        </button>
      </li>
    </ul>
  );
};

export default BottomNavItems;
