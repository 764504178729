const initialState = {
  loading: true, // this is so the url doesn't add accountId until a successful BE call is made, maintaining Error page's Go Back button functionality
  success: false,
  error: false,
  errorMessageKey: "genericPaymentLoadingError",
  paymentInfo: [],
};

const payment = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "GET_PAYMENT_SETTINGS_LOADING":
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
      };

    case "GET_PAYMENT_SETTINGS_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        paymentInfo: payload.items || [],
      };

    case "GET_PAYMENT_SETTINGS_ERROR": {
      let nextErrorMessageKey = "genericPaymentLoadingError";
      if (
        payload.error?.status === 403 &&
        payload.error?.data?.message ===
          "Not allowed to access this account information"
      ) {
        nextErrorMessageKey = "noAccessPaymentLoadingError";
      }
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        errorMessageKey: nextErrorMessageKey,
      };
    }

    default:
      return state;
  }
};

export default payment;
