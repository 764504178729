import React from "react";
import { connect } from "react-redux";
import {
  createSingleShortLink,
  resetLinkBuilder,
} from "../../../redux/actions/linkBuilder";
import SingleItemInput from "./SingleItemInput";
import ShortLinkDisplay from "./ShortLinkDisplay";
import PageLoader from "../../loader/PageLoader";
import ShortLinkError from "./ShortLinkError";
import { FormattedMessage } from "react-intl";

const SingleItemLinkBuilder = ({
  dispatch,
  singleText,
  singleIsLoading,
  singleTextValid,
  singleShortLink,
  singleShortLinkError,
  singleCreateButtonText,
}) => {
  const textValidationError = singleTextValid === false;

  const onClick = () => {
    if (singleShortLinkError) {
      dispatch(resetLinkBuilder("single"));
    } else if (singleShortLink) {
      dispatch(resetLinkBuilder("single"));
    } else if (singleTextValid) {
      dispatch(createSingleShortLink());
    }
  };

  let linkInputOrError = <SingleItemInput />;

  if (singleShortLinkError) {
    linkInputOrError = <ShortLinkError singleMulti={"single"} />;
  } else if (singleShortLink !== null) {
    linkInputOrError = <ShortLinkDisplay singleMulti={"single"} />;
  }

  return (
    <PageLoader loading={singleIsLoading}>
      <div className="single-item-link-builder">
        <h3>
          <FormattedMessage id="singleItemLinkBuilder" />
        </h3>
        <p>
          <FormattedMessage id="pasteALink" />
        </p>
        {linkInputOrError}
        <button
          onClick={onClick}
          className={
            textValidationError || singleText === ""
              ? "secondary linkSubmit disabled"
              : "secondary linkSubmit"
          }
        >
          <FormattedMessage id={singleCreateButtonText} />
        </button>
      </div>
    </PageLoader>
  );
};

const mapStateToProps = ({ amazonLinkBuilder }) => {
  return {
    singleText: amazonLinkBuilder.singleText,
    singleIsLoading: amazonLinkBuilder.singleIsLoading,
    singleTextValid: amazonLinkBuilder.singleTextValid,
    singleShortLink: amazonLinkBuilder.singleShortLink,
    singleShortLinkError: amazonLinkBuilder.singleShortLinkError,
    singleCreateButtonText: amazonLinkBuilder.singleCreateButtonText,
  };
};

export default connect(mapStateToProps)(SingleItemLinkBuilder);
