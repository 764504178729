import React from 'react';
import amazonInstructions from '../../../assets/amazonLinkBulderInstructions.pdf';
import { FormattedMessage } from 'react-intl';

const AmazonLinkBuilderTile = ({
  goToLinkBuilder,
  affiliateCode,
}) => {
  return (
    <article className={`${affiliateCode === null ? 'no-affiliate-code ' : ''}amazon-link-builder-tile`}>
      <div onClick={goToLinkBuilder} className="opaque-container">
        <div className="tile-image" />
        <div className="tile-content">
          <h3><FormattedMessage id="amazonAffiliateLinkBuilder" /></h3>
          <p><FormattedMessage id="toolToBuild" /></p>
          <p>
            <a href={amazonInstructions} target="_blank" rel="noopener noreferrer"><FormattedMessage id="clickHere" /></a>
            <FormattedMessage id="forTermsAndConditions" />
          </p>
        </div>
      </div>
    </article>
  );
}

export default AmazonLinkBuilderTile;
