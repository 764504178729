import React from 'react';
import { FormattedMessage } from "react-intl";

const OverlapHeader = () => {
   return [
      <li key="title"><FormattedMessage id="title" /></li>,
      <li key="percentage"><FormattedMessage id="overlapPercentage" /></li>,
  ];
}

export default OverlapHeader;
