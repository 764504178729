import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { updateInputField } from '../../../redux/actions/ugcForm';

const TimeStampField = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const messages = defineMessages({
    timestamps: { id: 'timestamps' },
    enterTimestampLabel: { id: 'enterTimestampLabel' },
    entireVideo: { id: 'entireVideo' },
    timestampError: { id: 'timestampError' },
  });

  const platform = useSelector(state => state.ugcForm.inputs.platform);
  const timestampValue = useSelector(state => state.ugcForm.inputs?.timestamp || '');
  const entireVideoValue = useSelector(state => state.ugcForm.inputs?.entireVideo || false);
  const validationError = useSelector(state => state.ugcForm.validationErrors.timestamp);

  const handleInputChange = ({ target }) => {
    const { name, value } = target;
    dispatch(updateInputField({ name, value }));
  };

  if (platform !== 'YouTube') {
    return null;
  }

  return (
    <div className="timestamp">
      <span className="label">{intl.formatMessage(messages.timestamps)}</span>
      <div>
        <input
          type="text"
          name="timestamp"
          placeholder={intl.formatMessage(messages.enterTimestampLabel)}
          onChange={handleInputChange}
          value={timestampValue}
        />
        <span>
          <input
            name="entireVideo"
            type="checkbox"
            onChange={handleInputChange}
            checked={entireVideoValue}
          />
          {intl.formatMessage(messages.entireVideo)}
        </span>
      </div>
      {validationError && <em className="error-message">{intl.formatMessage(messages.timestampError)}</em>}
    </div>
  );
};

export default TimeStampField;
