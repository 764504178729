import React, { Component } from 'react';
import IconDisplay from './IconDisplay';
import { FormattedMessage } from 'react-intl';

class ModalContent extends Component {
  constructor(props) {
    super(props);
    this.state = { closing: false };
    this.handlePreClose = this.handlePreClose.bind(this);
    this.timeout = null;
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  handlePreClose() {
    this.setState({ closing: true });

    this.timeout = setTimeout(() => {
      this.props.closeModal();
    }, 300);
  }

  render() {
    const { header, content, closeOnOutsideClick, icon, className, hideCloseButton } = this.props;
    const closingClassName = this.state.closing ? ' closing' : '';
    const additionalClassName = className ? ` ${className}` : '';

    return (
      <div className={`creator-app-modal${additionalClassName}${closingClassName}`} data-testid="modal">
        <div className="modal-overlay" onClick={closeOnOutsideClick ? this.handlePreClose : null} data-testid="modal-overlay"/>
        <div className="modal-content-container">
          {hideCloseButton || <div className="close" onClick={this.handlePreClose}>
            <i data-testid="close-button" className="fas fa-times" />
            <span><FormattedMessage id="close" defaultMessage="Close" /></span>
          </div>}
          <div className="header-container">
            <div className="header-icon">
              <IconDisplay icon={icon}/>
            </div>
            <h3>{header}</h3>
          </div>
          <div className="modal-content">
            {content}
          </div>
        </div>
      </div>
    );
  }
};

export default ModalContent;
