const initialState = {
  loading: true,
  error: false,
  errorMessage: '',
  pageNotFound: false,
  items: [], // drawn in the container page
  item: {},   // drawn in the detail page
  pagination: {
    page: 1,
    totalPages: null,
  }
};

const opportunities = (state = initialState, action) => {
  const { type, payload } = action;

  switch(type) {
    case 'INITIALIZE_OPPORTUNITIES_PAGE':
      return {
        ...initialState,
        pagination: {
          ...state.pagination,
          page: +payload.page,
        }
      };
    case 'GET_OPPORTUNITY_FETCHING':
    case 'GET_OPPORTUNITIES_FETCHING':
      return {
        ...state,
        loading: true,
        error: false,
        pageNotFound: false,
        errorMessage: '',
      };
    case 'GET_OPPORTUNITIES_SUCCESS':
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        items: payload.items,
        pagination: {
          ...state.pagination,
          ...payload.pagination
        }
      };
    case 'GET_OPPORTUNITIES_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: 'Error loading opportunities. Please try again later!',
      };
    case 'GET_OPPORTUNITY_SUCCESS':
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        pageNotFound: false,
        item: payload.item,
      };
    case 'GET_OPPORTUNITY_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        pageNotFound: payload.pageNotFound || false,
        errorMessage: 'Error loading this opportunity. Please try again later!',
      };
    default:
      return state;
  };
};

export default opportunities;
