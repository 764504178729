import React from 'react';
import { connect } from 'react-redux';
import { linkBuilderTextChange } from '../../../redux/actions/linkBuilder';
import AffiliateCodeDropdown from './AffiliateCodeDropdown';
import {
  useIntl,
  defineMessages,
  FormattedMessage,
} from 'react-intl';

const SingleItemInput = ({ dispatch, singleText, singleTextValid, affiliateCodeList }) => {
  const intl = useIntl();

  const textValidationError = (singleTextValid === false);

  const onChange = (e) => {
    dispatch(linkBuilderTextChange("single", e.target.value));
  }

  // this is so the react-intl works as textarea placeholder
  const messages = defineMessages({
    placeholder: {
      id: 'enterAUrlHere',
      defaultMessage: 'ENTER A URL HERE'
    }
  });

  return (
    <div className={`textInput ${affiliateCodeList.length > 1 ? 'multiple-codes' : ''}`}>
      {affiliateCodeList.length > 1 && <AffiliateCodeDropdown />}
      <input
        type="text"
        onChange={onChange}
        placeholder={intl.formatMessage(messages.placeholder)}
        value={singleText}
        className={textValidationError ? 'error' : ''}
      />
      { textValidationError  &&  <p className="error"><FormattedMessage id="enterAValidAmazon" /></p> }
    </div>
  );
}

const mapStateToProps = ({ amazonLinkBuilder, user }) => {
  return {
    singleText: amazonLinkBuilder.singleText,
    singleTextValid: amazonLinkBuilder.singleTextValid,
    affiliateCodeList: user.affiliateCodeList,
  };
};

export default connect(mapStateToProps)(SingleItemInput);
