import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { navigateToPath } from '../../redux/actions/ui';
import NavToggle from './NavToggle';

const MainLogo = () => {
  const dispatch = useDispatch();

  const handleLogoClick = useCallback(() => dispatch(navigateToPath('/')), [dispatch]);

  return (
    <div className="logo-container">
      <h1 onClick={handleLogoClick}>
        <span className="header-text">
          <FormattedMessage id="appName" defaultMessage="Studio 71 Creator App" />
        </span>
        <span className="arrow-logo" />
      </h1>
      <NavToggle />
    </div>
  );
};

export default MainLogo;
