import React from 'react';
import { toPercent } from '../../../util/helpers';
import { FormattedNumber } from 'react-intl';

const CardRows = (props) => {
  const cards = props?.data?.videos?.cards || [];

  return cards.map(item => {
    return (
      <ul className="report-row" key={item.id}>
        <li>{item.title}</li>
        <li>{<FormattedNumber value={item.card_teaser_clicks} />}</li>
        <li>{toPercent(item.card_ctr)}</li>
      </ul>
    );
  });
}

export default CardRows;
