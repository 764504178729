import React from 'react';
import { FormattedMessage } from "react-intl";
import FormButton from './FormButton';

const FormSuccess = ({
  location,
}) => {
  return (
    <div className="contact-us-form success">
      <FormattedMessage
        id="contactSuccess"
        defaultMessage="Message sent! We'll be in touch soon!"
      />
      <div className="actions">
        <FormButton location={location} />
      </div>
    </div>
  );
};

export default FormSuccess;
