import React, { useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import Modal from '../../modal/Modal';
import AddChannelModalContents from './AddChannelModalContents';
import { closeAddChannelModal } from '../../../redux/actions/settings';
import { FormattedMessage } from 'react-intl';
import SuccessMessageDisplay from './SuccessMessageDisplay';

const AddChannelModal = () => {
  const dispatch = useDispatch();
  const { addChannelSuccess, addChannelModalOpened, } = useSelector(state => {
    return {
      addChannelModalOpened: state.settings.channel.addChannelModalOpened,
      addChannelSuccess: state.settings.channel.addChannelSuccess,
    }
  }, shallowEqual);

  const closeModal = useCallback(() => {
    dispatch(closeAddChannelModal());
  }, [dispatch]);

  return (
    <Modal className="add-channel-modal"
      opened={addChannelModalOpened}
      closeModal={closeModal}
      closeOnOutsideClick={true}
      header={<FormattedMessage id="addAChannel" defaultMessage="Add a Channel" />}
      icon="fas fa-plus"
    >
      {addChannelSuccess ? <SuccessMessageDisplay /> : <AddChannelModalContents />}
    </Modal>
  );
};

export default AddChannelModal;
