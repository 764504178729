const initialState = {
  inputs: {
    copyrightLink: '',
    infringingLink: '',
    additionalNotes: '',
    platform: '',
    policyOption: '',
    policyOther: '',
    timestamp: '',
    entireVideo: false,
  },
  sending: false,
  sendError: false,
  sendSuccess: false,
  formIsValid: false,
  validationErrors: {
    copyrightLink: false,
    infringingLink: false,
    platform: false,
    policyOption: false,
    policyOther: false,
    timestamp: false,
  },
};

const ugcForm = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'RESET_UGC_FORM':
      return {
        ...initialState
      };

    case 'UGC_FORM_UPDATE_INPUT':
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [payload.name]: payload.value,
        }
      };

    case 'UGC_VALID_FIELD':
      return {
        ...state,
        validationErrors: {
          ...state.validationErrors,
          [payload.name]: false,
        }
      };

    case 'UGC_INVALID_FIELD':
      return {
        ...state,
        validationErrors: {
          ...state.validationErrors,
          [payload.name]: true,
        }
      };

    case 'UGC_VALIDATE_FORM':
      return {
        ...state,
        formIsValid: payload.isValid,
      };

    case 'SUBMIT_UGC_FORM_SENDING':
      return {
        ...state,
        sending: true,
        sendError: false,
      };

    case 'SUBMIT_UGC_FORM_SUCCESS':
      return {
        ...state,
        sending: false,
        sendSuccess: true,
      };

    case 'SUBMIT_UGC_FORM_ERROR':
      return {
        ...state,
        sending: false,
        sendError: true,
      };

    default:
      return state;
  };
};

export default ugcForm;
