import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import UserGroupAuthError from "./login/UserGroupAuthError";
import PageNotFoundContainer from "./pageNotFound/PageNotFoundContainer";
import {
  paymentSettingsPath,
  filterAccountsForPaymentSettings,
} from "../util/paymentSettingsUtils";

// A wrapper for <Route> that redirects to login
// if you have no auth or expired auth.
// from react router docs https://reacttraining.com/react-router/web/example/auth-workflow

const PrivateRoute = (props) => {
  const { location, computedMatch, render } = props;
  const search = location?.search;
  const userAccounts = useSelector((state) => state.user.accounts || []);
  const { loggedIn, userGroupAuthError, matchedRestrictionGroup } = useSelector(
    (state) => {
      return {
        loggedIn: state.user.loggedIn,
        userGroupAuthError: state.user.userGroupAuthError,
        matchedRestrictionGroup: state.user.matchedRestrictionGroup || {},
      };
    },
    shallowEqual,
  );

  // if a user has at least one account with any of these permission_groups,
  // they have access to the Payment Settings page
  const paymentSettingsHasAccess =
    filterAccountsForPaymentSettings(userAccounts).length >= 1;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.path, search]);

  // not logged in, go to login page
  if (loggedIn === false) {
    return <Redirect to={{ pathname: "/login", state: { from: location } }} />;
  } else if (userGroupAuthError) {
    return <UserGroupAuthError />;
  } else if (
    (matchedRestrictionGroup?.restrictedRoutes || []).filter(
      (g) => g === location.pathname,
    ).length
  ) {
    return <PageNotFoundContainer />;
  } else if (props.path === paymentSettingsPath && !paymentSettingsHasAccess) {
    return <PageNotFoundContainer />;
  }

  return render({ location, match: computedMatch });
};

export default PrivateRoute;
