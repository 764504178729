import api from './api';

export const resetForm = () => {
  return dispatch => {
    dispatch({ type: 'RESET_UGC_FORM' });
  };
};

export const updateInputField = ({name, value}) => {
  return (dispatch, getState) => {
    name === 'entireVideo' && (value = !getState().ugcForm.inputs.entireVideo);
    dispatch({ type: 'UGC_FORM_UPDATE_INPUT', payload: { name, value } });
    dispatch(validateField((name === 'entireVideo' ? 'timestamp' : name)));
  };
};

// Validate individual field when updated
const validateField = (name) => {
  return (dispatch, getState) => {
    const inputs = getState().ugcForm.inputs;
    const value = inputs[name];
    const urlFields = ['copyrightLink', 'infringingLink'];
    const urlReg = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/g;
    const isValid = () => {
      if (urlFields.indexOf(name) > -1) {
        return urlReg.test(value);
      } else if (name === 'timestamp') {
        return (inputs.entireVideo || inputs.timestamp.trim().length > 0);
      } else {
        return value.trim().length > 0;
      }
    };

    dispatch({ type: isValid() ? 'UGC_VALID_FIELD' : 'UGC_INVALID_FIELD', payload: { name } });
    dispatch(formValidationCheck());
  };
};

// Validate the entire form to detmine the ability to submit
const formValidationCheck = () => {
  return (dispatch, getState) => {
    const state = getState().ugcForm;
    const optionalFields = ['additionalNotes', 'entireVideo'];
    const errorsArr = Object.keys(state.validationErrors).map(key => state.validationErrors[key]);
    const fieldsArr = Object.keys(state.inputs).map(key => {
      const isOptional = optionalFields.indexOf(key) > -1;

      // Not a required field
      if (isOptional) {
        return null;
      }

      // Check for "Other Policy" field having a value if the dropdown has "Other" selected
      if (key === 'policyOther' && state.inputs.policyOption !== 'other') {
        return null;
      }

      // Check for the "Entire Video" checkbox being checked if timestamp is blank
      if (key === 'timestamp' && (state.inputs.entireVideo || state.inputs.platform !== 'YouTube')) {
        return null;
      }

      return state.inputs[key];
    }).filter(item => item !== null);

    const isValid = (fieldsArr?.every(field => field?.length > 0) && errorsArr?.every(error => error === false));

    dispatch({ type: 'UGC_VALIDATE_FORM', payload: { isValid } });
  };
};

export const submitForm = () => {
  return (dispatch, getState) => {
    const state = getState();
    const { formIsValid, inputs } = state.ugcForm;
    const {
      copyrightLink,
      infringingLink,
      additionalNotes,
      platform,
      policyOption,
      policyOther,
      timestamp,
      entireVideo,
    } = inputs || {};
    const { firstName, lastName, username, email, accounts } = state.user;
    const accountNames = accounts?.map(account => account.title)?.join(', ') || '';

    const data = {
      firstName,
      lastName,
      username,
      email,
      accountNames,
      copyrightLink,
      infringingLink,
      additionalNotes,
      platform,
      policy: policyOption === 'other' ? policyOther : policyOption,
      timestamp: entireVideo ? 'Entire Video' : timestamp,
    };

    if (formIsValid) {
      dispatch({ type: 'SUBMIT_UGC_FORM_SENDING', payload: data });
      api.post('/api/ugc-form/submit', data).then(() => {
        dispatch({ type: 'SUBMIT_UGC_FORM_SUCCESS' });
      }).catch(error => {
        dispatch({ type: 'SUBMIT_UGC_FORM_ERROR', payload: { error } });
      });
    }
  };
};