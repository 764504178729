import React from 'react';

const IconDisplay = ({ icon }) => {
  if (!icon) {
    return <div className="s71-icon" data-testid="header-icon" />;
  } else if (typeof icon === 'string') {
    return <i className={icon} data-testid="header-icon" />;
  } else {
    return icon;
  }
};

export default IconDisplay;
