import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import PageLoader from "../../loader/PageLoader";
import ChannelSettingsItem from "./ChannelSettingsItem";
import ChannelsIcon from "../../../svg/ChannelsIcon";
import {
  initializeChannelsSettingsPage,
  authorizeChannel,
  openAddChannelModal,
  closeAddChannelModal,
} from "../../../redux/actions/settings";
import channelsMap from "../../../util/channelsMap";
import AddChannelModal from "./AddChannelModal";
import { gaSettingsAction } from "../../../redux/actions/ga";
import PageHeader from "../../shared/PageHeader";

class ChannelSettingsContainer extends Component {
  constructor(props) {
    super(props);
    this.authorizeChannel = this.authorizeChannel.bind(this);
    this.closeAddChannelModal = this.closeAddChannelModal.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(initializeChannelsSettingsPage());
  }

  closeAddChannelModal() {
    this.props.dispatch(closeAddChannelModal());
  }

  authorizeChannel(channelId, domain) {
    if (domain) {
      this.props.dispatch(
        gaSettingsAction({
          s71_settings_page: "channels_page",
          s71_action: `add_${domain}`,
          s71_channelId: channelId,
        }),
      );
    } else {
      this.props.dispatch(
        gaSettingsAction({
          s71_settings_page: "channels_page",
          s71_action: "add_global",
        }),
      );
    }

    if (channelId && typeof channelId !== "object") {
      this.props.dispatch(
        gaSettingsAction({
          s71_settings_page: "channels_page",
          s71_action: `auth_${domain}`,
          s71_channelId: channelId,
        }),
      );
      this.props.dispatch(authorizeChannel({ channelId, domain }));
    } else {
      this.props.dispatch(openAddChannelModal(domain));
    }
  }

  render() {
    const { loading, error, errorMessage, channels, addChannelModalOpened } =
      this.props;

    return (
      <PageLoader
        loading={loading}
        error={error}
        errorMessage={errorMessage}
        fadeOut={true}
      >
        <section className="channel-settings-page">
          <div className="header-container">
            <PageHeader
              headerId="channels"
              subheaderId="channelsSettingsDescription"
            />
            <button
              className="primary add-channel"
              onClick={this.authorizeChannel}
            >
              <i className="fas fa-plus" />
              <FormattedMessage id="add" defaultMessage="Add" />
            </button>
          </div>
          <div className="opaque-container offset-header social-channels">
            <h3 className="gradient-header">
              <FormattedMessage
                id="socialPlatforms"
                defaultMessage="Social Platforms"
              />
              <span>
                <ChannelsIcon />
              </span>
            </h3>
            <div className="tiles row">
              {channels
                .filter((channel) => channel?.domain !== "pc")
                .map((channel, index) => (
                  <ChannelSettingsItem
                    key={channel.feed_id || `${channel.domain}-${index}`}
                    channel={channel}
                    handleAuthClick={this.authorizeChannel}
                  />
                ))}
            </div>
          </div>
          <div className="opaque-container offset-header podcasts">
            <h3 className="gradient-header">
              <FormattedMessage id="podcasts" defaultMessage="Podcasts" />
              <span>
                <i className={channelsMap.pc.icon} />
              </span>
            </h3>
            <div className="tiles row">
              {channels
                .filter((channel) => channel?.domain === "pc")
                .map((channel, index) => (
                  <ChannelSettingsItem
                    key={channel.feed_id || `${channel.domain}-${index}`}
                    channel={channel}
                    handleAuthClick={this.authorizeChannel}
                  />
                ))}
            </div>
          </div>
          <AddChannelModal opened={addChannelModalOpened} />
        </section>
      </PageLoader>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  return {
    channels: settings?.channel?.channels || [],
    loading: settings?.channel?.loading,
    error: settings?.channel?.error,
    errorMessage: settings?.channel?.errorMessage,
    addChannelModalOpened: settings?.channel?.addChannelModalOpened,
  };
};

export default connect(mapStateToProps)(ChannelSettingsContainer);
