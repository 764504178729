import api from "./api";
import moment from "moment";
import { navigateToPath } from "./ui";
import channelsMap from "../../util/channelsMap";
import {
  gaSettingsAction,
  gaModalOpen,
  gaModalClose,
} from "../../redux/actions/ga";

export const loadSettingsSection = (path) => {
  return (dispatch) => {
    dispatch({ type: "_LOAD_SETTINGS_SECTION", payload: { path } });
    dispatch(navigateToPath(path));
  };
};

export const initializeChannelsSettingsPage = () => {
  return (dispatch) => {
    dispatch({ type: "INITIALIZE_CHANNELS_SETTINGS_PAGE" });
    dispatch(getChannels());
  };
};

export const getChannels = () => {
  return async (dispatch, getState) => {
    const feeds = getState().user?.feeds || [];
    const feedIds = feeds.map((feed) => feed.feed_id);

    /* No feeds; its done */
    if (!feeds || !feeds.length) {
      dispatch({
        type: "GET_CHANNEL_DETAILS_FETCHING",
        payload: { ids: feedIds.join(",") },
      });

      const emptyChannelsArray = buildChannelsArray([]);

      return dispatch({
        type: "GET_CHANNEL_DETAILS_SUCCESS",
        payload: { channels: emptyChannelsArray },
      });
    }

    /* Ton of feeds; batch the calls.
     * FYI: [...Array(numberOfBatches).keys()] generates a range, like [0,1,2,3...numberOfBatches - 1]
     */
    const BATCH_SIZE = 50;

    if (feedIds.length > BATCH_SIZE) {
      const numberOfBatches = Math.ceil(feedIds.length / BATCH_SIZE);

      const batches = [...Array(numberOfBatches).keys()].reduce((acc, next) => {
        acc.push(
          feedIds.slice(next * BATCH_SIZE, next * BATCH_SIZE + BATCH_SIZE),
        );
        return acc;
      }, []);

      let batchResponses = [];

      batches.forEach(async (batch, i) => {
        const moreCallsToMake = i < batches.length - 1;
        const channelsResp = await dispatch(
          makeChannelsListCall({
            feedIds: batch.join(","),
            doNotDispatch: true,
          }),
        );

        batchResponses = batchResponses.concat(channelsResp);

        if (!moreCallsToMake) {
          const builtChannelsDuped = buildChannelsArray(batchResponses);

          const builtChannelsDedupedObj = builtChannelsDuped.reduce(
            (acc, next) => {
              const { feed_id, domain, noAccounts } = next;
              acc[`${domain}-${noAccounts}-${feed_id}`] = next;
              return acc;
            },
            {},
          );

          const builtChannelsDeduped = Object.keys(builtChannelsDedupedObj).map(
            (key) => builtChannelsDedupedObj[key],
          );

          return dispatch({
            type: "GET_CHANNEL_DETAILS_SUCCESS",
            payload: { channels: builtChannelsDeduped },
          });
        }
      });
    } else {
      /* Regular amount of feeds */
      dispatch(makeChannelsListCall({ feedIds }));
    }
  };
};

// doNotDispatch is used to batch the calls in the case where there's too many
// channels to call all at once and not get the UI to flicker.
// if this dispatches each time, the UI flickers every time this dispatches "GET_CHANNEL_DETAILS_SUCCESS"
const makeChannelsListCall = ({ feedIds, doNotDispatch = false }) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_CHANNEL_DETAILS_FETCHING",
      payload: { ids: feedIds },
    });

    return api
      .post("/api/channels/list", { ids: feedIds, parts: "base, progress" })
      .then((resp) => {
        const channels = buildChannelsArray(resp.data);

        if (doNotDispatch) return channels;

        return dispatch({
          type: "GET_CHANNEL_DETAILS_SUCCESS",
          payload: { channels },
        });
      })
      .catch((err) => {
        dispatch({
          type: "GET_CHANNEL_DETAILS_ERROR",
          payload: { error: err },
        });

        if (doNotDispatch) return [];
      });
  };
};

const buildChannelsArray = (data) => {
  /**
   * To check for accounts by channel this is creating an array of arrays of channels grouped by domain
   * if any channel array groups are empty they replaced with [{domain, noAccounts: true}]
   * then the nested arrays are flattened into one array of channel objects
   */
  const channelDomains = Object.keys(channelsMap);
  const channels = channelDomains
    .map((domain) => {
      const matchingChannels = (data || []).filter(
        (feed) => feed.domain === domain,
      );
      return matchingChannels.length
        ? matchingChannels
        : [{ domain: domain, noAccounts: true }];
    })
    .reduce((acc, val) => acc.concat(val), [])
    .map((c) => {
      c.thumb_url =
        c.thumb_url_default || c.thumb_url_large || c.thumb_url_high;
      return c;
    });

  return channels;
};

export const authorizeChannel = ({ channelId, domain }) => {
  return (dispatch) => {
    dispatch({ type: "AUTH_CHANNEL_FETCHING", payload: { channelId, domain } });

    api
      .post("/api/channels/authorize", { channelId, domain })
      .then((resp) => {
        const url = resp?.data?.url;
        dispatch({ type: "AUTH_CHANNEL_SUCCESS", payload: resp.data });
        url && window.open(url);
      })
      .catch((err) => {
        dispatch({ type: "AUTH_CHANNEL_ERROR", payload: err });
      });
  };
};

export const openAddChannelModal = (domain) => {
  return (dispatch, getState) => {
    const { settings, user } = getState();
    const currentDomain = settings?.channel?.addChannelSelectedPlatform;
    const addChannelSelectedAccount =
      settings?.channel?.addChannelSelectedAccount;

    const accountOptions = (user?.accounts || []).map((account) => {
      return { value: account.id, label: account.title };
    });

    const selectedAccount =
      accountOptions.length === 1
        ? accountOptions[0].value
        : addChannelSelectedAccount;

    const payload = {
      domain: domain || currentDomain,
      selectedAccount: selectedAccount,
      accountOptions,
    };

    dispatch(gaModalOpen({ modalName: "settings_channel_add" }));

    dispatch({ type: "OPEN_ADD_CHANNEL_MODAL", payload });
  };
};

export const closeAddChannelModal = () => {
  return (dispatch, getState) => {
    dispatch(gaModalClose({ modalName: "settings_channel_add" }));

    dispatch({ type: "CLOSE_ADD_CHANNEL_MODAL" });

    api
      .get("/api/channels/update")
      .then((resp) => {
        const channels = buildChannelsArray(resp?.data?.feedData || []);
        const userData = resp?.data?.userData || [];

        dispatch({
          type: "UPDATE_CHANNELS_DATA",
          payload: { channels, userData },
        });
      })
      .catch((error) => {
        const state = getState();
        const channels = state.settings?.channel?.channels || [];
        const userData = state.user?.feeds || [];

        dispatch({
          type: "UPDATE_CHANNELS_DATA",
          payload: { channels, userData, error },
        });
      });
  };
};

export const selectAddChannelPlatform = (value) => {
  return (dispatch) => {
    dispatch(
      gaSettingsAction({
        s71_settings_page: "channels_add_modal",
        s71_action: "select_platform",
        s71_platform: value,
      }),
    );

    dispatch({ type: "SELECT_ADD_CHANNEL_PLATFORM", payload: { value } });
  };
};

export const updateAddChannelUrl = (value) => {
  return (dispatch) => {
    dispatch(
      gaSettingsAction({
        s71_settings_page: "channels_add_modal",
        s71_action: "enter_channel_url",
        s71_url: value,
      }),
    );

    const regex =
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/gm;

    const validationError = !regex.test(value);

    dispatch({
      type: "UPDATE_ADD_CHANNEL_URL",
      payload: { value, validationError },
    });
  };
};

export const selectAddChannelAccount = (value) => {
  return (dispatch) => {
    dispatch(
      gaSettingsAction({
        s71_settings_page: "channels_add_modal",
        s71_action: "select_account",
        s71_platform: value,
      }),
    );
    dispatch({ type: "SELECT_ADD_CHANNEL_ACCOUNT", payload: { value } });
  };
};

export const postAddChannel = () => {
  return (dispatch, getState) => {
    const { settings } = getState();
    const params = {
      account: settings?.channel?.addChannelSelectedAccount,
      title: settings?.channel?.addChannelSelectedPlatform,
      url: settings?.channel?.addChannelURL,
      domain: settings?.channel?.addChannel,
    };

    dispatch(
      gaSettingsAction({
        s71_settings_page: "channels_add_modal",
        s71_action: "add_channel",
        s71_account: params.account,
        s71_title: params.title,
        s71_url: params.url,
        s71_domain: params.domain,
      }),
    );

    api
      .post("/api/channels/add", params)
      .then((resp) => {
        const { domain = "", feed_id = "" } = resp?.data;

        dispatch({
          type: "POST_ADD_CHANNEL_SUCCESS",
          payload: { domain, feed_id },
        });
        dispatch(authorizeChannel(`${domain}-${feed_id}`));
      })
      .catch((err) => {
        dispatch({ type: "POST_ADD_CHANNEL_ERROR", payload: err });
      });
  };
};

export const changeTextInput = (e) => {
  return (dispatch) => {
    const { name, value } = e.target;
    dispatch({
      type: "ACCOUNT_SETTINGS_CHANGE_TEXT_INPUT",
      payload: { name, value },
    });

    if (name === "newPassword" || name === "newPasswordConfirm") {
      dispatch(comparePasswords());
    }

    dispatch(updateSaveButtonStatus());
  };
};

export const updateAccountDetails = () => {
  return async (dispatch, getState) => {
    dispatch(
      gaSettingsAction({
        s71_settings_page: "account_settings",
        s71_action: "save",
      }),
    );

    const state = getState();
    const { id } = state.user;
    const {
      firstName,
      lastName,
      currentPassword,
      newPassword,
      newPasswordConfirm,
      showPasswordMatchError,
      enableSaveButton,
    } = state.settings.account;

    const params = {
      id,
      firstName,
      lastName,
      currentPassword,
      newPassword,
      newPasswordConfirm,
      changePassword:
        currentPassword.length && newPassword.length && !showPasswordMatchError,
    };

    if (!enableSaveButton) {
      return;
    }

    dispatch({
      type: "SAVE_ACCOUNT_SETTINGS_FETCHING",
      payload: { firstName, lastName },
    });

    return api
      .post("/api/user/update", params)
      .then((resp) => {
        dispatch({ type: "SAVE_ACCOUNT_SETTINGS_SUCCESS", payload: resp.data });
        dispatch(updateSaveButtonStatus());
      })
      .catch((error) => {
        const { data } = error;
        let errorType = "genericAccountSettingsError";

        if (data.message === "Invalid password.") {
          errorType = "savePasswordInvalidPassword";
        } else if (data.message === "Invalid current password.") {
          errorType = "savePasswordInvalidCurrentPassword";
        }

        dispatch({
          type: "SAVE_ACCOUNT_SETTINGS_ERROR",
          payload: { error: data, errorType },
        });
        dispatch(updateSaveButtonStatus());
      });
  };
};

export const showChangePasswordForm = () => {
  return (dispatch) => {
    dispatch(
      gaSettingsAction({
        s71_settings_page: "account_settings",
        s71_action: "change_password",
      }),
    );

    dispatch({ type: "SHOW_CHANGE_PASSWORD_FORM" });
  };
};

export const cancelAccountSettingsChange = () => {
  return (dispatch, getState) => {
    dispatch(
      gaSettingsAction({
        s71_settings_page: "account_settings",
        s71_action: "cancel",
      }),
    );
    const state = getState();
    const { user } = state;
    const payload = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    };

    dispatch({ type: "CANCEL_ACCOUNT_SETTINGS_CHANGE", payload });
    dispatch(navigateToPath("/settings"));
  };
};

const comparePasswords = () => {
  return (dispatch, getState) => {
    const state = getState().settings.account;
    const { newPassword, newPasswordConfirm } = state;

    dispatch({
      type: "COMPARE_PASSWORDS",
      payload: { passwordsMatch: newPassword === newPasswordConfirm },
    });
  };
};

const updateSaveButtonStatus = () => {
  return (dispatch, getState) => {
    const state = getState();
    const { user, settings } = state;

    const {
      currentPassword,
      newPassword,
      newPasswordConfirm,
      showPasswordMatchError,
    } = settings.account;

    const savedValues = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    };

    const formValues = {
      firstName: settings.account.firstName,
      lastName: settings.account.lastName,
      email: settings.account.email,
    };

    let passwordIsValid = null;

    if (
      currentPassword.length ||
      newPassword.length ||
      newPasswordConfirm.length
    ) {
      if (
        !currentPassword.length ||
        !newPassword.length ||
        !newPasswordConfirm.length ||
        showPasswordMatchError ||
        newPassword.length < 12
      ) {
        passwordIsValid = false;
      } else {
        passwordIsValid = true;
      }
    }
    const formHasChanged =
      JSON.stringify(savedValues) !== JSON.stringify(formValues);
    const enableButton =
      (formHasChanged && passwordIsValid !== false) || passwordIsValid;

    dispatch({
      type: "ENABLE_ACCOUNT_SETTINGS_SAVE_BUTTON",
      payload: { enableButton },
    });
  };
};

export const uploadPhoto = (fileData) => {
  return (dispatch) => {
    dispatch(
      gaSettingsAction({
        s71_settings_page: "account_settings",
        s71_action: "upload_photo",
      }),
    );
    const formData = new FormData();
    formData.append("image", fileData, fileData.name);

    dispatch({ type: "UPLOAD_ACCOUNT_PHOTO_FETCHING" });

    api
      .post("/api/user/photo", formData)
      .then((resp) => {
        const thumbnail = resp?.data?.thumbnail;
        dispatch({
          type: "UPLOAD_ACCOUNT_PHOTO_SUCCESS",
          payload: { thumbnail },
        });
      })
      .catch((err) => {
        const errorMessageId =
          err?.data?.error === "File too large"
            ? "fileTooLargeError"
            : "genericImageUploadError";

        dispatch({
          type: "UPLOAD_ACCOUNT_PHOTO_ERROR",
          payload: { error: errorMessageId },
        });
      });
  };
};

export const deletePhoto = () => {
  return (dispatch) => {
    dispatch(
      gaSettingsAction({
        s71_settings_page: "account_settings",
        s71_action: "remove_photo",
      }),
    );
    dispatch({ type: "DELETE_ACCOUNT_PHOTO_FETCHING" });

    api
      .delete("/api/user/photo", {})
      .then((resp) => {
        dispatch({ type: "DELETE_ACCOUNT_PHOTO_SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "DELETE_ACCOUNT_PHOTO_ERROR" });
      });
  };
};

export const openRemovePhotoModal = () => {
  return (dispatch) => {
    dispatch({ type: "OPEN_REMOVE_PHOTO_MODAL" });
  };
};

export const closeRemovePhotoModal = () => {
  return (dispatch) => {
    dispatch({ type: "CLOSE_REMOVE_PHOTO_MODAL" });
  };
};

export const ignoreAuthRoadblock = () => {
  return (dispatch, getState) => {
    const { id } = getState().user;
    const expireDate = moment().add(2, "d").toISOString();

    dispatch({
      type: "_IGNORE_AUTH_ROADBLOCK_FETCHING",
      payload: { expireDate },
    });

    localStorage.setItem(`ignoreAuthRoadblock${id}`, expireDate);

    api
      .post("/api/user/ignore-roadblock")
      .then((resp) => {
        dispatch({ type: "_IGNORE_AUTH_ROADBLOCK_SUCCESS", payload: resp });
      })
      .catch((error) => {
        dispatch({ type: "_IGNORE_AUTH_ROADBLOCK_ERROR", payload: { error } });
      });
  };
};

export const initializeSurveysPage = (page) => {
  return (dispatch) => {
    dispatch({ type: "INITIALIZE_SURVEYS_PAGE", payload: { page } });
    dispatch(getSurveys(page));
  };
};

export const getSurveys = (page = 1) => {
  return (dispatch, getState) => {
    dispatch({ type: "GET_SURVEYS_FETCHING" });
    api
      .post("/api/posts/list", { page, per: 6, postType: "survey" })
      .then(({ data }) => {
        const {
          pagination: { total },
          items,
        } = data || {};

        dispatch({
          type: "GET_SURVEYS_SUCCESS",
          payload: {
            items: items || [],
            pagination: {
              page: +page,
              totalPages: Math.ceil((+total || 0) / 6),
            },
          },
        });
      })
      .catch((err) => {
        dispatch({ type: "GET_SURVEYS_ERROR", payload: { err } });
      });
  };
};

export const changeSurveysContainerPage = (pageNumber) => {
  return (dispatch) => {
    dispatch({
      type: "_CHANGE_SURVEYS_CONTAINER_PAGE",
      payload: { pageNumber },
    });
    dispatch(navigateToPath(`/surveys?page=${pageNumber}`));
  };
};

export const getPaymentSettings = ({ accountIds }) => {
  return (dispatch) => {
    dispatch({ type: "GET_PAYMENT_SETTINGS_LOADING" });

    api
      .get("/api/user/tax-and-banking", { params: { accountIds } })
      .then(({ data }) => {
        dispatch({
          type: "GET_PAYMENT_SETTINGS_SUCCESS",
          payload: { items: data?.items },
        });
      })
      .catch((error) => {
        dispatch({ type: "GET_PAYMENT_SETTINGS_ERROR", payload: { error } });
      });
  };
};
