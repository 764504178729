import React from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "../PrivateRoute";
import SettingsOverviewContainer from "./SettingsOverviewContainer";
import ChannelSettingsContainer from "./channelSettings/ChannelSettingsContainer";
import AccountSettingsContainer from "./accountSettings/AccountSettingsContainer";
import SurveySettingsContainer from "./surveysSettings/SurveysContainer";
import PaymentSettingsContainer from "./paymentSettings/PaymentSettingsContainer";

const SettingsRoutes = () => {
  return (
    <Switch>
      <PrivateRoute
        exact
        path="/settings"
        render={(routeProps) => <SettingsOverviewContainer {...routeProps} />}
      />
      <PrivateRoute
        path="/settings/channels"
        render={(routeProps) => <ChannelSettingsContainer {...routeProps} />}
      />
      <PrivateRoute
        path="/settings/account"
        render={(routeProps) => <AccountSettingsContainer {...routeProps} />}
      />
      <PrivateRoute
        path="/settings/surveys"
        render={(routeProps) => <SurveySettingsContainer {...routeProps} />}
      />
      <PrivateRoute
        path="/settings/payment"
        render={(routeProps) => <PaymentSettingsContainer {...routeProps} />}
      />
    </Switch>
  );
};

export default SettingsRoutes;
