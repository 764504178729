import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toggleMainNav } from '../../redux/actions/ui';
import BREAKPOINTS from '../../constants/breakpoints';
import MainLogo from './MainLogo';
import NavOverlay from './NavOverlay';
import NavContent from './NavContent';

const MainNavContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navVisibilityClass = useSelector(state => state.mainNav.navVisibilityClass || '');
  const loggedIn = useSelector(state => state.user.loggedIn);
  const userGroupAuthError = useSelector(state => state.user.userGroupAuthError);
  const { pathname } = location;

  //create a ref for the pathname.  Using this as a prevProps type of approach
  const pathnameRef = useRef(null);

  useEffect(() => {
    if (window.innerWidth < BREAKPOINTS.DESKTOP && navVisibilityClass === 'opened') {
      // Only want to toggle the nav here if the pathname changes
      pathnameRef.current !== pathname && dispatch(toggleMainNav());
    } else if (navVisibilityClass === 'closed') {
      // this removes the overlay to be removed from the dom after enough time for the fade out to be completed
      window.setTimeout(() => {
        dispatch(toggleMainNav('clear'));
      }, 500);
    }
    //set the new pathname to the ref for future comparisons
    pathnameRef.current = pathname;
  }, [pathname, navVisibilityClass, dispatch]);

  if (!loggedIn || userGroupAuthError) {
    return null;
  }

  return (
    <header className={`main-nav ${navVisibilityClass}`}>
      <div className="overlay" />
      <MainLogo />
      <NavContent />
      <NavOverlay navVisibilityClass={navVisibilityClass} />
    </header>
  );
};


export default MainNavContainer;
