import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import ContainerTile from '../shared/containerTile/ContainerTile';
import EmptyTile from '../shared/containerTile/EmptyTile';
import BlogPreviewItem from '../blog/BlogPreviewItem';

const PostPreviewItems = ({blog, resource, opportunity, handlers}) => {
  return (
    <Fragment>
      <div data-testid="resource" className="dashboard-tile col-12 col-md-4 order-2 order-md-3">
        <h3 className="section-header"><FormattedMessage id="featuredResource" defaultMessage="Featured Resource" /></h3>
        <ContainerTile
          {...resource.item}
          type="resource"
          container="dashboard"
          className="dashboard"
          handleTileClick={handlers.handleResourceClick}
          loading={resource.loading}
          error={resource.error}
          errorMessage={<FormattedMessage id="resourcesLoadError" defaultMessage="Could not load resources right now!" />}
        />
      </div>
      <div data-testid="blog" className="dashboard-tile blog-preview-area col-12 col-md-4 order-3 order-md-4">
        <h3 className="section-header"><FormattedMessage id="fromTheBlog" defaultMessage="From the Blog" /></h3>
        {!blog.item ? <EmptyTile type="blog post" container="dashboard" /> :
          <BlogPreviewItem
            data={blog.item}
            handleClick={handlers.handleBlogItemClick}
            loading={blog.loading}
            error={blog.error}
            errorMessage={<FormattedMessage id="blogLoadError" defaultMessage="Could not load blog right now!" />}
          />}
      </div>
      <div data-testid="opportunity" className="dashboard-tile col-12 col-md-4 order-4 order-md-5">
        <h3 className="section-header"><FormattedMessage id="featuredOpportunity" defaultMessage="Featured Opportunity" /></h3>
        <ContainerTile
          {...opportunity.item}
          type="opportunity"
          container="dashboard"
          className="dashboard"
          handleTileClick={handlers.handleOpportunityClick}
          loading={opportunity.loading}
          error={opportunity.error}
          errorMessage={<FormattedMessage id="opportunitiesLoadError" defaultMessage="Could not load opportunities right now!" />}
        />
      </div>
    </Fragment>
  );
};

export default PostPreviewItems;
