import React from 'react';
import { FormattedMessage } from "react-intl";

const FormError = () => {
  return (
    <div className="contact-us-form error">
      <FormattedMessage
        id="genericErrorMessage"
        defaultMessage="Whoops, something went wrong. Try again later!"
      />
    </div>
  );
}

export default FormError;
