import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import CustomDropdown from '../../shared/customDropdown/CustomDropdown';
import { updateInputField } from '../../../redux/actions/ugcForm';
import InputField from './InputField';

const GlobalPolicyField = () => {
  const dispatch = useDispatch();
  const textInputRef = useRef(null);
  const intl = useIntl();
  const messages = defineMessages({
    globalPolicyLabel: { id: 'globalPolicyLabel' },
    monetize: { id: 'monetize' },
    other: { id: 'other' },
    enterTextHere: { id: 'enterTextHere' },
  });

  const policyOption = useSelector(state => state.ugcForm.inputs.policyOption || '');

  useEffect(() => {
    policyOption === 'other' && textInputRef.current.focus();
  }, [policyOption]);

  const handlePolicyChange = (value) => dispatch(updateInputField({ name: 'policyOption', value }));

  const globalPolicyOptions = [
    { label: intl.formatMessage(messages.monetize), value: 'monetize' },
    { label: intl.formatMessage(messages.other), value: 'other' },
  ];

  return (
    <div className="global-policy">
    <CustomDropdown
      items={globalPolicyOptions}
      labelText={intl.formatMessage(messages.globalPolicyLabel)}
      onChange={handlePolicyChange}
      selected={policyOption}
    />
    {
      policyOption === 'other' &&
        <InputField
          ref={textInputRef}
          inputName="policyOther"
          messageIds={{ placeholderId: 'ugcEnterOtherPolicy', errorId: 'enterAPolicyError' }}
        />
    }
  </div>
  );
};

export default GlobalPolicyField;
