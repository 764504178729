const initialState = {
  destinationPage: "/",
  authUrlFetching: false,
  siteLoading: true,
  siteLoadingError: true,
  siteLoadingErrorMessage: null,
  pageLoading: false,
  pageLoadingError: false,
  pageLoadingErrorMessage: null,
};

const ui = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "REMOVE_SITE_LOADER":
      return {
        ...state,
        siteLoading: false,
      };

    case "GET_AUTH_URL_FETCHING":
      return {
        ...state,
        authUrlFetching: true,
      };

    case "USER_LOGIN_SUCCESS":
      return {
        ...state,
        siteLoading: false,
        authUrlFetching: false,
        destinationPage: payload.destinationPage || "/",
      };

    case "USER_LOGIN_ERROR":
      return {
        ...state,
        authUrlFetching: false,
      };

    case "USER_LOGOUT_SUCCESS":
      return { ...state, siteLoading: false };

    case "PAGE_LOAD_FETCHING":
      return {
        ...state,
        pageLoading: true,
        pageLoadingError: false,
      };

    case "PAGE_LOAD_SUCCESS":
      return {
        ...state,
        pageLoading: false,
        pageLoadingError: false,
      };

    default:
      return state;
  }
};

export default ui;
