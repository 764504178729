import React, { Component } from 'react';
import ReactDom from 'react-dom';

class TooltipContent extends Component {
  constructor(props) {
    super(props);
    this.toolTipRef = React.createRef();
    this.timeout = null;

    this.state = {
      distance: 15,//distance tooltip is from the trigger
      style: {},
      placement: 'top',
      arrowPosition: 0,
    }
  }

  componentDidMount() {
    this.props.distance && this.setState({ distance: this.props.distance });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isOpen !== prevProps.isOpen) {

      if (this.props.isOpen) {
        const desiredPosition = this.props.placement || 'top';
        const positionObj = this.positionContent();
        const adjacentPositions = {
          top: 'bottom',
          bottom: 'top',
          left: 'right',
          right: 'left',
        };

        // primary position check can trigger a direct adjacent change like "top" to "bottom"
        const placement = positionObj.isOffScreen[desiredPosition] ? adjacentPositions[desiredPosition] : desiredPosition;
        // secondary position check, chan change "top" to "top-left"
        const position = this.checkHorizontalPosition(placement, positionObj);

        this.setState({
          placement: position.finalPlacement,
          style: {
            ...prevState.style,
            ...position.position,
          },
        });
      }
    }
  }

  // primary placement check.
  // i.e. if "to"p is desired but the top off tooltip will fall off screen, change to "bottom"
  checkPlacement(positions) {
    const { triggerPosition } = this.props;
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const content = this.toolTipRef.current;
    const contentWidth = content.offsetWidth;
    const contentHeight = content.scrollHeight;

    return {
      top: positions.top.top <= scrollTop,
      bottom: (triggerPosition.bottom + contentHeight + this.state.distance) > window.innerHeight,
      right: (triggerPosition.right + contentWidth + this.state.distance) > window.innerWidth,
      left: positions.left.left <= scrollLeft,
    };
  }

  // secondary placement check
  // i.e. if "top" is desired but one side of the tooltip falls offscreen, change to something like "top-left" to accomodate
  // todo: account for "right / left" placements falling off screen vertically.  A less likely scenario in our use case
  checkHorizontalPosition(placement, positionObj) {
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const content = this.toolTipRef.current;
    const contentWidth = content.offsetWidth;
    const desiredPosition = positionObj.positions[placement];

    const offScreenLeft = desiredPosition.left < scrollLeft;
    const offScreenRight = desiredPosition.left + contentWidth > window.innerWidth + scrollLeft;

    const placementsToCheck = ['top', 'bottom', 'top-left', 'top-right', 'bottom-left', 'bottom-right'];

    if (placementsToCheck.filter(p => p === placement).length) {
      if (offScreenLeft && offScreenRight) {
        //noting we can do here so render as desired
        return {
          finalPlacement: placement,
          position: positionObj.positions[placement],
        };
      }

      if (offScreenRight) {
        return {
          finalPlacement: `${placement.replace(/(?:-left|-right)/, '')}-left`,
          position: positionObj.positions[`${placement.replace(/(?:-left|-right)/, '')}-left`],
        };
      }

      if (offScreenLeft){
        return {
          finalPlacement: `${placement.replace(/(?:-left|-right)/, '')}-right`,
          position: positionObj.positions[`${placement.replace(/(?:-left|-right)/, '')}-right`],
        };
      }
    }

    return {
      finalPlacement: placement,
      position: positionObj.positions[placement],
    };
  }

  // Calculate all of the positioning values of the tooltip
  positionContent() {
    const { triggerPosition } = this.props;
    const content = this.toolTipRef.current;
    const contentWidth = content.offsetWidth;
    const contentHeight = content.offsetHeight;
    const arrowPositioningDistance = 40; // used for when the arrow shouldn't be centered

    const positions = {
      top: {
        top: triggerPosition.top - (contentHeight + this.state.distance),
        left: triggerPosition.left + (triggerPosition.width / 2) - (contentWidth / 2),
      },
      'top-left': {
        top: triggerPosition.top - (contentHeight + this.state.distance),
        left: triggerPosition.left - (contentWidth - (triggerPosition.width / 2)) + arrowPositioningDistance,
      },
      'top-right': {
        top: triggerPosition.top - (contentHeight + this.state.distance),
        left: triggerPosition.left + (triggerPosition.width / 2) - arrowPositioningDistance,
      },
      bottom: {
        top: triggerPosition.top + triggerPosition.height + this.state.distance,
        left: triggerPosition.left + (triggerPosition.width / 2) - (contentWidth / 2),
      },
      'bottom-left': {
        top: triggerPosition.top + triggerPosition.height + this.state.distance,
        left: triggerPosition.left - (contentWidth - (triggerPosition.width / 2)) + arrowPositioningDistance,
      },
      'bottom-right': {
        top: triggerPosition.top + triggerPosition.height + this.state.distance,
        left: triggerPosition.left + (triggerPosition.width / 2) - arrowPositioningDistance,
      },
      left: {
        top: triggerPosition.top + (triggerPosition.height / 2) - (contentHeight / 2),
        left: triggerPosition.left - contentWidth - this.state.distance,
      },
      right: {
        top: triggerPosition.top + (triggerPosition.height / 2) - (contentHeight / 2),
        left: triggerPosition.left + (triggerPosition.width) + this.state.distance,
      },
    };

    const isOffScreen = this.checkPlacement(positions);

    return { positions, isOffScreen };
  }

  render() {
    const { isOpen, content, hidden, className } = this.props;
    const { placement, style } = this.state;
    const additionalClasses = className ? ` ${className}` : '';

    if (isOpen) {
      return ReactDom.createPortal(
        <div className={`s71-tooltip${hidden ? ' hidden' : ''}${additionalClasses} ${placement}`} ref={this.toolTipRef} style={style}>
          <div className={`arrow-container ${placement}`} data-testid="arrow-container"><div /></div>
          <div className="content">
            {content}
          </div>
        </div>,
        document.body
      );
    }
    return null;
  }
};

export default TooltipContent;
