import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TooltipContent from './TooltipContent';

/**
 * Tooltip component
 * @prop content [string, jsx, component] required - the tooltip content, can be a string, jsx, or a component,
 * @prop margin [string, int] - the distance from the trigger element, type: string or int - default 15
 * @prop placement [string] options: [top, bottom, right, left, top-left, top-right, bottom-left, bottom-right],  default: top
 * @prop showOnClick [bool]
 * @prop delay [bool] time in milliseconds until the tooltip is displayed for hover over event listener, default delay is 800
 * @prop className [string]
 * @prop disable [bool] disable the tooltip and only render the wrapped trigger element
 */

class Tooltip extends Component {
  constructor(props) {
    super(props);

    this.state = {
      triggerPosition: {
        width: 0,
        height: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      isOpen: false,
      hidden: false,
    };

    this.showTimeout = null;
    this.hideTimeout = null;

    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.removeTooltip = this.removeTooltip.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentWillUnmount() {
    clearTimeout(this.showTimeout);
    clearTimeout(this.hideTimeout);
  }

  updateState(positions) {
    const state = this.state;

    this.setState({
      isOpen: true,
      hidden: false,
      triggerPosition: {
        ...state.triggerPosition,
        ...positions
      },
    });
  }

  showTooltip(el) {
    const delay = this.props.delay || 400;
    // Get the dimensions of the trigger element and its position in the window
    // relative to the windows scroll position
    const state = this.state;
    const boundingClientRect = el.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const positions = Object.keys(state.triggerPosition).reduce((positionObj, position) => {
      if (position === 'top') {
        positionObj[position] = boundingClientRect?.[position] + scrollTop;
      } else if (position === 'left') {
        positionObj[position] = boundingClientRect?.[position] + scrollLeft;
      } else {
        positionObj[position] = boundingClientRect?.[position];
      }

      return positionObj;
    }, {});

    // Prevent pointless renders by delaying the set state when listening to hover event
    if (this.props.showOnClick) {
      this.updateState(positions);
    } else {
      this.showTimeout = setTimeout(() => {
        this.updateState(positions);
      }, delay);
    }
  }

  removeTooltip() {
    clearTimeout(this.showTimeout);

    // delay 200ms to allow css time to fade the tooltip out.  css animation duration is 100ms
    if (!this.state.hidden || this.state.isOpen) {
      this.setState({
        hidden: true,
      }, () => {
        this.hideTimeout = setTimeout(() => {
          this.setState({ isOpen: false });
        }, 200);
      });
    }
  };

  handleMouseOver({ target }) {
    !this.props.showOnClick && this.showTooltip(target);
  };

  handleMouseOut() {
    !this.props.showOnClick && this.removeTooltip();
  }

  handleClick({ target }) {
    const originalClickHandler = this.props.children.props?.onClick;

    if (this.props.showOnClick) {
      this.state.isOpen ? this.removeTooltip() : this.showTooltip(target);
    } else if (originalClickHandler && typeof originalClickHandler === 'function') {
      this.setState({isOpen: false, hidden: true});
      originalClickHandler();
    }
  };

  render() {
    const { isOpen, triggerPosition, hidden } = this.state;
    const { children, content, placement, margin, className, disable } = this.props;

    //No tooltip for mobile currently so just return the trigger element
    if (disable) {
      return children;
    };

    const triggerEl = React.cloneElement(
      children,
      {
        key: 'trigger',
        onClick: this.handleClick,
        onMouseOver: this.handleMouseOver,
        onMouseOut: this.handleMouseOut,
      }
    );

    const contentEl = <TooltipContent
      key="content"
      className={className}
      isOpen={isOpen}
      hidden={hidden}
      content={content}
      triggerPosition={triggerPosition}
      placement={placement}
      distance={margin}
    />;

    return [triggerEl, contentEl];
  }
};

Tooltip.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element,]).isRequired,
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placement: PropTypes.string,
  showOnClick: PropTypes.bool,
  delay: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  className: PropTypes.string,
};

export default Tooltip;
