import React from "react";

const BarsLoader = () => {
  // svg from loading.io
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="221px"
      height="221px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <rect x="17.5" y="29" width="15" height="42" fill="#ee436d">
        <animate
          attributeName="y"
          repeatCount="indefinite"
          dur="1.25s"
          calcMode="spline"
          keyTimes="0;0.5;1"
          values="14.299999999999997;29;29"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.25s"
        ></animate>
        <animate
          attributeName="height"
          repeatCount="indefinite"
          dur="1.25s"
          calcMode="spline"
          keyTimes="0;0.5;1"
          values="71.4;42;42"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.25s"
        ></animate>
      </rect>
      <rect x="42.5" y="29" width="15" height="42" fill="#30a7ff">
        <animate
          attributeName="y"
          repeatCount="indefinite"
          dur="1.25s"
          calcMode="spline"
          keyTimes="0;0.5;1"
          values="17.974999999999994;29;29"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.125s"
        ></animate>
        <animate
          attributeName="height"
          repeatCount="indefinite"
          dur="1.25s"
          calcMode="spline"
          keyTimes="0;0.5;1"
          values="64.05000000000001;42;42"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.125s"
        ></animate>
      </rect>
      <rect x="67.5" y="28.9569" width="15" height="42.0862" fill="#7ef1b5">
        <animate
          attributeName="y"
          repeatCount="indefinite"
          dur="1.25s"
          calcMode="spline"
          keyTimes="0;0.5;1"
          values="17.974999999999994;29;29"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
        ></animate>
        <animate
          attributeName="height"
          repeatCount="indefinite"
          dur="1.25s"
          calcMode="spline"
          keyTimes="0;0.5;1"
          values="64.05000000000001;42;42"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
        ></animate>
      </rect>
    </svg>
  );
};

export default BarsLoader;
