import React from 'react';
import { FormattedNumber } from "react-intl";

const PlaylistRows = (props) => {
  const watch_time = props?.data?.playlists?.watch_time || [];

  return watch_time.map(item => {
    return (
      <ul className="report-row" key={item.id}>
        <li>{item.title}</li>
        <li>{<FormattedNumber value={item.watch_time} />}</li>
      </ul>
    );
  })
};

export default PlaylistRows;
