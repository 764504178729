import React from 'react';
import PageNotFoundContainer from '../pageNotFound/PageNotFoundContainer';
import ErrorPage from '../shared/ErrorPage';
import { navigateBack } from '../../redux/actions/ui';
import { useDispatch } from 'react-redux';

const LoadedPageContent = props => {
  const dispatch = useDispatch();
  const { error, errorMessage, pageContent, loading, pageNotFound, transparent } = props;

  const handleButtonClick = () => {
    dispatch(navigateBack());
  };

  /**
   * If the page is finished loading render either the wrapped content or an error message
   * otherwise don't render anything
   */
  if (transparent || !loading) {
    if (pageNotFound) {
      return <PageNotFoundContainer />;
    } else if (error) {
      return (
        <ErrorPage message={errorMessage} handleButtonClick={handleButtonClick} />
      );
    }
    else {
      // pageContent is either the children JSX or a render prop function
      return (typeof pageContent === 'function') ? pageContent() : pageContent;
    }
  } else {
    return null;
  }
};

export default LoadedPageContent;
