import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { updateInputField, submitForm, resetForm } from '../../../redux/actions/ugcForm';
import PlatformField from './PlatformField';
import InputField from './InputField';
import GlobalPolicyField from './GlobalPolicyField';
import TimeStampField from './TimeStampField';
import SuccessMessage from './SuccessMessage';
import PageLoader from '../../loader/PageLoader';

const UgcFormContent = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const messages = defineMessages({
    ugcAdditionalNotesLabel: { id: 'ugcAdditionalNotesLabel' },
    ugcSubmitError: { id: 'ugcSubmitError' },
    submit: { id: 'submit' },
  });

  const additionalNotes = useSelector(state => state.ugcForm.inputs.additionalNotes || '');
  const formIsValid = useSelector(state => state.ugcForm.formIsValid);
  const sending = useSelector(state => state.ugcForm.sending);
  const sendError = useSelector(state => state.ugcForm.sendError);
  const sendSuccess = useSelector(state => state.ugcForm.sendSuccess);

  useEffect(() => {
    dispatch(resetForm());
  }, [dispatch]);

  const handleInputChange = ({ target }) => {
    const { name, value } = target;
    dispatch(updateInputField({ name, value }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(submitForm());
  };

  return (
    <form className="opaque-container" onSubmit={handleSubmit}>
      <PageLoader loading={sending} transparent render={() => (
        <Fragment>
          <SuccessMessage sendSuccess={sendSuccess} />
          <div className="form-content">
            <PlatformField />
            <InputField
              inputName="copyrightLink"
              messageIds={{ labelId: 'ugcCopyrightLink', placeholderId: 'ugcCopyrightPlaceholder', errorId: 'urlValidationError' }}
            />
            <InputField
              inputName="infringingLink"
              messageIds={{ labelId: 'ugcInfringingLink', placeholderId: 'ugcInfringingPlaceholder', errorId: 'urlValidationError' }}
            />
            <TimeStampField />
            <GlobalPolicyField />
            <label>
              {intl.formatMessage(messages.ugcAdditionalNotesLabel)}
              <textarea name="additionalNotes" onChange={handleInputChange} value={additionalNotes} />
            </label>
            <div className="actions">
              <button disabled={!formIsValid} className={`secondary${!formIsValid ? ' disabled' : ''}`}>
                {intl.formatMessage(messages.submit)}
              </button>
            </div>
            {sendError && <em className="error-message">{intl.formatMessage(messages.ugcSubmitError)}</em>}
          </div>
        </Fragment>
      )} />
    </form>
  );
};

export default UgcFormContent;
