import React from "react";
import PropTypes from "prop-types";
import FeaturedImage from "./FeaturedImage";

const PostDetailContainer = (props) => {
  const { featuredImage, header, subheader, content, className } = props;
  const hasImageClass = featuredImage ? " has-image" : "";
  const additionalClass = className ? ` ${className}` : "";
  return (
    <article
      className={`post-detail-container opaque-container${hasImageClass}${additionalClass}`}
    >
      {featuredImage && <FeaturedImage imageUrl={featuredImage} />}
      <div className="post-content-wrapper">
        <h3>
          {header}
          {subheader && <span className="subheader">{subheader}</span>}
        </h3>
        <div className="post-content-body">{content}</div>
      </div>
    </article>
  );
};

export default PostDetailContainer;

PostDetailContainer.propTypes = {
  header: PropTypes.string, //Post header, renders as a h3 element,
  subheader: PropTypes.string, // Post subheader, is lightened and appended to the h3 by default
  featuredImage: PropTypes.string, // Featured image for the post
  className: PropTypes.string, // Any additional class names wished to be added,
  content: PropTypes.oneOfType([
    // Post content, if from wordpress needs to be decded and dangersouly set into a '<div>' tag
    PropTypes.string,
    PropTypes.element,
  ]),
};
