const initialState = {
  contactUsModalOpen: false,
  error: false,
  success: false,
  subject: "",
  message: "",
  openedFrom: "",
  showMessageError: false,
};

const contactUs = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "OPEN_CONTACT_US_MODAL":
      return {
        ...state,
        contactUsModalOpen: true,
        success: false,
        error: false,
        openedFrom: payload.openedFrom,
      };

    case "RESET_CONTACT_US_FORM":
    case "CLOSE_CONTACT_US_MODAL":
      return {
        ...initialState,
      };

    case "UPDATE_CONTACT_US_SUBJECT":
      return {
        ...state,
        subject: payload.value,
      };

    case "UPDATE_CONTACT_US_MESSAGE":
      return {
        ...state,
        message: payload.value,
      };

    case "SHOW_MISSING_MESSAGE_ERROR":
      return {
        ...state,
        showMessageError: true,
      };

    case "SEND_CONTACT_US_EMAIL_SUCCESS":
      return {
        ...state,
        subject: "",
        message: "",
        success: true,
      };

    case "SEND_CONTACT_US_EMAIL_ERROR":
      return {
        ...state,
        subject: "",
        message: "",
        error: true,
      };

    default:
      return state;
  }
};

export default contactUs;
