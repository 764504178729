import api from "./api";
import { defaultedChannelReport } from "../../components/reportsPage/consts/channelReportBase";
import { gaSelectChannelEvent } from "./ga";

// TODO: What is this, is this <ReportsPageContainer /> used at all?
export const getReportData = (channelId) => {
  return (dispatch, getState) => {
    dispatch({ type: "GET_REPORT_DATA_FETCHING", payload: { channelId } });
    const { selectedChannel } = getState().report;
    return api
      .get("/api/report", { params: { media_feed_id: channelId } })
      .then(({ data }) => {
        return dispatch({
          type: "GET_REPORT_DATA_SUCCESS",
          payload: {
            data: defaultedChannelReport(data),
            selectedChannel,
          },
        });
      })
      .catch((err) => {
        dispatch({ type: "GET_REPORT_DATA_ERROR", payload: err });
      });
  };
};

export const updateReportData = (channelId, targetChannel) => {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_REPORT_DATA_FETCHING",
      payload: { channelId, targetChannel },
    });
    return api
      .get("/api/report", { params: { media_feed_id: channelId } })
      .then(({ data = [] }) => {
        return dispatch({
          type: "UPDATE_REPORT_DATA_SUCCESS",
          payload: {
            selectedChannel: targetChannel,
            data: defaultedChannelReport(data),
          },
        });
      })
      .catch((err) => {
        dispatch({ type: "UPDATE_REPORT_DATA_ERROR", payload: err });
      });
  };
};

export const selectChannel = (target) => {
  return (dispatch, getState) => {
    const {
      report: { channels = [], selectedChannel = {} },
    } = getState();

    const targetChannel = channels.find((channel) => channel.title === target);

    dispatch(
      gaSelectChannelEvent({
        page: "reports",
        channel: `${targetChannel.title}`,
      }),
    );

    if (targetChannel.title === selectedChannel.title) {
      return;
    } else {
      dispatch({
        type: "_CHANNEL_SELECTED",
        payload: { targetChannel },
      });
      dispatch(updateReportData(targetChannel.feed_id, targetChannel));
    }
  };
};
