const initialState = {
  loading: true,
  error: false,
  errorMessage: null,
  overviewItems: [
    {
      title: "Account Settings",
      titleIntlKey: "accountSettings",
      path: "/settings/account",
      description:
        "Edit your account’s email address, password, change language preferences.",
      descriptionIntlKey: "accountSettingsDescription",
      icon: "fas fa-cog",
    },
    {
      title: "Channels",
      titleIntlKey: "channels",
      path: "/settings/channels",
      description:
        "View and edit all of your channels - social accounts, podcasts and more.",
      descriptionIntlKey: "channelsSettingsDescription",
      icon: "fa-sharp fa-solid fa-grid-2",
    },
    {
      title: "Surveys",
      titleIntlKey: "surveys",
      path: "/settings/surveys",
      description:
        "We want to learn more about you! Click here to fill out some questions that will help us find the best opportunities for you!",
      descriptionIntlKey: "surveysSettingsDescription",
      icon: "fas fa-clipboard-list",
    },
    {
      title: "Payment Settings",
      titleIntlKey: "paymentSettings",
      path: "/settings/payment",
      description:
        "Review your bank and payment setting. This is a sample text. Wil require an update.",
      descriptionIntlKey: "paymentSettingsDescription",
      icon: "fas fa-landmark",
      betaFlag: true,
      betaFlagTooltipIntlKey: "paymentSettingsBetaTooltip",
    },
    // {
    //   title: "Creator Profile(s)",
    //   titleIntlKey: "creatorProfiles",
    //   path: "/settings/profiles",
    //   description:
    //     "Edit your creator profile and create additional profiles for each creator on your account. This profile data helps our Sales Team pitch you for deals!",
    //   descriptionIntlKey: "creatorProfilesSettingsDescription",
    //   icon: "fas fa-user-plus",
    //   disabled: true,
    // },
    // {
    //   title: "Notifications",
    //   titleIntlKey: "notifications",
    //   path: "/settings/notifications",
    //   description: "Edit your notification preferences.",
    //   descriptionIntlKey: "notificationsSettingsDescription",
    //   icon: "far fa-bell",
    //   disabled: true,
    // },
  ],
};

const settings = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case "INITIALIZE_CHANNEL_SETTINGS_PAGE":
      return initialState;

    default:
      return state;
  }
};

export default settings;
