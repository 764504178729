import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { logout } from '../../redux/actions/user';

const UserGroupAuthError = () => {
  const dispatch = useDispatch();
  const noAppPermissionMessage = <FormattedMessage id="noAppPermissionMessage" />;
  const noAppPermissionTitle = <FormattedMessage id="noAppPermissionTitle" />;

  const handleLogout = () => dispatch(logout());

  return (
    <div className="error-page permissions-error">
      <div className="overlay"/>
      <h4>{noAppPermissionTitle}</h4>
      <p>{noAppPermissionMessage}</p>
      <button className="primary" onClick={handleLogout}><FormattedMessage id="logout"/></button>
    </div>
  );
};

export default UserGroupAuthError;
