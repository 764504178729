import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { goToOpportunitiesContainer } from "../../../redux/actions/opportunities";
import LinkBuilderArea from "./LinkBuilderArea";
import NotSetUpMessage from "./NotSetUpMessage";
import Instructions from "./Instructions";
import amazonInstructions from "../../../assets/amazonLinkBulderInstructions.pdf";
import { FormattedMessage } from "react-intl";
import { gaClickthroughEvent } from "../../../redux/actions/ga";
import PageHeader from "../../shared/PageHeader";

const AmazonLinkBuilder = () => {
  const dispatch = useDispatch();
  const affiliateCodeList = useSelector(
    (state) => state.user.affiliateCodeList || [],
    shallowEqual,
  );

  const onClick = () => {
    dispatch(goToOpportunitiesContainer());
  };

  const moreInfoClickHereGa = () => {
    dispatch(
      gaClickthroughEvent({
        page: "opportunities_amazon",
        itemName: "more_info_click_here",
      }),
    );
  };

  return (
    <div className="amazon-link-builder-container">
      <button className="button pill-button primary" onClick={onClick}>
        <FormattedMessage
          id="allOpportunities"
          defaultValue="All Opportunities"
        />
      </button>
      <PageHeader headerId="amazonAffiliateLinkBuilder" />
      <div className="link-builder-and-instructions">
        {affiliateCodeList.length === 0 ? (
          <NotSetUpMessage />
        ) : (
          <LinkBuilderArea />
        )}
        <Instructions />
      </div>
      <div className="amazon-link-builder-fineprint">
        <FormattedMessage id="forMoreInfo" />
        <a
          onClick={moreInfoClickHereGa}
          href={amazonInstructions}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="clickHere" />
        </a>
        <FormattedMessage id="pleaseNoteThat" />
      </div>
    </div>
  );
};

export default AmazonLinkBuilder;
