import React from 'react';

const CreatorStudioLogo = () => {
  return (
    <svg className="creator-studio-logo" x="0px" y="0px" viewBox="0 0 4063.32 325.91">
      <path d="M3978.67,135.57l63.4-88.76v238.37c0,5.84-4.73,10.58-10.57,10.58h-42.27c-5.83,0-10.57-4.74-10.57-10.58
        V135.57z"/>
      <path d="M3773.66,16.45c-5.84,0-10.57,4.74-10.57,10.58v42.32c0,5.84,4.73,10.58,10.57,10.58h137.85l-147.44,203.33
        c-2.93,4.21-0.93,12.5,6.57,12.5h62.92c4.84,0,9.37-2.37,12.14-6.34l196.38-272.96H3773.66z"/>
      <g>
        <path d="M10.87,156.66c0-86.11,65.21-144.21,148.81-144.21c69.39,0,105.34,38.04,123.73,74.82l-61.86,29.26
          c-9.2-22.15-33.02-40.55-61.86-40.55c-43.47,0-75.66,35.11-75.66,80.67s32.19,80.67,75.66,80.67c28.84,0,52.67-18.39,61.86-40.55
          l61.86,29.26c-17.97,35.95-54.34,74.82-123.73,74.82C76.08,300.87,10.87,242.35,10.87,156.66z"/>
        <path d="M427.19,201.39h-36.78v94.47h-71.9V17.05h139.61c61.86,0,96.98,40.96,96.98,92.8c0,48.49-29.68,74.4-55.59,83.6
          l56.85,102.41h-82.35L427.19,201.39z M447.25,77.66h-56.85v62.7h56.85c18.81,0,34.69-12.12,34.69-31.35S466.06,77.66,447.25,77.66z
          "/>
        <path d="M598.99,17.05h204.4v60.61H670.88v46.4h129.58v61.03H670.88v50.16h132.51v60.61h-204.4V17.05z"/>
        <path d="M1027.85,253.64H912.06l-14.21,42.22h-78.17L924.6,17.05h90.29l105.34,278.81h-78.17L1027.85,253.64z M930.87,193.03h78.17
          L969.75,79.75L930.87,193.03z"/>
        <path d="M1181.25,79.75h-78.17v-62.7h227.81v62.7h-77.75v216.11h-71.9V79.75z"/>
        <path d="M1495.17,12.45c85.27,0,148.81,59.36,148.81,144.21c0,84.85-63.54,144.21-148.81,144.21s-149.23-59.36-149.23-144.21
          C1345.95,71.81,1409.9,12.45,1495.17,12.45z M1495.17,75.99c-46.4,0-76.08,35.11-76.08,80.67c0,45.14,29.68,80.67,76.08,80.67
          c45.98,0,75.66-35.53,75.66-80.67C1570.83,111.1,1541.15,75.99,1495.17,75.99z"/>
        <path d="M1793.2,201.39h-36.78v94.47h-71.9V17.05h139.61c61.86,0,96.98,40.96,96.98,92.8c0,48.49-29.68,74.4-55.59,83.6
          l56.85,102.41h-82.35L1793.2,201.39z M1813.27,77.66h-56.85v62.7h56.85c18.81,0,34.69-12.12,34.69-31.35
          S1832.08,77.66,1813.27,77.66z"/>
        <path d="M2205.35,202.64c20.06,20.06,51,36.78,89.03,36.78c24.66,0,39.71-10.45,39.71-23.83c0-15.88-17.97-22.15-47.65-28.42
          c-45.98-9.2-110.35-20.9-110.35-86.94c0-46.82,39.71-87.36,111.19-87.36c44.73,0,83.6,13.38,113.28,38.87l-39.29,51.41
          c-23.41-19.23-53.92-28.84-78.58-28.84c-23.83,0-33.02,9.61-33.02,21.74c0,14.63,17.14,19.65,48.07,25.5
          c45.98,9.61,109.52,22.99,109.52,86.11c0,56.01-41.38,93.21-116.2,93.21c-56.43,0-95.72-17.56-123.73-44.31L2205.35,202.64z"/>
        <path d="M2505.47,79.75h-78.17v-62.7h227.81v62.7h-77.75v216.11h-71.9V79.75z"/>
        <path d="M2693.56,17.05h73.15v163.44c0,33.02,17.56,56.85,56.43,56.85c38.46,0,56.01-23.83,56.01-56.85V17.05h73.15v165.53
          c0,69.39-39.71,118.29-129.16,118.29c-89.45,0-129.58-49.32-129.58-117.88V17.05z"/>
        <path d="M3006.64,17.05h117.04c87.78,0,150.9,52.67,150.9,139.19s-63.12,139.61-150.48,139.61h-117.46V17.05z M3123.68,233.16
          c48.91,0,77.33-35.53,77.33-76.91c0-43.05-25.92-76.49-76.91-76.49h-45.56v153.41H3123.68z"/>
        <path d="M3314.7,17.05h71.9v278.81h-71.9V17.05z"/>
        <path d="M3575.95,12.45c85.27,0,148.81,59.36,148.81,144.21c0,84.85-63.54,144.21-148.81,144.21s-149.23-59.36-149.23-144.21
          C3426.72,71.81,3490.68,12.45,3575.95,12.45z M3575.95,75.99c-46.4,0-76.08,35.11-76.08,80.67c0,45.14,29.68,80.67,76.08,80.67
          c45.98,0,75.66-35.53,75.66-80.67C3651.61,111.1,3621.93,75.99,3575.95,75.99z"/>
      </g>
    </svg>
  );
};

export default CreatorStudioLogo;
