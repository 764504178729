import React, { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import getQueryStringParams from "../../../util/getQueryStringParams";
import {
  initializeSurveysPage,
  changeSurveysContainerPage,
} from "../../../redux/actions/settings";
import PageLoader from "../../loader/PageLoader";
import Pagination from "../../shared/pagination/Pagination";
import ContainerTile from "../../shared/containerTile/ContainerTile";
import PageHeader from "../../shared/PageHeader";

const SurveysContainer = ({ location }) => {
  const dispatch = useDispatch();
  const { loading, error, errorMessage, items, pagination } = useSelector(
    (state) => ({
      loading: state.settings.surveys.loading,
      error: state.settings.surveys.error,
      errorMessage: state.settings.surveys.errorMessage,
      items: state.settings.surveys.items || [],
      pagination: state.settings.surveys.pagingation || {},
    }),
    shallowEqual,
  );
  const pageNumber = getQueryStringParams(location?.search).page || 1;

  useEffect(() => {
    dispatch(initializeSurveysPage(pageNumber));
  }, [dispatch, pageNumber]);

  const handleTileClick = (url) => {
    if (url) {
      window.open(url, "_blank");
    }
  };

  const changePage = (page) => {
    dispatch(changeSurveysContainerPage(page));
  };

  return (
    <PageLoader
      loading={loading}
      error={error}
      errorMessage={errorMessage}
      fadeOut={true}
    >
      <section className="surveys-container">
        <PageHeader headerId="surveys" />
        <div className="surveys-list row container-preview-area">
          {items.map((item) => {
            return (
              <ContainerTile
                {...item}
                key={item.ID}
                type="survey"
                container="survey"
                className="survey col-sm-12"
                handleTileClick={handleTileClick}
              />
            );
          })}
        </div>
        <Pagination
          page={pagination.page}
          totalPages={pagination.totalPages}
          handleClick={changePage}
        />
      </section>
    </PageLoader>
  );
};

export default SurveysContainer;
