import React from 'react';
import { connect } from 'react-redux';
import { changeTextInput, showChangePasswordForm } from '../../../redux/actions/settings';
import { useIntl } from 'react-intl';

const messages = {
  currentPassword: {
    id: 'enterCurrentPassword',
    defaultMessage: 'Enter Current Password'
  },
  newPassword: {
    id: 'enterNewPassword',
    defaultMessage: 'Enter New Password'
  },
  newPasswordConfirm: {
    id: 'confirmNewPassword',
    defaultMessage: 'Confirm New Password'
  },
  changePassword: {
    id: 'changePassword',
    defaultMessage: 'Change Password'
  },
  passwordMatchError: {
    id: 'passwordsDontMatch',
    defaultMessage: "Passwords don't match",
  },
};

const UpdatePasswordForm = ({ currentPassword, newPassword, newPasswordConfirm, showPasswordMatchError, updatePasswordFormVisibile, dispatch }) => {
  const intl = useIntl();

  const handleChange = e => {
    dispatch(changeTextInput(e));
  };

  const handleChangePasswordClick = e => {
    e.preventDefault();
    dispatch(showChangePasswordForm());
  };

  const labels = {
    currentPassword: intl.formatMessage(messages.currentPassword),
    newPassword: intl.formatMessage(messages.newPassword),
    newPasswordConfirm: intl.formatMessage(messages.newPasswordConfirm),
  };

   if (updatePasswordFormVisibile) {
    return (
      <div className="update-password-form">
        <label>
          <span>{labels.currentPassword}</span>
          <input
            name="currentPassword"
            type="password"
            onChange={handleChange}
            value={currentPassword}
            placeholder={labels.currentPassword}
          />
        </label>
        <label>
          <span>{labels.newPassword}</span>
          <input
            name="newPassword"
            type="password"
            onChange={handleChange}
            value={newPassword}
            placeholder={labels.newPassword}
          />
        </label>
        <label>
          <span>{labels.newPasswordConfirm}</span>
          <input
            name="newPasswordConfirm"
            type="password"
            onChange={handleChange}
            value={newPasswordConfirm} placeholder={labels.newPasswordConfirm}
          />
          <p>
            <em>
              Password must be 12 characters long, not contain any common words,
              and has not previously been compromised in a data breach.
            </em>
          </p>
          {showPasswordMatchError && <em className="error">{intl.formatMessage(messages.passwordMatchError)}</em>}
        </label>
      </div>
    );
  } else {
     return (
       <button className="link" onClick={handleChangePasswordClick}>
         {intl.formatMessage(messages.changePassword)}
       </button>
     );
  }
};

const mapStateToProps = ({ settings }) => {
  return {
    currentPassword: settings.account.currentPassword,
    newPassword: settings.account.newPassword,
    newPasswordConfirm: settings.account.newPasswordConfirm,
    showPasswordMatchError: settings.account.showPasswordMatchError,
    updatePasswordFormVisibile: settings.account.updatePasswordFormVisibile,
  };
};

export default connect(mapStateToProps)(UpdatePasswordForm);
