import React from 'react';

const FeaturedImage = ({ imageUrl }) => {
  if (!imageUrl) return null;

  return (
    <div className="featured-image-container">
      <div className="featured-image" style={{ backgroundImage: `url(${imageUrl})` }} />
    </div>
  );
};

export default FeaturedImage;
