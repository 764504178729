export const statToValMap = [
  {views: "Views", statType: "number"},
  {watch_time: "Watch Time (mins)", statType: "number"},
  {avg_view_duration: "Avg. View Duration (mins)", statType: "avgTime"},
  {net_subscribers: "Net Subscribers", statType: "number"},
  {watch_page_net_subscribers: "Watch Page Net Subs", statType: "number"},
  {likes: "Likes", statType: "number"},
  {comments: "Comments", statType: "number"},
  {shares: "Shares", statType: "number"},
  {card_teaser_clicks: "Card Teaser Clicks", statType: "number"},
  {card_teaser_ctr: "Card Teaser CTR", statType: "percentage"},
  {card_clicks: "Card Clicks", statType: "number"},
  {card_ctr: "Card CTR", statType: "percentage"},
  {playlist_watch_time: "Playlist WatchTime (min)", statType: "number"},
  {playlist_starts: "Playlist Starts", statType: "number"},
  {avg_time_in_playlist: "Avg Time in Playlist (mins)", statType: "avgTime"}
];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];