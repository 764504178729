import React from 'react';
import CreatorStudioLogo from '../../svg/CreatorStudioLogo';

const CreatorStudioLogoHeader = () => (
  // Maybe we'll need a click handler here or use the svg on its
  // so separated the svg component from the container
  <div className="creator-studio-logo-container">
    <CreatorStudioLogo />
  </div>
);

export default CreatorStudioLogoHeader;
