const initialState = {
  menuItems: ['Dashboard', 'Documents', 'Reports', 'Opportunities', 'Resources'],
  navVisibilityClass: '',
};

const mainNav = (state = initialState, action) => {
  const {type, payload} = action;

  switch(type) {
    case 'USER_LOGIN_SUCCESS':
      return {
        ...state,
        menuItems: payload.navItems,
      };
    case 'TOGGLE_MAIN_NAV':
      return  {
        ...state,
        navVisibilityClass: payload.nextNavVisibilityClass
      };

    default:
      return state;
  };

};

export default mainNav;
