import api from "./api";
import { navigateToPath } from "./ui";

export const showLoader = () => {
  return (dispatch) => {
    dispatch({ type: "GET_DOCUMENTS_FETCHING" });
  };
};

export const getDocumentsList = ({
  page = 1,
  per,
  sortBy,
  sortDirection,
  accountId,
}) => {
  return (dispatch) => {
    // FYI: Document Type is not changeable anywhere in the UI, its not necessary for it to be in the State like this
    // const state = getState();
    // const type = state.documents.documentType || "financial_statement";

    api
      .post("/api/documents/get", {
        page,
        per,
        sortBy,
        sortDirection,
        // type,
        accountId,
      })
      .then((resp) => {
        const data = resp.data || {};
        const paginationData = data.pagination || {};
        const pagination = {
          ...paginationData,
          totalPages: (paginationData.total || 0) / (paginationData.per || 20),
        };
        const payload = {
          items: data.items || [],
          pagination: pagination,
        };

        dispatch({ type: "GET_DOCUMENTS_SUCCESS", payload });
      })
      .catch((err) => {
        dispatch({ type: "GET_DOCUMENTS_ERROR", payload: err });
      });
  };
};

export const changeDocumentsSort =
  ({ page, per, sortBy, sortDirection, accountId = "all" } = {}) =>
  (dispatch) => {
    const sortableOptions = ["account", "date", "type"];
    const sortDirections = ["asc", "desc"];

    const params = {
      ...(page ? { page } : {}),
      ...(per ? { per } : {}),
      ...(accountId !== "all" ? { accountId } : {}),
      sortBy: sortableOptions.indexOf(sortBy) > -1 ? sortBy : "date",
      sortDirection:
        sortDirections.indexOf(sortDirection) > -1 ? sortDirection : "desc",
    };

    const queryString = Object.keys(params)
      .reduce((acc, paramKey) => {
        acc = acc.concat([`${paramKey}=${params[paramKey]}`]);
        return acc;
      }, [])
      .join("&");

    dispatch(
      navigateToPath(
        `/documents${Object.keys(params).length ? `?${queryString}` : ""}`,
      ),
    );
  };
