import React from 'react';
import { connect } from 'react-redux';
import {
  createMultiShortLink,
  resetLinkBuilder,
} from '../../../redux/actions/linkBuilder';
import MultiItemInput from './MultiItemInput';
import ShortLinkDisplay from './ShortLinkDisplay';
import PageLoader from '../../loader/PageLoader';
import ShortLinkError from './ShortLinkError';
import { FormattedMessage } from 'react-intl';

const MultiLinkBuilder = ({ dispatch, multiText, multiIsLoading, multiTextValid, multiShortLink, multiShortLinkError, multiCreateButtonText }) => {
  const textValidationError = (multiTextValid === false);

  const onClick = () => {
    if (multiShortLinkError) {
      dispatch(resetLinkBuilder("multi"));
    } else if (multiShortLink) {
      dispatch(resetLinkBuilder("multi"));
    } else if (multiTextValid) {
      dispatch(createMultiShortLink());
    }
  };

  let linkInputOrError = <MultiItemInput />;

  if (multiShortLinkError) {
    linkInputOrError = <ShortLinkError singleMulti={"multi"} />;
  } else if (multiShortLink !== null) {
    linkInputOrError = <ShortLinkDisplay singleMulti={"multi"} />;
  }

  return (
    <PageLoader loading={multiIsLoading}>
      <div className="multi-item-link-builder">
        <h3><FormattedMessage id="multiItemLinkBuilder" /></h3>
        <p><FormattedMessage id="pasteMultipleLinks" /></p>
        {linkInputOrError}
        <button onClick={onClick}
            className={`secondary ${textValidationError || multiText === "" ? "disabled" : ""}`}>
          <FormattedMessage id={multiCreateButtonText} />
        </button>
      </div>
    </PageLoader>
  );
};

const mapStateToProps = ({amazonLinkBuilder}) => {
  return {
    multiText: amazonLinkBuilder.multiText,
    multiIsLoading: amazonLinkBuilder.multiTextIsLoading,
    multiTextValid: amazonLinkBuilder.multiTextValid,
    multiShortLink: amazonLinkBuilder.multiShortLink,
    multiShortLinkError: amazonLinkBuilder.multiShortLinkError,
    multiCreateButtonText: amazonLinkBuilder.multiCreateButtonText,
  };
};

export default connect(mapStateToProps)(MultiLinkBuilder);
