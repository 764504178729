import React from 'react';



function reportTable({title = "", headers = [], rows = [], width = "half", cols = ""}) {
  return (
    <div className={`${width === "half" && "col-12 col-sm-6"} ${cols}-col`}>
      <div className="opaque-container offset-header">
        <h3 className={`${width}-title gradient-header`}>
          {title}
          {width === 'full' && <i className='fa fa-chart-line'/>}
        </h3>
        <ul className="report-header-row">{headers}</ul>
        {rows}
      </div>
    </div>
  );
}

export default reportTable;