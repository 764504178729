import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import Modal from '../../modal/Modal';
import { closeRemovePhotoModal, deletePhoto } from '../../../redux/actions/settings';

const DeletePhotoConfirmation = props => {
  const dispatch = useDispatch();
  const closeModal = () => dispatch(closeRemovePhotoModal());
  const confirm = () => dispatch(deletePhoto());

  return (
    <Modal className="remove-photo-modal"
      opened={props.opened}
      closeModal={closeModal}
      header={<FormattedMessage id="removePhoto" defaultMessage="Remove Photo" />}
    >
      <p><FormattedMessage id="removePhotoConfirmation" defaultMessage="Are you sure you want to remove this photo?" /></p>
      <div className="actions">
        <button onClick={confirm} className="secondary"><FormattedMessage id="confirm" defaultMessage="Confirm" /></button>
        <button onClick={closeModal}><FormattedMessage id="cancel" defaultMessage="Cancel" /></button>
      </div>
    </Modal>
  )
};

export default DeletePhotoConfirmation;
