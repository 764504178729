import React from "react";
import { FormattedNumber } from "react-intl";

const SubsRows = (props) => {
  const subscribers = props?.data?.videos?.subscribers || [];

  return subscribers.map(item => {
    return (
      <ul className="report-row" key={item.id}>
        <li>{item.title}</li>
        <li>{<FormattedNumber value={item.subscribers ?? 0} />}</li>
      </ul>
    );
  });
};

export default SubsRows;
