import React, { Fragment } from "react";

import { secToMin, toPercent, abbreviateBigNumber } from "../../../util/helpers";
import { months } from '../consts/consts';
import BREAKPOINTS from '../../../constants/breakpoints';

import { FormattedMessage, FormattedNumber } from "react-intl";
import ReportTable from "./reportTable.js";
import ChannelPerformanceHeaders from './ChannelPerformanceHeaders';
import ChannelPerformanceRows from "./ChannelPerformanceRows";
import ThreeColHeader from "./ThreeColHeader";
import PlaylistHeader from './PlaylistHeader';
import PlaylistRows from './PlaylistRows';
import TwoColHeader from './TwoColHeader';
import OverlapHeader from './OverlapHeader';
import CardRows from "./CardRows";
import CommentRows from './CommentRows';
import OverlapRows from './OverlapRows';
import SubsRows from './SubsRows';

class ReportPage extends React.Component {
  // applies different formatting functions depending on statType property
  channelValueFormat = (num, statType) => {
    if (statType === "number") {
      // on small screens, round the numbers
      if (window.innerWidth < BREAKPOINTS.TABLET) {
        return <Fragment>{abbreviateBigNumber(num, this.props.locale)}</Fragment>;
      }
      return <FormattedNumber value={num} />;
    }
    if (statType === "avgTime") {
      return secToMin(num);
    }
    if (statType === "percentage") {
      return toPercent(num);
    }
    return '--';
  }

  render() {
    const { data = [], data: [ , , month2 = {} ] = [] } = this.props;
    const { month = "" } = month2;
    const [year = "", finalMonth = ""] = month.split("-");
    const finalMonthName = months[Number(finalMonth) - 1] || "";
    if (!data || (data && !data.length)) {
      return (
        <div className="monthly-report-error">
          This channel does not currently have any reports. Please check
          back at a later time.
        </div>
      );
    }

    return (
      <div>
        {/* three month comparison table */}
        <ReportTable
          title={<FormattedMessage id="channelPerformance" />}
          headers={<ChannelPerformanceHeaders data={data} />}
          rows={
            <ChannelPerformanceRows
              channelValueFormat={this.channelValueFormat}
              data={data}
            />
          }
          width="full"
          cols="four"
        />

        {/* single month report */}
        <div className="col-sm-12 monthly-report-header gradient-header">
          <FormattedMessage
            id="monthlyReportHeader"
            values={{monthAndYear: `${finalMonthName} ${year} `}}
          />
          <FormattedMessage id="monthlyReport" />
        </div>

        <div className="row">
          <ReportTable
            title={<FormattedMessage id="topCards" />}
            cols="three"
            headers={<ThreeColHeader />}
            rows={<CardRows data={month2} />}
          />
          <ReportTable
            title={<FormattedMessage id="topPlaylists" />}
            cols="two"
            headers={<PlaylistHeader />}
            rows={<PlaylistRows data={month2} />}
          />
          <ReportTable
            title={<FormattedMessage id="videosDrivingComments" />}
            cols="two"
            headers={<TwoColHeader />}
            rows={<CommentRows data={month2} />}
          />
          <ReportTable
            title={<FormattedMessage id="videosDrivingSubscribers" />}
            cols="two"
            headers={<TwoColHeader />}
            rows={<SubsRows data={month2} />}
          />
          <ReportTable
            title={<FormattedMessage id="topSubscriberOverlap" />}
            cols="two"
            headers={<OverlapHeader />}
            rows={<OverlapRows data={month2} />}
          />
        </div>
      </div>
    );
  }
}

export default ReportPage;
