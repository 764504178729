import React from 'react';
import { FormattedMessage } from "react-intl";

const TwoColHeader = () => {
  return [
    <li key="title">
      <FormattedMessage id="title" />
    </li>,
    <li key="number">
      <FormattedMessage id="number" />
    </li>
  ];
}

export default TwoColHeader;
