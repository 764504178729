import React, { Component } from "react";
import ChannelsIcon from "../../svg/ChannelsIcon";
import Tooltip from "../shared/tooltip/Tooltip";
import { FormattedMessage } from "react-intl";

class SettingsItem extends Component {
  constructor(props) {
    super(props);
    this.handleItemClick = this.handleItemClick.bind(this);
  }

  handleItemClick() {
    this.props.handleClick(this.props.data);
  }

  render() {
    const { data, betaFlag = false, betaFlagTooltipText = "" } = this.props;
    const disabledClass = data?.disabled ? "disabled" : "";
    const titleIntlKey = data?.titleIntlKey || "noIntlId";
    const descriptionIntlKey = data?.descriptionIntlKey || "noIntlId";

    return (
      <div className="col-sm-12 col-lg-6">
        <div
          className={`settings-item opaque-container offset-header ${disabledClass}`}
          onClick={this.handleItemClick}
        >
          <div className="icon">
            {data.title === "Channels" ? (
              <ChannelsIcon />
            ) : (
              <i className={data?.icon} />
            )}
          </div>
          <h3>
            <FormattedMessage
              id={titleIntlKey}
              defaultMessage={data?.description}
            />
            {betaFlag ? (
              <Tooltip
                className="nav-tooltip"
                content={betaFlagTooltipText}
                placement="right"
                disable={!betaFlagTooltipText.length}
              >
                <span className="beta-flag">beta</span>
              </Tooltip>
            ) : null}
          </h3>
          <p>
            <FormattedMessage
              id={descriptionIntlKey}
              defaultMessage={data?.description}
            />
          </p>
        </div>
      </div>
    );
  }
}

export default SettingsItem;
