const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  currentPassword: "",
  newPassword: "",
  newPasswordConfirm: "",
  updatePasswordFormVisibile: false,
  loading: false,
  error: false,
  errorMessage: null,
  showSuccessMessage: false,
  showPasswordMatchError: false,
  enableSaveButton: false,
  photoUploadLoading: false,
  photoUploadSuccess: false,
  photoUploadError: false,
  removePhotoModalOpened: false,
};

const account = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "USER_LOGIN_SUCCESS":
      return {
        ...state,
        firstName: payload.first_name,
        lastName: payload.last_name,
        email: payload.email,
      };

    case "CANCEL_ACCOUNT_SETTINGS_CHANGE":
      return {
        ...state,
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        showPasswordMatchError: false,
        showSuccessMessage: false,
        errorMessage: null,
        updatePasswordFormVisibile: false,
        enableSaveButton: false,
        currentPassword: "",
        newPassword: "",
        newPasswordConfirm: "",
      };

    case "ACCOUNT_SETTINGS_CHANGE_TEXT_INPUT":
      return {
        ...state,
        [payload.name]: payload.value,
        showSuccessMessage: false,
        errorMessage: null,
      };

    case "SHOW_CHANGE_PASSWORD_FORM":
      return {
        ...state,
        updatePasswordFormVisibile: true,
      };

    case "SAVE_ACCOUNT_SETTINGS_FETCHING":
      return {
        ...state,
        loading: true,
        error: false,
      };

    case "SAVE_ACCOUNT_SETTINGS_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        showSuccessMessage: true,
        currentPassword: "",
        newPassword: "",
        newPasswordConfirm: "",
        showPasswordMatchError: false,
        errorMessage: null,
      };

    case "SAVE_ACCOUNT_SETTINGS_ERROR":
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: payload.errorType || "generic",
      };

    case "COMPARE_PASSWORDS":
      return {
        ...state,
        showPasswordMatchError: !payload.passwordsMatch,
      };

    case "ENABLE_ACCOUNT_SETTINGS_SAVE_BUTTON":
      return {
        ...state,
        enableSaveButton: payload.enableButton,
      };

    case "UPLOAD_ACCOUNT_PHOTO_FETCHING":
      return {
        ...state,
        photoUploadLoading: true,
        photoUploadError: false,
        photoUploadSuccess: false,
      };

    case "UPLOAD_ACCOUNT_PHOTO_SUCCESS":
      return {
        ...state,
        photoUploadLoading: false,
        photoUploadSuccess: true,
      };

    case "UPLOAD_ACCOUNT_PHOTO_ERROR":
      return {
        ...state,
        photoUploadLoading: false,
        photoUploadError: payload.error,
      };

    case "OPEN_REMOVE_PHOTO_MODAL":
      return {
        ...state,
        removePhotoModalOpened: true,
      };

    case "CLOSE_REMOVE_PHOTO_MODAL":
    case "DELETE_ACCOUNT_PHOTO_SUCCESS":
      return {
        ...state,
        removePhotoModalOpened: false,
      };

    default:
      return state;
  }
};

export default account;
