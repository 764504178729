import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl, defineMessages } from "react-intl";
import { loadSettingsSection } from "../../redux/actions/settings";
import {
  filterAccountsForPaymentSettings,
  paymentSettingsPath,
} from "../../util/paymentSettingsUtils";
import SettingsItem from "./SettingsItem";
import PageHeader from "../shared/PageHeader";

const SettingsOverviewContainer = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const overviewItems = useSelector(
    (state) => state.settings?.overview?.overviewItems || [],
  );
  const accounts = useSelector((state) => state.user.accounts || []);

  const messages = defineMessages({
    paymentSettingsBetaTooltip: { id: "paymentSettingsBetaTooltip" },
  });

  const handleItemClick = (data) => {
    !data.disabled && dispatch(loadSettingsSection(data?.path || ""));
  };

  return (
    <section className="settings-page settings-overview">
      <PageHeader headerId="settings" />
      <div className="row">
        {overviewItems
          .filter(({ path }) => {
            // only show payment settings box if you can see at least one account's payment info
            if (path === paymentSettingsPath) {
              return filterAccountsForPaymentSettings(accounts).length >= 1;
            }
            return true;
          })
          .map((item) => {
            // FYI: in order for you to add more tooltips and beta flags here,
            // re-write the below betaFlagTooltipText logic
            return (
              <SettingsItem
                key={item.title}
                data={item}
                handleClick={handleItemClick}
                betaFlag={item.betaFlag}
                betaFlagTooltipText={intl.formatMessage(
                  messages.paymentSettingsBetaTooltip,
                )}
              />
            );
          })}
      </div>
    </section>
  );
};

export default SettingsOverviewContainer;
