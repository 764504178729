import React from "react";
import { connect } from "react-redux";
import { linkBuilderTypeChange } from "../../../redux/actions/linkBuilder";
import SingleItemLinkBuilder from "./SingleItemLinkBuilder";
import MultiItemLinkBuilder from "./MultiItemLinkBuilder";
import { FormattedMessage } from "react-intl";

const LinkBuilderArea = ({ dispatch, linkBuilderType }) => {
  const onSingleClick = () => {
    dispatch(linkBuilderTypeChange("single"));
  };

  const onMultiClick = () => {
    dispatch(linkBuilderTypeChange("multi"));
  };

  return (
    <div className="amazon-link-builder-area opaque-container">
      <div className="link-builder-type-toggles">
        <div
          onClick={onSingleClick}
          className={
            linkBuilderType === "single"
              ? "link-builder-type-toggle active"
              : "link-builder-type-toggle"
          }
        >
          <i className="fa fa-keyboard"></i>
          <span>
            <FormattedMessage id="singleItemLink" />
          </span>
        </div>
        <div
          onClick={onMultiClick}
          className={
            linkBuilderType === "multi"
              ? "link-builder-type-toggle active"
              : "link-builder-type-toggle"
          }
        >
          <i className="fa fa-keyboard"></i>
          <span>
            <FormattedMessage id="multiItemLink" />
          </span>
        </div>
      </div>
      <div className="link-builder-area">
        {linkBuilderType === "single" && <SingleItemLinkBuilder />}
        {linkBuilderType === "multi" && <MultiItemLinkBuilder />}
      </div>
    </div>
  );
};

const mapStateToProps = ({ amazonLinkBuilder }) => {
  return {
    linkBuilderType: amazonLinkBuilder.linkBuilderType,
  };
};

export default connect(mapStateToProps)(LinkBuilderArea);
