import React from "react";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import PageLoader from "../loader/PageLoader";
import { gaNotificationClick } from "../../redux/actions/ga";

const NotificationSection = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(
    (state) => state.notifications.items || [],
    shallowEqual,
  );
  const loading = useSelector((state) => state.notifications.loading);
  const error = useSelector((state) => state.notifications.error);

  const handleNotificationClick = ({ linkUrl, title }) => {
    const target = linkUrl?.indexOf("creator.studio") < 0 ? "_blank" : "_self";

    dispatch(
      gaNotificationClick({
        notificationName: title,
        notificationUrl: linkUrl,
      }),
    );

    if (linkUrl) {
      window.open(linkUrl, target);
    }
  };

  return (
    <div className="notification-section col-12 col-md-7 order-1 order-md-1">
      <div className="opaque-container">
        <h3>
          <FormattedMessage id="notifications" defaultMessage="Notifications" />
        </h3>
        <PageLoader loading={loading} error={error} size="medium">
          <ul>
            {notifications.map(({ title, content, postDate, linkUrl }) => {
              return (
                <li
                  key={postDate}
                  className={linkUrl ? "clickable" : null}
                  onClick={() => handleNotificationClick({ linkUrl, title })}
                >
                  <p>
                    <strong>{title}</strong> -{" "}
                    <span dangerouslySetInnerHTML={{ __html: content }} />
                  </p>
                </li>
              );
            })}
          </ul>
        </PageLoader>
      </div>
    </div>
  );
};

export default NotificationSection;
