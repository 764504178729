import React from "react";
import { connect } from "react-redux";
import {
  getOpportunity,
  goToOpportunitiesContainer,
} from "../../redux/actions/opportunities";
import { injectIntl, FormattedMessage } from "react-intl";
import PageLoader from "../loader/PageLoader";
import ContactUsButton from "../shared/ContactUsButton";
import PostDetailContainer from "../shared/PostDetailContainer";

class OpportunitiesDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleAllOpportunitiesClick =
      this.handleAllOpportunitiesClick.bind(this);
  }

  componentDidMount() {
    let id = this.props.match?.params?.id;
    this.props.dispatch(getOpportunity(id));
  }

  handleAllOpportunitiesClick() {
    this.props.dispatch(goToOpportunitiesContainer());
  }

  createMarkup() {
    const { item } = this.props.wpDetailContent;
    let decodedContent = null;
    if (item) {
      decodedContent = decodeURIComponent(item.post_content || "").trim();
    }
    return { __html: decodedContent };
  }

  render() {
    const { loading, error, item, pageNotFound } = this.props.wpDetailContent;

    const content = <div dangerouslySetInnerHTML={this.createMarkup()} />;

    const date = this.props.intl.formatDate(
      (item.post_date || "").replace(" ", "T"),
      {
        year: "numeric",
        month: "long",
        day: "2-digit",
      },
    );

    return (
      <PageLoader
        loading={loading}
        error={error}
        fadeOut={true}
        pageNotFound={pageNotFound}
      >
        <button
          className="button pill-button primary"
          onClick={this.handleAllOpportunitiesClick}
        >
          <FormattedMessage
            id={`allOpportunities`}
            defaultValue={`All Opportunities`}
          />
        </button>
        <PostDetailContainer
          header={item.post_title}
          subheader={`${item.post_author} - ${date}`}
          content={content}
          featuredImage={item.featured_image}
        />
        <ContactUsButton pageType="opportunities_detail" />
      </PageLoader>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    wpDetailContent: { ...store.opportunities },
  };
};

export default connect(mapStateToProps)(injectIntl(OpportunitiesDetailPage));
