export const paymentSettingsPath = "/settings/payment";

export const paymentSettingsPermissionGroups = ["admin", "finance"];

export const filterAccountsForPaymentSettings = (accounts) => {
  return accounts?.filter(({ permission_group }) => {
    return paymentSettingsPermissionGroups.includes(
      permission_group.toLowerCase(),
    );
  });
};
