import React, { Component } from "react";
import AccountDetails from "./AccountDetails";
import AccountPhoto from "./AccountPhoto";
import PageHeader from "../../shared/PageHeader";

class AccountSettingsContainer extends Component {
  render() {
    return (
      <section className="account-settings-container">
        <PageHeader
          headerId="accountSettings"
          subheaderId="accountSettingsDescription"
        />
        <div className="account-settings-content row">
          <div className="col-12 col-lg-4">
            <AccountPhoto />
          </div>
          <div className="details-container col-12 col-lg-8">
            <AccountDetails />
          </div>
        </div>
      </section>
    );
  }
}

export default AccountSettingsContainer;
