import React, { Component } from "react";
import PropTypes from "prop-types";
import NativeDropdown from "./NativeDropdown";
import ReactSelect from "./ReactSelect";
import BREAKPOINTS from "../../../constants/breakpoints";

/**
 *  Custom Dropdown component
 *  @prop {array} items: array of objects containing value and label keys
 *  @prop {string, element} labelText: default placeholderText -  Label text
 *  @prop {string} placeholderText:  default "Select an Option" - placeholder text
 *  @prop {string, number} selected: selected option
 *  @prop {function} onChange: handler function
 */

class CustomDropdown extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    if (e?.target) {
      this.props.onChange(e.target.value);
    } else if (e?.value) {
      this.props.onChange(e.value);
    }
  }

  render() {
    const { items, selected, className, labelText, placeholderText } =
      this.props;

    const placeHolder = placeholderText || "Select an option";
    const selectedItem =
      items?.filter((item) => item.value + "" === selected + "")?.[0] || null;

    if (window.innerWidth >= BREAKPOINTS.TABLET_LANDSCAPE) {
      return (
        <div className={`custom-dropdown${className ? ` ${className}` : ""}`}>
          <ReactSelect
            handleChange={this.handleChange}
            selected={selectedItem}
            placeholder={placeHolder}
            labelText={labelText || placeHolder}
            items={items}
          />
        </div>
      );
    } else {
      return (
        <div className={`custom-dropdown${className ? ` ${className}` : ""}`}>
          <NativeDropdown
            handleChange={this.handleChange}
            selected={selected}
            labelText={labelText || placeHolder}
            placeholder={placeHolder}
            items={items}
          />
        </div>
      );
    }
  }
}

export default CustomDropdown;

CustomDropdown.propTypes = {
  items: PropTypes.array, // [{ value: "someKindOfKey", label: "What is displayed" }, ...]
  className: PropTypes.string,
  labelText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placeholderText: PropTypes.string,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
