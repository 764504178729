const initialState = {
  loading: false,
  error: false,
  items: [],
};

const notifications = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'GET_NOTIFICATIONS_FETCHING':
      return {
        ...state,
        loading: true,
        error: false,
      };

    case 'GET_NOTIFICATIONS_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
      };

    case 'GET_NOTIFICATIONS_SUCCESS':
      return {
        ...state,
        loading: false,
        items: payload.notifications || [],
      };

    default:
      return state;
  };
};

export default notifications;
