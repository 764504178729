import React, { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { uploadPhoto, openRemovePhotoModal } from '../../../redux/actions/settings';
import PageLoader from '../../loader/PageLoader';
import RemoveImageOverlay from './RemoveImageOverlay';
import PhotoUploadError from './PhotoUploadError';
import DeletePhotoConfirmation from './DeletePhotoConfirmation';

const AccountPhoto = () => {
  const dispatch = useDispatch();
  const { loading, photoUploadError, thumbnail, modalOpened } = useSelector(state => {
    return {
      loading: state.settings?.account?.photoUploadLoading,
      photoUploadError: state.settings?.account?.photoUploadError,
      thumbnail: state.user?.thumbnail,
      modalOpened: state.settings?.account?.removePhotoModalOpened,
    };
  }, shallowEqual);

  const photoStyle = { backgroundImage: `url(${thumbnail})`};

  const handleUpload = useCallback((e) => {
    e.target.files[0] && dispatch(uploadPhoto(e.target.files[0]));
  }, [dispatch]);

  const handleDeleteClick = useCallback(() => {
    dispatch(openRemovePhotoModal());
  }, [dispatch]);

  return (
    <div className="account-photo-container opaque-container">
      <div className="photo-container">
        <PageLoader transparent={true} loading={loading} size="medium">
          <RemoveImageOverlay thumbnail={thumbnail} handleClick={handleDeleteClick} />
          <div className="photo" style={thumbnail ? photoStyle : {}}/>
        </PageLoader>
      </div>
      <div className="actions">
        {thumbnail && <p className="remove-photo" onClick={handleDeleteClick}>
          <FormattedMessage id="removePhoto" defaultMessage="Remove Photo" />
        </p>}
        <label className="button secondary">
          <FormattedMessage id="uploadNewPhoto" defaultMessage="Upload New Photo" />
          <input type="file" accept="image/jpeg" id="image-upload-input" onChange={handleUpload} />
        </label>
        <em>
          <FormattedMessage id="photoRequirements" defaultMessage="1MB limit preferably a square (1:1) ratio" />
        </em>
        <PhotoUploadError error={photoUploadError} />
      </div>
      <DeletePhotoConfirmation opened={modalOpened} />
    </div>
  );
};

export default AccountPhoto;
