import React from "react";
import { connect } from "react-redux";
import getQueryStringParams from "../../util/getQueryStringParams";
import {
  initializeOpportunitiesPage,
  changeOpportunityContainerPage,
} from "../../redux/actions/opportunities";
import { navigateToPath } from "../../redux/actions/ui";
import PageLoader from "../loader/PageLoader";
import Pagination from "../shared/pagination/Pagination";
import AmazonLinkBuilderTile from "./AmazonLinkBuilder/AmazonLinkBuilderTile";
import ContainerTile from "../shared/containerTile/ContainerTile";
import PageHeader from "../shared/PageHeader";

class OpportunitiesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.goToLinkBuilder = this.goToLinkBuilder.bind(this);
    this.handleTileClick = this.handleTileClick.bind(this);
    this.changePage = this.changePage.bind(this);
  }

  componentDidMount() {
    const pageNumber =
      getQueryStringParams(this.props.location?.search).page || 1;
    this.props.dispatch(initializeOpportunitiesPage(pageNumber));
  }

  componentDidUpdate(prevProps) {
    if (this.props.location?.search !== prevProps.location?.search) {
      const pageNumber =
        getQueryStringParams(this.props.location?.search).page || 1;
      this.props.dispatch(initializeOpportunitiesPage(pageNumber));
    }
  }

  goToLinkBuilder(e) {
    // the A is anchor meaning you clicked a link, and not the opportunity itself
    if (e.target.tagName !== "A" && this.props.affiliateCode !== null) {
      this.props.dispatch(navigateToPath("/amazon-link-builder"));
    }
  }

  handleTileClick(id) {
    this.props.dispatch(navigateToPath(`/opportunities/${id}`));
  }

  changePage(page) {
    this.props.dispatch(changeOpportunityContainerPage(page));
  }

  render() {
    const { loading, error, errorMessage, items, pagination } =
      this.props.opportunities;

    return (
      <PageLoader
        loading={loading}
        error={error}
        errorMessage={errorMessage}
        fadeOut={true}
      >
        <section className="opportunities-container">
          <PageHeader
            headerId="opportunities"
            subheaderId="opportunitiesSubheader"
          />
          <AmazonLinkBuilderTile
            affiliateCode={this.props.affiliateCode}
            goToLinkBuilder={this.goToLinkBuilder}
          />
          <div className="opportunities-list tile-list row container-preview-area">
            {items.map((item) => {
              return (
                <ContainerTile
                  {...item}
                  key={item.ID}
                  type="opportunity"
                  container="opportunity"
                  handleTileClick={this.handleTileClick}
                />
              );
            })}
          </div>
          <Pagination
            page={pagination.page}
            totalPages={pagination.totalPages}
            handleClick={this.changePage}
          />
        </section>
      </PageLoader>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    opportunities: store.opportunities,
    affiliateCode: store.user.affiliateCode,
  };
};

export default connect(mapStateToProps)(OpportunitiesContainer);
