import axios from 'axios';
import store from '../../store';
import { logout } from './user';

const { dispatch } = store;

const api = axios.create({
  validateStatus: function (status) {
    const validStatuses = [401];
    return (status < 400 || validStatuses.includes(status));
  }
});

api.interceptors.response.use(response => {
  const invalidTokenMessages = ['Unauthorized: No token provided', 'Unauthorized: Invalid token'];

  if (response.status === 401 && invalidTokenMessages.includes(response.data.message)) {
    dispatch(logout());
    return Promise.reject(response);
  }

  return response;
}, error => {
    return Promise.reject(error.response);
});

export default api;
