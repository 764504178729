import { filterAccountsForPaymentSettings } from "../../util/paymentSettingsUtils";

const initialState = {
  id: null,
  firstName: "",
  lastName: "",
  profileImg: null,
  loggedIn: false,
  loginError: false,
  loginPending: true,
  logoutPending: false,
  accounts: [],
  paymentSettingsAccounts: [],
  active: null,
  affiliates: [],
  base: {},
  created_on: "",
  email: "",
  feeds: [],
  groups: [],
  intro: "",
  username: "",
  affiliateCode: "",
  affiliateCodeList: [],
  thumbnail: null,
  userClickedLogoutButton: false,
  userGroupAuthError: false,
  userGroupAuthErrorGroups: [],
  matchedRestrictionGroup: {},
  monthlyStatementEmailUnsubscribe: {
    loading: false,
    success: false,
    error: false,
  },
};

const user = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "UPDATE_USER_LOGIN_STATUS":
      return {
        ...state,
        loggedIn: payload.status,
      };

    case "USER_LOGIN_PENDING":
      return {
        ...state,
        loginPending: true,
      };

    case "USER_LOGIN_SUCCESS": {
      const paymentSettingsAccounts = filterAccountsForPaymentSettings(
        payload.accounts,
      );

      return {
        ...state,
        loginPending: false,
        id: payload.id,
        firstName: payload.first_name || "",
        lastName: payload.last_name || "",
        loggedIn: true,
        loginError: false,
        accounts: payload.accounts || [],
        paymentSettingsAccounts,
        active: payload.active,
        affiliates: payload.affiliates || [],
        base: payload.base || {},
        created_on: payload.created_on || "",
        email: payload.email || "",
        feeds: payload.feeds || [],
        groups: payload.groups || [],
        intro: payload.intro || "",
        username: payload.username || "",
        affiliateCode: payload.affiliateCode || "",
        affiliateCodeList: payload.affiliateCodeList || [],
        thumbnail: payload.thumbnail || null,
        matchedRestrictionGroup: payload.matchedRestrictionGroup || {},
      };
    }

    case "USER_LOGIN_ERROR": {
      return {
        ...state,
        loginError: true,
        loginPending: false,
      };
    }

    case "USER_LOGOUT_BUTTON_CLICKED":
      return {
        ...state,
        userClickedLogoutButton: true,
      };

    case "USER_LOGOUT_FETCHING":
      return {
        ...state,
        logoutPending: true,
      };

    case "USER_LOGOUT_SUCCESS": {
      return {
        ...initialState,
        loginError: false,
        loggedIn: false,
        loginPending: false,
        logoutPending: false,
        userGroupAuthError: payload.userGroupAuthError,
        userGroupAuthErrorGroups: payload.userGroups,
      };
    }

    case "SAVE_ACCOUNT_SETTINGS_SUCCESS":
      return {
        ...state,
        firstName: payload.user_update?.first_name,
        lastName: payload.user_update?.last_name,
      };

    case "UPLOAD_ACCOUNT_PHOTO_SUCCESS":
      return {
        ...state,
        thumbnail: payload.thumbnail || null,
      };

    case "DELETE_ACCOUNT_PHOTO_SUCCESS":
      return {
        ...state,
        thumbnail: null,
      };

    case "SET_AFFILIATE_CODE":
      return {
        ...state,
        affiliateCode: payload.code,
      };

    case "UPDATE_CHANNELS_DATA":
      return {
        ...state,
        feeds: payload.userData,
      };

    case "USER_GROUP_AUTH_ERROR":
      return {
        ...state,
        userGroupAuthError: true,
        loginPending: false,
        loggedIn: true,
      };

    case "MONTHLY_STATEMENT_EMAIL_UNSUBSCRIBE_LOADING":
      return {
        ...state,
        monthlyStatementEmailUnsubscribe: {
          ...state.monthlyStatementEmailUnsubscribe,
          loading: true,
          success: false,
          error: false,
        },
      };

    case "MONTHLY_STATEMENT_EMAIL_UNSUBSCRIBE_SUCCESS":
      return {
        ...state,
        monthlyStatementEmailUnsubscribe: {
          ...state.monthlyStatementEmailUnsubscribe,
          loading: false,
          success: true,
          error: false,
        },
      };

    case "MONTHLY_STATEMENT_EMAIL_UNSUBSCRIBE_ERROR":
      return {
        ...state,
        monthlyStatementEmailUnsubscribe: {
          ...state.monthlyStatementEmailUnsubscribe,
          loading: false,
          success: false,
          error: true,
        },
      };

    default:
      return state;
  }
};

export default user;
