import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { openContactUsModal } from "../../redux/actions/contactUs";

const ContactUsButton = ({ pageType }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(openContactUsModal({ openedFrom: pageType }));
  };

  return (
    <div onClick={handleClick} className="contact-us-button">
      <button className="primary button pill-button">
        <FormattedMessage id="needHelpContactUs" />
      </button>
    </div>
  );
};

ContactUsButton.propTypes = {
  pageType: PropTypes.string, // resources, opportunities...
};

export default ContactUsButton;
