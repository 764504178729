import React from 'react';

const LoginError = () => {
  return (
    <div>
      <h3>There was an error logging you in!</h3>
      <h6>We'll put more on this page later!</h6>
    </div>
  );
}

export default LoginError;
