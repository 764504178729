import React from 'react';
import { connect } from 'react-redux';
import { linkBuilderTextChange } from '../../../redux/actions/linkBuilder';
import AffiliateCodeDropdown from './AffiliateCodeDropdown';
import {
  injectIntl,
  defineMessages,
  FormattedMessage,
} from 'react-intl';

const MultiItemInput = ({ dispatch, intl, multiText, multiTextValid, affiliateCodeList }) => {
  const textValidationError = (multiTextValid === false);

  const onChange = (e) => {
    dispatch(linkBuilderTextChange("multi", e.target.value));
  }

  // this is so the react-intl works as textarea placeholder
  const messages = defineMessages({
    placeholder: {
      id: 'enterUrlsHere',
      defaultMessage: 'ENTER URLS HERE'
    }
  });

  return (
    <div className={`multi-text-input ${affiliateCodeList.length > 1 ? 'multiple-codes' : ''}`}>
      {affiliateCodeList.length > 1 && <AffiliateCodeDropdown />}
      <textarea
        rows="5" cols="50"
        onChange={onChange}
        placeholder={intl.formatMessage(messages.placeholder)}
        className={textValidationError ? 'error' : ''}
        value={multiText}
      ></textarea>
      { textValidationError  &&  <p className="error"><FormattedMessage id="enterValidAmazons" /></p> }
    </div>
  );
}

const mapStateToProps = ({amazonLinkBuilder, user}) => {
  return {
    multiText: amazonLinkBuilder.multiText,
    multiTextValid: amazonLinkBuilder.multiTextValid,
    affiliateCodeList: user.affiliateCodeList,
  };
};

export default connect(mapStateToProps)(injectIntl(MultiItemInput));
