import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import translations from "./util/translations/index";
import history from "./history";
import { init, completeLogin, checkAuth } from "./redux/actions/user";
import { removeSiteLoader } from "./redux/actions/ui";
import PageLoader from "./components/loader/PageLoader";
import MainNavContainer from "./components/mainNav/MainNavContainer";
import AppRoutes from "./AppRoutes";
import Footer from "./components/Footer";
import ContactUsModal from "./components/contactUsModal/ContactUsModal";
import AuthRoadblockModal from "./components/authRoadblockModal/AuthRoadblockModal";

const AppContent = () => {
  const dispatch = useDispatch();
  const siteLoading = useSelector((state) => state.ui.siteLoading);
  const contactUsModalOpen = useSelector(
    (state) => state.contactUs.contactUsModalOpen,
  );
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const loginPending = useSelector((state) => state.user.loginPending);
  const userGroupAuthError = useSelector(
    (state) => state.user.userGroupAuthError,
  );
  const userId = useSelector((state) => state.user.id);
  const { search, pathname } = history?.location || {};

  // If this is the redirect back from OAuth complete the login otherwise check for login status
  useEffect(() => {
    if (pathname === "/auth") {
      dispatch(completeLogin(search));
    } else {
      dispatch(checkAuth());
    }
  }, [dispatch, search, pathname]);

  // If the user is logged in initialize the app
  useEffect(() => {
    if (loggedIn && !userId && !userGroupAuthError) {
      dispatch(init());
    }
  }, [dispatch, loggedIn, userId, userGroupAuthError]);

  // Remove the top level loader if the user is logged out and there's no login pending
  // or if is a user group auth error
  useEffect(() => {
    if ((!loggedIn && !loginPending) || userGroupAuthError) {
      dispatch(removeSiteLoader());
    }
  }, [dispatch, loggedIn, loginPending, userGroupAuthError]);

  // FYI: App can support multiple languages via IntlProvider, but Product wants it hard-coded to "en".
  // If multiple language support returns you need to track it with the Backend and pass the value here as a prop.
  return (
    <PageLoader loading={siteLoading} fadeOut={true}>
      <IntlProvider locale="en" key="en" messages={translations.en}>
        <div id="creator-app" className={!loggedIn ? "logged-out" : undefined}>
          <MainNavContainer />
          <main className="app-content">
            <div className="container-fluid">
              <AppRoutes />
              <ContactUsModal opened={contactUsModalOpen} />
              <AuthRoadblockModal />
            </div>
            <Footer locale="en" />
          </main>
        </div>
      </IntlProvider>
    </PageLoader>
  );
};

export default AppContent;
