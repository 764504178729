import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage }  from 'react-intl';

import PageLoader from '../loader/PageLoader'

const UIElementExamples = props => {
  return (
    <PageLoader loading={props.pageLoading} render={() => (
      <div className="ui-element-examples">
        <h2><FormattedMessage id="greeting" values={{name: props.firstName}} /></h2>
        <div className="opaque-container">
          <h3>The first div header</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec fringilla arcu sem, sit amet consequat ante lobortis sed. Etiam hendrerit magna arcu, ac bibendum nisl sagittis ut. Phasellus quis arcu vitae elit ullamcorper eleifend sit amet quis ipsum. Morbi blandit malesuada sodales. Phasellus eu sagittis sapien. Maecenas dolor sem, tempus a augue sit amet, euismod pretium nisl. Donec ornare id velit sed luctus. Etiam suscipit tincidunt arcu sit amet consequat. Donec eu sapien turpis. Cras tristique posuere elementum. Aliquam vel odio leo. Proin at ligula vitae quam semper interdum. Nunc feugiat mi vel aliquet lacinia. In odio magna, euismod et purus a, tempus pharetra felis. Praesent consequat elit justo.
          </p>
          <p>
            <button className="primary">Primary Button</button>
          </p>
          <p>
            <button className="primary pill-button">Pill Button</button>
          </p>
          <p>
            <button className="secondary">Secondary Button</button>
          </p>
          <p>
            <button className="secondary pill-button">Pill Button</button>
          </p>
          <p>
            <button>Button</button>
          </p>
          <p>
            <button className="pill-button">Pill Button</button>
          </p>
        </div>

        <div className="row">
          <div className="col-sm-12 col-md-6 col-xl-4">
            <div className="opaque-container offset-header">
              <h3 className="gradient-header">Another Div With Another Header</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec fringilla arcu sem, sit amet consequat ante lobortis sed. Etiam hendrerit magna arcu, ac bibendum nisl sagittis ut. Phasellus quis arcu vitae elit ullamcorper eleifend sit amet quis ipsum. Morbi blandit malesuada sodales. Phasellus eu sagittis sapien. Maecenas dolor sem, tempus a augue sit amet, euismod pretium nisl. Donec ornare id velit sed luctus. Etiam suscipit tincidunt arcu sit amet consequat. Donec eu sapien turpis. Cras tristique posuere elementum. Aliquam vel odio leo. Proin at ligula vitae quam semper interdum. Nunc feugiat mi vel aliquet lacinia. In odio magna, euismod et purus a, tempus pharetra felis. Praesent consequat elit justo.
              </p>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-xl-4">
            <div className="opaque-container offset-header">
              <h3 className="gradient-header">Another Div With Another Header</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec fringilla arcu sem, sit amet consequat ante lobortis sed. Etiam hendrerit magna arcu, ac bibendum nisl sagittis ut. Phasellus quis arcu vitae elit ullamcorper eleifend sit amet quis ipsum. Morbi blandit malesuada sodales. Phasellus eu sagittis sapien. Maecenas dolor sem, tempus a augue sit amet, euismod pretium nisl. Donec ornare id velit sed luctus. Etiam suscipit tincidunt arcu sit amet consequat. Donec eu sapien turpis. Cras tristique posuere elementum. Aliquam vel odio leo. Proin at ligula vitae quam semper interdum. Nunc feugiat mi vel aliquet lacinia. In odio magna, euismod et purus a, tempus pharetra felis. Praesent consequat elit justo.
              </p>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-xl-4">
            <div className="opaque-container offset-header">
              <h3 className="gradient-header">Another Div With Another Header</h3>
              <PageLoader loading={true} size="medium">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec fringilla arcu sem, sit amet consequat ante lobortis sed. Etiam hendrerit magna arcu, ac bibendum nisl sagittis ut. Phasellus quis arcu vitae elit ullamcorper eleifend sit amet quis ipsum. Morbi blandit malesuada sodales. Phasellus eu sagittis sapien. Maecenas dolor sem, tempus a augue sit amet, euismod pretium nisl. Donec ornare id velit sed luctus. Etiam suscipit tincidunt arcu sit amet consequat. Donec eu sapien turpis. Cras tristique posuere elementum. Aliquam vel odio leo. Proin at ligula vitae quam semper interdum. Nunc feugiat mi vel aliquet lacinia. In odio magna, euismod et purus a, tempus pharetra felis. Praesent consequat elit justo.
                </p>
              </PageLoader>
            </div>
          </div>


          <div className="col-sm-12 col-md-6 col-xl-4">
            <div className="opaque-container offset-header">
              <h3 className="gradient-header red">Another Div With Another Header</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec fringilla arcu sem, sit amet consequat ante lobortis sed. Etiam hendrerit magna arcu, ac bibendum nisl sagittis ut. Phasellus quis arcu vitae elit ullamcorper eleifend sit amet quis ipsum. Morbi blandit malesuada sodales. Phasellus eu sagittis sapien. Maecenas dolor sem, tempus a augue sit amet, euismod pretium nisl. Donec ornare id velit sed luctus. Etiam suscipit tincidunt arcu sit amet consequat. Donec eu sapien turpis. Cras tristique posuere elementum. Aliquam vel odio leo. Proin at ligula vitae quam semper interdum. Nunc feugiat mi vel aliquet lacinia. In odio magna, euismod et purus a, tempus pharetra felis. Praesent consequat elit justo.
              </p>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-xl-4">
            <div className="opaque-container offset-header">
              <h3 className="gradient-header red">Another Div With Another Header</h3>
              <PageLoader loading={true} size="medium">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec fringilla arcu sem, sit amet consequat ante lobortis sed. Etiam hendrerit magna arcu, ac bibendum nisl sagittis ut. Phasellus quis arcu vitae elit ullamcorper eleifend sit amet quis ipsum. Morbi blandit malesuada sodales. Phasellus eu sagittis sapien. Maecenas dolor sem, tempus a augue sit amet, euismod pretium nisl. Donec ornare id velit sed luctus. Etiam suscipit tincidunt arcu sit amet consequat. Donec eu sapien turpis. Cras tristique posuere elementum. Aliquam vel odio leo. Proin at ligula vitae quam semper interdum. Nunc feugiat mi vel aliquet lacinia. In odio magna, euismod et purus a, tempus pharetra felis. Praesent consequat elit justo.
                </p>
              </PageLoader>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-xl-4">
            <div className="opaque-container offset-header">
              <h3 className="gradient-header red">Another Div With Another Header</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec fringilla arcu sem, sit amet consequat ante lobortis sed. Etiam hendrerit magna arcu, ac bibendum nisl sagittis ut. Phasellus quis arcu vitae elit ullamcorper eleifend sit amet quis ipsum. Morbi blandit malesuada sodales. Phasellus eu sagittis sapien. Maecenas dolor sem, tempus a augue sit amet, euismod pretium nisl. Donec ornare id velit sed luctus. Etiam suscipit tincidunt arcu sit amet consequat. Donec eu sapien turpis. Cras tristique posuere elementum. Aliquam vel odio leo. Proin at ligula vitae quam semper interdum. Nunc feugiat mi vel aliquet lacinia. In odio magna, euismod et purus a, tempus pharetra felis. Praesent consequat elit justo.
              </p>
            </div>
          </div>
        </div>
      </div>
    )} />
  );
};

const mapStateToProps = ({user, ui}) => {
  const { firstName = '' } = user;
  const { pageLoading = false } = ui;

  return {
    firstName: firstName || null,
    pageLoading: pageLoading || false
  };
}

export default connect(mapStateToProps)(UIElementExamples);
