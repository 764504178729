import React from 'react';

const NextButton = ({handleClick}) => {
  return (
    <li className="next">
      <button className="next" onClick={handleClick}>
        <span className="text">Next</span>
        <span className="icon next">
          <i className="icon next fas fa-caret-right" />
        </span>
      </button>
    </li>
  );
};

export default NextButton;
