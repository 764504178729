const initialState = {
  reportLoading: true,
  reportError: false,
  data: null,
  channels: null,
  selectedChannel: null
}

const report = (state = initialState, action) => {
  const { type = "", payload = {} } = action;
  const { data = [], filteredFeeds = [], selectedChannel = {}} = payload;

  switch (type) {
    case "USER_LOGIN_SUCCESS":
      return {
        ...state,
        selectedChannel,
        channels: filteredFeeds
      };

    case "GET_REPORT_DATA_FETCHING":
    case "UPDATE_REPORT_DATA_FETCHING":
      return {
        ...state,
        reportLoading: true
      };

    case "GET_REPORT_DATA_SUCCESS":
      return {
        ...state,
        reportLoading: false,
        data: data,
        selectedChannel: selectedChannel
      };

    case "UPDATE_REPORT_DATA_SUCCESS":
      return {
        ...state,
        reportLoading: false,
        data: data,
        selectedChannel: selectedChannel
      };

    case "GET_REPORT_DATA_ERROR":
    case "GET_CHANNELS_ERROR":
    case "UPDATE_REPORT_DATA_ERROR":
      return {
        ...state,
        reportLoading: false,
        reportError: true
      };

    case "RESET_REPORTS":
      return {
        ...state,
        selectedChannel: payload
      };

    default:
      return state;
  }
}

export default report;