import api from "./api";
import { navigateToPath } from "./ui";

export const initBlogPage = (pageNumber) => {
  return (dispatch) => {
    dispatch({ type: "INITIALIZE_BLOG_PAGE" });
    dispatch(getBlogPosts(pageNumber));
  };
};

export const changeBlogPage = (pageNumber) => {
  return (dispatch) => {
    dispatch({ type: "_CHANGE_BLOG_OVERVIEW_PAGE", payload: { pageNumber } });
    dispatch(navigateToPath(`/blog?page=${pageNumber}`));
  };
};

export const goToBlog = () => {
  return (dispatch) => {
    dispatch({ type: "_GO_TO_BLOG_OVERVIEW" });
    dispatch(navigateToPath(`/blog?page=1`));
  };
};

export const getBlogPosts = (page, per) => {
  return (dispatch, getState) => {
    const state = getState().blogOverview;
    const params = {
      page: page ? +page : 1,
      per: per || state.pagination.per,
      postType: "latest-news",
    };

    dispatch({ type: "GET_BLOG_POSTS_FETCHING", payload: { params } });

    api
      .post("/api/posts/list", params)
      .then((resp) => {
        const data = resp?.data;
        const totalPages = Math.ceil(
          (data?.pagination?.total || 0) / (data?.pagination?.per || 5),
        );
        const payload = {
          items: data.items,
          pagination: {
            ...data?.pagination,
            totalPages,
          },
        };

        dispatch({ type: "GET_BLOG_POSTS_SUCCESS", payload });
      })
      .catch((err) => {
        dispatch({ type: "GET_BLOG_POSTS_ERROR", payload: err });
      });
  };
};

export const loadBlogDetailPage = (id, title, container) => {
  return (dispatch) => {
    dispatch({ type: "_LOAD_BLOG_DETAIL_PAGE", payload: { id } });
    dispatch(navigateToPath(`/blog/${id}`));
  };
};

export const getBlogDetail = (id) => {
  return (dispatch) => {
    dispatch({ type: "GET_BLOG_DETAIL_FETCHING", payload: { id } });
    if (isNaN(+id)) {
      return dispatch({
        type: "GET_BLOG_DETAIL_ERROR",
        payload: { pageNotFound: true },
      });
    }
    api
      .post("/api/blog/detail", { id })
      .then((resp) => {
        const data = resp?.data;
        const payload = data?.items?.items?.[0];

        if (!payload) {
          return dispatch({
            type: "GET_BLOG_DETAIL_ERROR",
            payload: { pageNotFound: true },
          });
        }
        dispatch({ type: "GET_BLOG_DETAIL_SUCCESS", payload });
      })
      .catch((err) => {
        dispatch({ type: "GET_BLOG_DETAIL_ERROR", payload: err });
      });
  };
};
