import React from "react";
import { connect } from "react-redux";
import { navigateToPath } from "../../../redux/actions/ui";
import { FormattedMessage } from "react-intl";
import UpdatePasswordForm from "./UpdatePasswordForm";
import ActionButtons from "./ActionButtons";
import {
  changeTextInput,
  updateAccountDetails,
} from "../../../redux/actions/settings";

const AccountDetails = (props) => {
  const { firstName, lastName, email } = props;

  const handleFormSubmit = (e) => {
    e.preventDefault();
    props.updateAccountDetails();
  };

  return (
    <div className="opaque-container">
      <form data-testid="account-settings-form" onSubmit={handleFormSubmit}>
        <div className="row">
          <div className="col-12 col-md-6">
            <label className="first-name">
              <FormattedMessage id="firstName" defaultMessage="First Name" />
              <input
                name="firstName"
                type="text"
                value={firstName}
                onChange={props.changeTextInput}
              />
            </label>
            <label className="last-name">
              <FormattedMessage id="lastName" defaultMessage="Last Name" />
              <input
                name="lastName"
                type="text"
                value={lastName}
                onChange={props.changeTextInput}
              />
            </label>
          </div>
          <div className="col-12 col-md-6">
            <p className="email-address">
              <FormattedMessage
                id="emailAddress"
                defaultMessage="Email Address"
              />
              <span>{email}</span>
              <em>
                <FormattedMessage
                  id="contactUsChangeEmail"
                  defaultMessage="Please contact us to have your email address changed."
                />
              </em>
            </p>
            <div className="change-password">
              <FormattedMessage id="password" defaultMessage="Password" />
              <UpdatePasswordForm />
            </div>
          </div>
        </div>
        <ActionButtons />
      </form>
    </div>
  );
};

const mapStateToProps = ({ settings }) => {
  const { account } = settings;
  return {
    firstName: account?.firstName || "",
    lastName: account?.lastName || "",
    email: account?.email || "",
    updatePasswordFormVisibile: account?.updatePasswordFormVisibile,
    showSuccessMessage: account?.showSuccessMessage,
  };
};

const mapDispatchToProps = {
  updateAccountDetails,
  changeTextInput,
  navigateToPath,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails);
