import history from "../../history";
import api from "./api";
import { gaSideBarExpand, gaSideBarCollapse } from "./ga";
import { openContactUsModal } from "./contactUs";
import BREAKPOINTS from "../../constants/breakpoints";

export const toggleMainNav = (clear) => {
  return (dispatch, getState) => {
    const { navVisibilityClass = "" } = getState().mainNav;
    const nextNavVisibilityClass =
      navVisibilityClass !== "opened" ? "opened" : "closed";

    if (nextNavVisibilityClass === "opened" && !clear) {
      dispatch(gaSideBarExpand());
    } else if (nextNavVisibilityClass === "closed" && !clear) {
      dispatch(gaSideBarCollapse());
    }

    dispatch({
      type: "TOGGLE_MAIN_NAV",
      payload: { nextNavVisibilityClass: clear ? "" : nextNavVisibilityClass },
    });
  };
};

export const reloadPage = () => {
  return (dispatch) => {
    const path = history?.location?.pathname || "/";
    dispatch({ type: "_RELOAD_PAGE", payload: { path } });
    history.push(path);
  };
};

export const navigateBack = (data) => {
  return (dispatch) => {
    dispatch({ type: "_NAVIGATE_BACK", payload: { data } });
    history.goBack();
  };
};

export const navigateToPath = (path) => {
  return (dispatch) => {
    dispatch({ type: "_NAVIGATE_TO_PATH", payload: { path } });
    history.push(path);
  };
};

export const getNavItems = (args) => {
  return (dispatch, getState) => {
    const navItems = getState().mainNav.menuItems || [];
    const { filteredFeeds, matchedRestrictionGroup } = args;
    const restrictedRoutes = matchedRestrictionGroup?.restrictedRoutes || [];
    const items = navItems.reduce((arr, item) => {
      const route = `/${(item || "").toLowerCase().replace(/ /g, "-")}`;
      const isRestricted = restrictedRoutes.filter((r) => r === route).length;

      if (isRestricted || (item === "Reports" && !filteredFeeds?.length)) {
        return arr;
      }

      return arr.concat(item);
    }, []);

    return dispatch({ type: "_BUILD_NAV_ITEMS", payload: { items } });
  };
};

export const handleContactNavLinkClick = () => {
  return (dispatch) => {
    dispatch(openContactUsModal({ openedFrom: "navbar" }));
    window.innerWidth < BREAKPOINTS.DESKTOP && dispatch(toggleMainNav());
  };
};

export const logError = (error, info) => {
  return (dispatch, getState) => {
    const user = getState().user.email;
    const data = {
      error: error?.toString(),
      info,
      user,
      url: document.location.href,
    };
    api
      .post("/api/clienterrors", data)
      .then((resp) => {
        dispatch({
          type: "_CLIENT_ERROR_LOG_SUCCESS",
          payload: { sent: data, response: resp.data },
        });
      })
      .catch((err) => {
        dispatch({ type: "_CLIENT_ERROR_LOG_ERROR", payload: err });
      });
  };
};

export const removeSiteLoader = () => {
  return (dispatch) => {
    dispatch({ type: "REMOVE_SITE_LOADER" });
  };
};
