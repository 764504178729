import React from "react";
import { FormattedMessage } from "react-intl";
import { months } from '../consts/consts';

const ChannelPerformanceHeaders = ({ data: [ month0 = {}, month1 = {}, month2 = {} ] = [] }) => {
  const [month0y = "", month0m = ""] = (month0.month || "").split("-");
  const [month1y = "", month1m = ""] = (month1.month || "").split("-");
  const [month2y = "", month2m = ""] = (month2.month || "").split("-");

  return [
    <li key={1}>
      <FormattedMessage id="metric" />
    </li>,
    <li key={2}>
      <FormattedMessage
        id={months[Number(month0m) - 1] || "default"}
        defaultMessage="n/a"
      />
      {` ${month0y}`}
    </li>,
    <li key={3}>
      <FormattedMessage
        id={months[Number(month1m) - 1] || "default"}
        defaultMessage="n/a"
      />
      {` ${month1y}`}
    </li>,
    <li key={4}>
      <FormattedMessage
        id={months[Number(month2m) - 1] || "default"}
        defaultMessage="n/a"
      />
      {` ${month2y}`}
    </li>
  ];
};

export default ChannelPerformanceHeaders;
