import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { closeAddChannelModal } from '../../../redux/actions/settings';

const SuccessMessageDisplay = () => {
  const dispatch = useDispatch();
  const closeModal = useCallback(() => { dispatch(closeAddChannelModal()) }, [dispatch]);

  return (
    <div className="success-message">
      <p><FormattedMessage id="addChannelSuccess" defaultMessage="Channel added successfully!" /></p>
      <button className="primary" onClick={closeModal}><FormattedMessage id="done" defaultMessage="Done" /></button>
    </div>
  );
};

export default SuccessMessageDisplay;
