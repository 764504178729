const channelReportBase = {
  month: "pending",
  channel: {
    card_teaser_ctr: 0,
    watch_page_net_subscribers_percent_delta: 0,
    card_teaser_clicks: 0,
    card_clicks_percent_delta: 0,
    avg_view_duration_percent_delta: 0,
    avg_view_duration: 0,
    card_ctr: 0,
    comments: 0,
    card_ctr_percent_delta: 0,
    watch_page_net_subscribers: 0,
    card_teaser_ctr_percent_delta: 0,
    likes: 0,
    comments_percent_delta: 0,
    playlist_watch_time_percent_delta: 0,
    playlist_starts: 0,
    card_teaser_clicks_percent_delta: 0,
    views_percent_delta: 0,
    shares_percent_delta: 0,
    net_subscribers_percent_delta: 0,
    playlist_starts_percent_delta: 0,
    playlist_watch_time: 0,
    watch_time_percent_delta: 0,
    net_subscribers: 0,
    card_clicks: 0,
    avg_time_in_playlist: 0,
    avg_time_in_playlist_percent_delta: 0,
    shares: 0,
    watch_time: 0,
    views: 0,
    likes_percent_delta: 0
  },
  videos: {
    comments: [
      {
        id: "",
        title: "--",
        comments: 0
      },
      {
        id: "",
        title: "--",
        comments: 0
      },
      {
        id: "",
        title: "--",
        comments: 0
      },
      {
        id: "",
        title: "--",
        comments: 0
      },
      {
        id: "",
        title: "--",
        comments: 0
      }
    ],
    subscribers: [
      {
        id: "",
        title: "--",
        subscribers: 0
      },
      {
        id: "",
        title: "--",
        subscribers: 0
      },
      {
        id: "",
        title: "--",
        subscribers: 0
      },
      {
        id: "",
        title: "--",
        subscribers: 0
      },
      {
        id: "",
        title: "--",
        subscribers: 0
      }
    ],
    cards: [
      {
        id: "",
        title: "--",
        card_teaser_clicks: 0,
        card_ctr: 0
      },
      {
        id: "",
        title: "--",
        card_teaser_clicks: 0,
        card_ctr: 0
      },
      {
        id: "",
        title: "--",
        card_teaser_clicks: 0,
        card_ctr: 0
      },
      {
        id: "",
        title: "--",
        card_teaser_clicks: 0,
        card_ctr: 0
      },
      {
        id: "",
        title: "--",
        card_teaser_clicks: 0,
        card_ctr: 0
      }
    ]
  },
  playlists: {
    watch_time: [
      {
        id: "",
        title: "--",
        watch_time: 0,
        watch_time_percent_delta: 0
      },
      {
        id: "",
        title: "--",
        watch_time: 0,
        watch_time_percent_delta: 0
      },
      {
        id: "",
        title: "--",
        watch_time: 0,
        watch_time_percent_delta: 0
      },
      {
        id: "",
        title: "--",
        watch_time: 0,
        watch_time_percent_delta: 0
      },
      {
        id: "",
        title: "--",
        watch_time: 0,
        watch_time_percent_delta: 0
      }
    ]
  },
  overlap: [
    {
      id: "",
      title: "--",
      overlap: 0
    },
    {
      id: "",
      title: "--",
      overlap: 0
    },
    {
      id: "",
      title: "--",
      overlap: 0
    },
    {
      id: "",
      title: "--",
      overlap: 0
    },
    {
      id: "",
      title: "--",
      overlap: 0
    }
  ]
};

export const defaultedChannelReport = (report = []) => {
  if (!report) {
    return [];
  }

  return report.map(monthData => {
    if (!monthData.channel) {
      monthData.channel = channelReportBase.channel;
    }
    if (!monthData.videos) {
      monthData.videos = channelReportBase.videos;
    }
    if (!monthData.playlists) {
      monthData.playlists = channelReportBase.playlists;
    }
    if (!monthData.overlap) {
      monthData.overlap = channelReportBase.overlap;
    }
    if (!monthData.month) {
      monthData.month = channelReportBase.month;
    }

    return monthData;
  });
};
