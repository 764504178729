import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { monthlyStatementEmailUnsubscribe } from "../redux/actions/user";
import BarsLoader from "../svg/barsLoader";
import { FormattedMessage } from "react-intl";
import { gaUnsubscribeClick } from "../redux/actions/ga";

const MonthlyStatementEmailUnsubscribe = () => {
  const dispatch = useDispatch();
  const loading = useSelector(
    (state) => state.user.monthlyStatementEmailUnsubscribe.loading,
  );
  const success = useSelector(
    (state) => state.user.monthlyStatementEmailUnsubscribe.success,
  );
  const error = useSelector(
    (state) => state.user.monthlyStatementEmailUnsubscribe.error,
  );

  const handleClick = () => {
    if (!loading) {
      dispatch(
        gaUnsubscribeClick({
          page: "Monthly Statement Email Unsubscribe",
          value: "monthly_statement_email",
        }),
      );
      dispatch(monthlyStatementEmailUnsubscribe());
    }
  };

  return (
    <div className="monthly-statement-email-unsubscribe-container">
      <header>
        <h2>Monthly Statement Email Unsubscribe</h2>
      </header>
      {success ? (
        <div className="opaque-container">
          <div className="success-contents">
            <p>
              <FormattedMessage
                id="monthlyStatementEmailUnsubscribeSuccess"
                defaultMessage="You’ve successfully unsubscribed from Monthly Statement Emails"
              />
            </p>
          </div>
        </div>
      ) : (
        <div className="opaque-container">
          <div className="start-contents">
            <p>
              <FormattedMessage
                id="monthlyStatementEmailUnsubscribeInstructions"
                defaultMessage="Click Below to Unsubscribe from Monthly Statement Emails"
              />
            </p>
            <button
              className={`secondary${loading ? " loading" : ""}`}
              onClick={handleClick}
            >
              Unsubscribe
            </button>
          </div>
          <div className={loading ? "show-loader" : "hide-loader"}>
            <BarsLoader />
          </div>
          {error ? (
            <p className="error-message">
              <FormattedMessage
                id="monthlyStatementEmailUnsubscribeError"
                defaultMessage="An error occured while unsubscribing, please try again"
              />
            </p>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default MonthlyStatementEmailUnsubscribe;
