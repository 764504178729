import React, { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { FormattedMessage } from "react-intl";
import { selectChannel, getReportData } from "../../redux/actions/report";
import PageLoader from "../loader/PageLoader";
import CustomDropdown from "../shared/customDropdown/CustomDropdown";
import Report from "./components/Report";
import PageHeader from "../shared/PageHeader";

const ReportsPageContainer = () => {
  const dispatch = useDispatch();

  const reportLoading = useSelector((state) => state.report.reportLoading);
  const reportError = useSelector((state) => state.report.reportLoading);
  const data = useSelector((state) => state.report.data || [], shallowEqual);
  const channels = useSelector(
    (state) => state.report.channels || [],
    shallowEqual,
  );
  const channelTitle = useSelector(
    (state) => state.report.selectedChannel?.title || "",
  );
  const feedId = useSelector((state) => state.report.selectedChannel?.feed_id);

  useEffect(() => {
    feedId && dispatch(getReportData(feedId));
  }, [dispatch, feedId]);

  const handleChange = (value) => dispatch(selectChannel(value));

  const channelOptions =
    channels?.map((channel) => {
      return {
        value: channel.title,
        label: channel.title,
      };
    }) || [];

  return (
    <PageLoader loading={reportLoading} error={reportError} fadeOut={true}>
      <div className="report-page">
        <PageHeader headerId="reportsTitle" />
        <CustomDropdown
          items={channelOptions}
          className="opaque-container report-dropdown"
          labelText={<FormattedMessage id="dropdownLabel" />}
          selected={channelTitle}
          onChange={handleChange}
        />
        <Report data={data} locale="en" />
      </div>
    </PageLoader>
  );
};

export default ReportsPageContainer;
