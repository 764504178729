import React from 'react';
import { FormattedMessage } from 'react-intl';

const RemoveImageOverlay = ({ handleClick, thumbnail }) => {
  if (thumbnail) {
    return (
      <div className="photo-overlay">
        <button className="link" onClick={handleClick}>
          <FormattedMessage id="remove" defaultMessage="Remove" />
        </button>
      </div>
    );
  }
  return null;
};

export default RemoveImageOverlay;
