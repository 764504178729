const initialState = {
  loading: true,
  error: false,
  errorMessage: '',
  pageNotFound: false,
  items: [], // list of resources for container page
  item: {}, // for resource detail page
  pagination: {
    page: 1,
    totalPages: null,
  }
};

const resources = (state = initialState, action) => {
  const { type, payload } = action;

  switch(type) {
    case 'INITIALIZE_RESOURCES_PAGE':
      return {
        ...initialState,
        pagination: {
          ...state.pagination,
        }
      };
    case 'GET_RESOURCES_FETCHING':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: '',
      };
    case 'GET_RESOURCES_SUCCESS':
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        items: payload.items,
        pagination: {
          ...state.pagination,
          ...payload.pagination
        }
      };
    case 'GET_RESOURCES_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: 'Error loading resources. Please try again later!',
      };
    case 'GET_RESOURCE_FETCHING':
      return {
        ...state,
        loading: true,
        error: false,
        pageNotFound: false,
        errorMessage: '',
      };
    case 'GET_RESOURCE_SUCCESS':
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        pageNotFound: false,
        item: payload.item,
      };
    case 'GET_RESOURCE_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        pageNotFound: payload.pageNotFound || false,
        errorMessage: 'Error loading this resource. Please try again later!',
      };
    default:
      return state;
  };
};

export default resources;
