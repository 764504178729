import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, defineMessages } from "react-intl";
import { updateSubject, updateMessage } from "../../../redux/actions/contactUs";

class FormInputs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textAreaHeight: "auto",
      currRows: 0,
      maxHeight: 0,
    };
    this.inputRef = React.createRef();
    this.updateSubject = this.updateSubject.bind(this);
    this.updateMessage = this.updateMessage.bind(this);
    this.updateHeight = this.updateHeight.bind(this);
  };

  updateHeight() {
    const { currRows = 0, maxHeight = 0 } = this.state;
    const { maxRows = 5 } = this.props;
    let currHeight = this.inputRef.current.scrollHeight;

    if (currRows < maxRows) {
      if (currHeight > maxHeight) {
        this.setState({
          maxHeight: currHeight,
          currRows: currRows + 1,
        });
      }
      this.setState({ textAreaHeight: "auto" }, () => {
        this.setState({
          textAreaHeight: currHeight,
        })}
      );
    } else {
      return; //no need to resize after maxRows is hit
    }
  }

  updateMessage(e) {
    this.updateHeight();
    this.props.updateMessage(e.target.value);
  }

  updateSubject(e) {
    this.props.updateSubject(e.target.value);
  }

  render() {
    const {
      intl,
      subject = "",
      message = "",
      showMessageError = false,
      ignoreUpdates = false,
    } = this.props;

    const messages = defineMessages({
      subjectPlaceholder: {
        id: "subjectPlaceholder",
        defaultMessage: "Subject (Optional)",
      },
      messagePlaceholder: {
        id: "messagePlaceholder",
        defaultMessage: "Hi, I have a question about...",
      }
    });

    return (
      <Fragment>
        <div className="subject">
          <label htmlFor="subject">Subject</label>
          <input
            type="text"
            name="subject"
            value={ignoreUpdates ? "" : subject}
            placeholder={intl.formatMessage(messages.subjectPlaceholder)}
            onChange={this.updateSubject}
          />
        </div>
        <div className="message">
          <label htmlFor="message">Message</label>
          <textarea
            value={ignoreUpdates ? "" : message}
            name="message"
            maxLength={1000}
            rows="1"
            onChange={this.updateMessage}
            placeholder={intl.formatMessage(messages.messagePlaceholder)}
            className={showMessageError ? "error" : ""}
            ref={this.inputRef}
            style={{ height: this.state.textAreaHeight }}
          />
        </div>
        {showMessageError && (
          <div className="missing-message">
            <FormattedMessage
              id="missingMessage"
              defaultMessage="Please enter a message"
            />
          </div>
        )}
      </Fragment>
    );
  };
}

const mapStateToProps = ({ contactUs }, passedProps) => {
  return {
    ...contactUs,
    maxRows: passedProps?.maxRows || 5,
    ignoreUpdates: passedProps?.ignoreUpdates || false,
  };
}

const mapDispatchToProps = {
  updateSubject,
  updateMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(FormInputs));
