import React from 'react';
import ReactDOM from 'react-dom';
import './scss/app.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();

// serviceWorker.register({
//   onUpdate: registration => {
//     const waitingServiceWorker = registration.waiting;
//     // Newly cached data is available on the next page refresh
//     if (waitingServiceWorker) {
//       waitingServiceWorker.addEventListener("statechange", event => {
//         if (event.target.state === "activated") {
//           // we can reload the page here or add a button to the page to reload at this point
//           // window.location.reload()
//         }
//       });
//       // tell the service worker to update on the next refresh
//       waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
//     }
//   }
// });
