import React from 'react';
import { FormattedMessage } from 'react-intl';

const PageNotFoundContainer = () => {
  return (
    <div className="page-not-found-container" data-testid="page-not-found">
      <div className="overlay" />
      <div className="contents">
        <h2>
          <p>404</p>
          <FormattedMessage id="pageNotFound" defaultMessage="Page not found :(" />
        </h2>
        <p>
          <FormattedMessage id="oops" defaultMessage="Oooooups! Looks like you got lost." />
        </p>
      </div>
    </div>
  );
}

export default PageNotFoundContainer;
