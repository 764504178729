import ReactDOM from 'react-dom';
import React, { useEffect } from 'react';
import ModalContent from './ModalContent';

const modalRoot = document.createElement('div');
modalRoot.setAttribute('class', 'modal-portal');
modalRoot.dataset.testid = 'modal-portal';

const Modal = props => {
  useEffect(() => {
    const container = props.container || document.body;
    const divs = document.querySelectorAll('.main-nav, .app-content');
    const areas = document.querySelectorAll('.main-nav a, .main-nav button, .app-content a, .app-content button');

    container.appendChild(modalRoot);

    if (props.opened) {
      areas.forEach(area => area.tabIndex = -1);
      divs.forEach(div => div.setAttribute('aria-hidden', 'true'));
    } else {
      areas.forEach(area => area.tabIndex = 0);
      divs.forEach(div => div.setAttribute('aria-hidden', 'false'));
    }

    return () => {
      modalRoot.parentNode && modalRoot.parentNode.removeChild(modalRoot);
    };
  }, [props.opened, props.container]);

  if (props.opened) {
    return ReactDOM.createPortal(
      <ModalContent content={props.children} {...props} />,
      modalRoot,
    );
  } else {
    return null;
  }
};

export default Modal;
