import React from 'react';
import { FormattedNumber } from "react-intl";

export const secToMin = (s = 1) => {
  let min = s / 60;
  let flooredMin = Math.floor(min);
  let remainingSec = Math.round((min - flooredMin) * 60);
  return `${flooredMin}:${remainingSec < 10 ? `0${remainingSec}` : remainingSec}`;
};

// turns .018 into "1.8%"
export const toPercent = (num = 1, places = 1) => {
  return `${(num * 100).toFixed(places)}%`;
};

// 1000 to 1,000
export const getCommaDelimitedNumber = (num = 0) => {
  if (num !== null) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return "0";
  }
};

// 28,777,708 to 28.8M
export const abbreviateBigNumber = (num = 0, locale) => {
  const formatter = new Intl.NumberFormat(locale || "en");
  const numberParts = formatter.formatToParts(num);

  // number is at least 1,000,000... numbers in the thousands will be displayed as-is
  if (numberParts.length >= 5) {
    let numbers = numberParts.filter(d => d.type === "integer").map(d => d.value);

    for (let i = numbers.length - 1; i > 0; i--) {
      if (i === 1) { // round last 2 digits
        if (+numbers[i].slice(1) > 50) {
          numbers[i] = `${+numbers[i].slice(0,1) + 1}00`;
        }
      } else { // round 3 digit numbers
        if (+numbers[i] > 500) {
          numbers[i-1] = `${+numbers[i-1] + 1}`;
          if (numbers[i-1].length === 1) {
            numbers[i-1] = `00${numbers[i-1]}`;
          }
          if (numbers[i-1].length === 2) {
            numbers[i-1] = `0${numbers[i-1]}`;
          }
          numbers[i] = `000`;
        }
      }
    }

    const place = {
      3: "M",
      4: "B",
      5: "t",
      6: "Qa",
      7: "Qi",
    };

    return `${numbers[0]}.${numbers.slice(1).join(",").slice(0,1)}${place[numbers.length]}`;
  }

  return <FormattedNumber value={num} />;
};
