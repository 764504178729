import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ErrorPage from "./components/shared/ErrorPage";
import { navigateToPath, logError } from "./redux/actions/ui";
import { FormattedMessage } from "react-intl";

class ErrorHandler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };

    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.state.hasError && this.setState({ hasError: false });
    }
  }

  componentDidCatch(error, errorInfo) {
    this.props.dispatch(logError(error, errorInfo));
  }

  handleButtonClick() {
    this.props.dispatch(navigateToPath("/"));
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorPage
          handleButtonClick={this.handleButtonClick}
          buttonText={
            <FormattedMessage id="backToHome" defaultMessage="Back to Home" />
          }
        />
      );
    }
    return this.props.children;
  }
}

export default connect()(withRouter(ErrorHandler));
