import { combineReducers } from "redux";
import overview from "./overview";
import channel from "./channel";
import account from "./account";
import surveys from "./surveys";
import payment from "./payment";

export default combineReducers({
  overview,
  channel,
  account,
  surveys,
  payment,
});
