import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import CustomDropdown from '../../shared/customDropdown/CustomDropdown';
import channelsMap from '../../../util/channelsMap';
import { updateInputField } from '../../../redux/actions/ugcForm';

const PlatformField = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const messages = defineMessages({
    ugcWhichPlatform: { id: 'ugcWhichPlatform' },
    ugcPlatformFieldError: {id: 'ugcPlatformFieldError'},
  });

  const platform = useSelector(state => state.ugcForm.inputs.platform || '');
  const validationError = useSelector(state => state.ugcForm.validationErrors.platform);

  const handlePlatformChange = (value) => dispatch(updateInputField({ name: 'platform', value }));

  const platformOptions = Object.keys(channelsMap).map(channel => {
    return {
      label: channelsMap[channel].name,
      value: channelsMap[channel].name,
    };
  });

  return (
    <div className={`platform ${validationError ? 'invalid' : ''}`}>
      <CustomDropdown
        labelText={intl.formatMessage(messages.ugcWhichPlatform)}
        items={platformOptions}
        onChange={handlePlatformChange}
        selected={platform}
      />
      {validationError && <em className="error-message">{intl.formatMessage(messages.ugcPlatformFieldError)}</em>}
    </div>
  );
};

export default PlatformField;
