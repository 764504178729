import React from 'react';
import { FormattedMessage } from "react-intl";
import { statToValMap } from '../consts/consts';

const ChannelPerformanceRows = props => {
  return statToValMap.map((item, i, currArr) => {
    const key = Object.keys(currArr[i])[0];
    const channelReportData = props.data || [];
    const thisMonth = channelReportData[2] || {};
    const lastMonth = channelReportData[1] || {};
    const twoMonthsAgo = channelReportData[0] || {};

    //checking the (ex: views_percent_delta) is less than or greater than 0
    // > 0 sets variable to true which displays an up arrow
    const delta_val = `${key}_percent_delta`;
    let delta1 = lastMonth.channel?.[delta_val];
    let delta2 = thisMonth.channel?.[delta_val];

    const upArrow = (
      <i className="fa fa-arrow-up green" />
    );
    const downArrow = (
      <i className="fa fa-arrow-down red" />
    );

    return (
      <ul key={key} className="report-row">
        <li>{<FormattedMessage id={key} />}</li>
        <li>
          {props.channelValueFormat(twoMonthsAgo.channel?.[key], item.statType)}
          {/* showing up or down arrow if delta is > or < respectively */}
          {delta1 === null || delta1 === undefined ? "" : delta1 > 0 ? upArrow : downArrow}
        </li>
        <li>
          {props.channelValueFormat(lastMonth.channel?.[key], item.statType)}
          {delta2 === null || delta2 === undefined ? "" : delta2 > 0 ? upArrow : downArrow}
        </li>
        <li>
          {props.channelValueFormat(thisMonth.channel?.[key], item.statType)}
        </li>
      </ul>
    );
  });
};

export default ChannelPerformanceRows;
