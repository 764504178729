import React, { Component, Fragment } from "react";
import LoadingComponent from "./LoadingComponent";
import LoadedPageContent from "./LoadedPageContent";

class PageLoader extends Component {
  constructor(props) {
    super(props);
    this.timeout = null;
    this.state = {
      removeLoader: false
    };
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      this.props.loading ? this.resetState() : this.showLoadedPage();
    }
  }

  resetState() {
    this.setState({
      removeLoader: false
    });
  }

  showLoadedPage() {
    this.timeout = setTimeout(() => {
      this.setState({
        removeLoader: true
      });
    }, 400);
  }

  render() {
    const {
      loading = false,
      error = false,
      loadingMessage = null,
      pageNotFound,
      errorMessage,
      children,
      render,
      fadeOut,
      transparent,
      size = "large" // x-small, small, medium, large, x-large
    } = this.props;

    return (
      <Fragment>
        <LoadingComponent
          loadingMessage={loadingMessage}
          loading={loading}
          removeLoader={fadeOut ? this.state.removeLoader : !loading}
          size={size}
          transparent={transparent}
        />
        <LoadedPageContent
          pageContent={children ? children : render}
          error={error}
          errorMessage={errorMessage}
          pageNotFound={pageNotFound}
          loading={loading}
          transparent={transparent}
        />
      </Fragment>
    );
  }
}

export default PageLoader;
