import React from "react";
import { connect } from "react-redux";
import FormError from "./FormError";
import FormSuccess from "./FormSuccess";
import FormInputs from "./FormInputs";
import FormActions from "./FormActions";

const ContactUsFormContainer = ({
  error,
  success,
  maxRows,
  location,
  ignoreUpdates,
}) => {
  if (error && !ignoreUpdates) {
    return <FormError />;
  }

  if (success && !ignoreUpdates) {
    return <FormSuccess location={location} />;
  }

  return (
    <div className="contact-us-form">
      <div className="form">
        <FormInputs maxRows={maxRows} ignoreUpdates={ignoreUpdates} />
        <FormActions location={location} ignoreUpdates={ignoreUpdates} />
      </div>
    </div>
  );
};

const mapStateToProps = ({ contactUs }, passedProps) => {
  const { error = false, success = false } = contactUs;

  const {
    maxRows = 5,
    location = "",
    ignoreUpdates = false,
  } = passedProps || {};

  return {
    error,
    success,
    maxRows,
    location,
    ignoreUpdates,
  };
};

export default connect(mapStateToProps)(ContactUsFormContainer);
