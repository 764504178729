import { combineReducers } from "redux";
import mainNav from "./mainNav";
import user from "./user";
import documents from "./documents";
import ui from "./ui";
import blogOverview from "./blogOverview";
import blogDetail from "./blogDetail";
import report from "./report";
import opportunities from "./opportunities";
import amazonLinkBuilder from "./amazonLinkBuilder";
import settings from "./settings/index";
import resources from "./resources";
import dashboard from "./dashboard";
import contactUs from "./contactUs";
import ugcForm from "./ugcForm";
import notifications from "./notifications";

export default combineReducers({
  ui,
  user,
  mainNav,
  documents,
  blogOverview,
  blogDetail,
  report,
  opportunities,
  amazonLinkBuilder,
  settings,
  resources,
  dashboard,
  contactUs,
  ugcForm,
  notifications,
});
