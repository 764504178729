const initialState = {
  loading: true,
  error: false,
  errorMessage: "",
  errorType: "",
  pageNotFound: false,
  items: [],
  pagination: {
    page: 1,
    totalPages: null,
  },
};

const surveys = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "INITIALIZE_SURVEYS_PAGE":
      return {
        ...initialState,
        pagination: {
          ...state.pagination,
        },
      };
    case "GET_SURVEYS_FETCHING":
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case "GET_SURVEYS_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
        items: payload.items,
        pagination: {
          ...state.pagination,
          ...payload.pagination,
        },
      };
    case "GET_SURVEYS_ERROR":
      return {
        ...state,
        loading: false,
        error: true,
        errorType: "surveysLoadingError",
        errorMessage: "Error loading surveys. Please try again later!",
      };
    default:
      return state;
  }
};

export default surveys;
