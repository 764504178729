import en from './en';

const de = {
  ...en,
  greeting: 'Hallo {name}, willkommen in deinem instrumententafel',
  //Nav items
  dashboard: 'Instrumententafel',
  notifications: 'Benachrichtigungen',
  documents: 'Unterlagen',
  reports: 'Berichte',
  opportunities: 'Chancen',
  resources: 'Ressourcen',
  contactUs: 'Kontaktiere uns',
  // Footer Items
  privacyPolicy: '{company} Datenschutzerklärung',
  termsOfService: '{company} Terms of Service',
  allRightsReserved: 'Nutzungsbedingungen von {company}',
  redArrowCompany: 'Eine Red Arrow Studios Firma',
  needHelpContactUs: "Brauchen Sie Hilfe? Kontaktiere uns",
  // amazon link builder
  amazonAffiliateLinkBuilder: 'Amazon Affiliate Link Builder',
  singleItemLink: 'EINZELTEIL-LINK',
  multiItemLink: 'MEHRPUNKT-LINK',
  forMoreInfo: 'Weitere Informationen zum Amazon-Partnerprogramm',
  clickHere: 'klicke hier',
  pleaseNoteThat: '. Bitte beachten Sie, dass Personen mit Wohnsitz in AR & ME aus steuerlichen Gründen nicht teilnahmeberechtigt sind.',
  singleItemLinkBuilder: "EINZELTEIL-VERBINDUNGS-ERSTELLER",
  multiItemLinkBuilder: "MEHRZWECK-VERBINDUNGS-ERSTELLER",
  pasteALink: "Fügen Sie einen Link zu einem beliebigen Amazon-Produkt, einer Abteilung oder einem benutzerdefinierten Geschäft ein, um Ihren benutzerdefinierten Partner-Link zu erstellen.",
  pasteMultipleLinks: "Fügen Sie mehrere Amazon-Produktlinks in separate Zeilen ein, um Ihren benutzerdefinierten Partnerlink zu erstellen.",
  enterAUrlHere: "HIER EINE URL EINGEBEN",
  enterUrlsHere: "URLS HIER EINGEBEN",
  enterAValidAmazon: "Geben Sie einen gültigen Amazon- oder Joyo-Link ein.",
  enterValidAmazons: "Geben Sie gültige Amazon- oder Joyo-Links ein.",
  originalLink: "Ursprünglicher Link:",
  originalLinks: "Ursprüngliche Links:",
  anErrorMulti: "Beim Erstellen Ihres Amazon-Affiliate-Links ist ein Fehler aufgetreten! Bitte überprüfen Sie Ihre Links und versuchen Sie es später erneut.",
  anErrorSingle: "Beim Erstellen Ihres Amazon-Affiliate-Links ist ein Fehler aufgetreten! Bitte überprüfen Sie Ihren Link und versuchen Sie es später erneut.",
  createLink: "Link erstellen",
  copyLink: "Link kopieren",
  copied: "Kopiert!",
  createAnotherLink: "Erstellen Sie einen weiteren Link",
  startOver: "Von vorn anfangen",
  getStarted: "Loslegen",
  findAProduct: "1. Finden Sie ein Produkt auf Amazon",
  theFirstStep: "Der erste Schritt besteht darin, ein Produkt auf Amazon zu finden, das Sie mit Ihrer Zielgruppe teilen möchten.",
  copyAndPaste: "2. Kopieren und Einfügen im Link Builder",
  copyTheLink: "Kopieren Sie den Link des Produkts und fügen Sie ihn links in den Link Builder ein.",
  viewFullGuidelines: "Vollständige Richtlinien anzeigen",
  toolToBuild: "Tool zum Erstellen von Links zu Produkten bei Amazon als Amazon-Partner.",
  click: "Klicken",
  here: "hier",
  forTermsAndConditions: "für allgemeine Geschäftsbedingungen",
  // opportunities
  allOpportunities: "Alle Möglichkeiten",
};

export default de;
