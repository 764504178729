const initialState = {
  loading: true,
  error: false,
  errorMessage: "",
  items: [],
  // FYI: Document Type is not changeable anywhere in the UI, not necessary for it to be in State like this
  // documentType: "financial_statement", // creator_yt_asset_revenuel, creator_yt_video_revenue
  pagination: {
    page: 1,
    total: 0,
    totalPages: 0,
    per: 20,
  },
};

const documents = (state = initialState, action) => {
  const { type = "", payload = {} } = action;

  switch (type) {
    case "INITIALIZE_DOCUMENTS_PAGE":
      return initialState;

    case "GET_DOCUMENTS_FETCHING":
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };

    case "GET_DOCUMENTS_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
        items: payload.items || [],
        pagination: payload.pagination || {},
      };

    case "GET_DOCUMENTS_ERROR":
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return state;
  }
};

export default documents;
