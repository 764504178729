import React from 'react';
import PropTypes from 'prop-types';

const NativeDropdown = props => {
  return (
    <div className="native-dropdown">
      <label>
        <span className="label">{props.labelText}</span>
        <select onChange={props.handleChange} value={props.selected} placeholder={props.placeholder}>
          <option disabled={true} value="">{props.placeholder}</option>
          {(props.items || []).map(item => <option key={item.value} value={item.value}>{item.label}</option>)}
        </select>
        <i className="fas fa-chevron-down" />
      </label>
    </div>
  );
};

export default NativeDropdown;

NativeDropdown.propTypes = {
  labelText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  placeholder: PropTypes.string,
  items: PropTypes.array,
  handleChange: PropTypes.func,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])
};
