import React from 'react';

const PrevButton = ({ handleClick }) => {
  return (
    <li className="prev">
      <button className="prev" onClick={handleClick}>
        <span className="icon prev">
          <i className="icon fas fa-caret-left prev" />
        </span>
        <span className="text">Prev</span>
      </button>
    </li>
  );
};

export default PrevButton;
