import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { resetForm } from '../../../redux/actions/ugcForm';

const SuccessMessage = ({sendSuccess}) => {
  const dispatch = useDispatch();
  const handleReset = () => dispatch(resetForm());

  if (!sendSuccess) {
    return null;
  }

  return (
    <div className="success-message">
      <h4><FormattedMessage id="ugcSuccessMessage" /></h4>
      <button className="secondary" onClick={handleReset}>
        <FormattedMessage id="ugcSendAnother" />
      </button>
    </div>
  );
};

export default SuccessMessage;
