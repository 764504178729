import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "../modal/Modal";
import { closeContactUsModal } from "../../redux/actions/contactUs";
import { FormattedMessage } from "react-intl";
import ContactUsFormContainer from "../shared/contactUsForm/ContactUsFormContainer";

class ContactUsModal extends Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
  }
  closeModal() {
    this.props.dispatch(closeContactUsModal());
  }

  render() {
    return (
      <Modal
        className="contact-us-modal"
        opened={this.props.opened}
        closeModal={this.closeModal}
        closeOnOutsideClick={true}
        header={<FormattedMessage id="contactUs" defaultMessage="Contact Us" />}
        icon="fas fa-envelope"
      >
        <ContactUsFormContainer maxRows={5} location="modal" />
      </Modal>
    );
  }
}

export default connect()(ContactUsModal);
