import React, { Component } from 'react';
import { connect } from 'react-redux';
import getQueryStringParams from '../../util/getQueryStringParams';
import PageLoader from '../loader/PageLoader';
import BlogPreviewItem from './BlogPreviewItem';
import Pagination from '../shared/pagination/Pagination';

import { initBlogPage, getBlogPosts, loadBlogDetailPage, changeBlogPage } from '../../redux/actions/blog';
import PageHeader from '../shared/PageHeader';

class BlogOverviewContainer extends Component {
  constructor(props) {
    super(props);
    this.handlePaginationClick = this.handlePaginationClick.bind(this);
    this.handleBlogItemClick = this.handleBlogItemClick.bind(this);
  }

  componentDidMount() {
    const pageNumber = getQueryStringParams(this.props.location?.search).page || 1;
    this.props.dispatch(initBlogPage(pageNumber));
  }

  componentDidUpdate(prevProps) {
    if (this.props.location?.search !== prevProps.location?.search) {
      const pageNumber = getQueryStringParams(this.props.location?.search).page || 1;
      this.props.dispatch(getBlogPosts(pageNumber));
    }
  }

  handlePaginationClick(pageNumber) {
    this.props.dispatch(changeBlogPage(pageNumber));
  }

  handleBlogItemClick(id, title) {
    this.props.dispatch(loadBlogDetailPage(id, title));
  }

  render() {
    const { items, pagination, loading, error, errorMessage } = this.props;

    return (
      <PageLoader loading={loading} error={error} errorMessage={errorMessage} fadeOut={true}>
        <section className="blog-overview-page">
          <PageHeader headerId="blog" />
          <div className="blog-preview-area">
            {(items || []).map(blogItem => <BlogPreviewItem key={blogItem.ID} data={blogItem} handleClick={this.handleBlogItemClick}/>)}
          </div>
          <Pagination
            page={pagination.page}
            totalPages={pagination.totalPages}
            handleClick={this.handlePaginationClick}
            type="blog_overview"
          />
        </section>
      </PageLoader>
    );
  }
};

const mapStateToProps = ({blogOverview}) => {
  return blogOverview;
}

export default connect(mapStateToProps)(BlogOverviewContainer);
