import React from 'react';

const ChannelsIcon = () => {
  return (
    <svg viewBox="136 126 323 323">
      <g>
        <path d="M 426.7 416.7 L 345.95 416.7 L 345.95 335.95 L 426.7 335.95 Z M 426.7 303.65 L 345.95 303.65 C 328.1204 303.65 313.65 318.1204 313.65 335.95 L 313.65 416.7 C 313.65 434.5296 328.1204 449 345.95 449 L 426.7 449 C 444.5296 449 459 434.5296 459 416.7 L 459 335.95 C 459 318.1204 444.5296 303.65 426.7 303.65 Z M 249.05 416.7 L 168.3 416.7 L 168.3 335.95 L 249.05 335.95 Z M 249.05 303.65 L 168.3 303.65 C 150.48655 303.65 136 318.1204 136 335.95 L 136 416.7 C 136 434.5296 150.48655 449 168.3 449 L 249.05 449 C 266.86345 449 281.35 434.5296 281.35 416.7 L 281.35 335.95 C 281.35 318.1204 266.86345 303.65 249.05 303.65 Z M 426.7 239.05 L 345.95 239.05 L 345.95 158.3 L 426.7 158.3 Z M 426.7 126 L 345.95 126 C 328.1204 126 313.65 140.4704 313.65 158.3 L 313.65 239.05 C 313.65 256.8796 328.1204 271.35 345.95 271.35 L 426.7 271.35 C 444.5296 271.35 459 256.8796 459 239.05 L 459 158.3 C 459 140.4704 444.5296 126 426.7 126 Z M 249.05 239.05 L 168.3 239.05 L 168.3 158.3 L 249.05 158.3 Z M 249.05 126 L 168.3 126 C 150.48655 126 136 140.4704 136 158.3 L 136 239.05 C 136 256.8796 150.48655 271.35 168.3 271.35 L 249.05 271.35 C 266.86345 271.35 281.35 256.8796 281.35 239.05 L 281.35 158.3 C 281.35 140.4704 266.86345 126 249.05 126 Z" fill="black"/>
      </g>
    </svg>
  );
};

export default ChannelsIcon;
