import React, { Component } from 'react';
import { FormattedDate } from 'react-intl';
import FeaturedImage from '../shared/FeaturedImage';
import PageLoader from '../loader/PageLoader';

class BlogPreviewItem extends Component {
  constructor(props) {
    super(props);
    this.handleBlogItemClick = this.handleBlogItemClick.bind(this);
  }

  handleBlogItemClick() {
    this.props.handleClick(this.props.data?.ID, this.props.data?.post_title);
  }

  createMarkup() {
    const { post_content } = this.props.data || {};
    const decodedContent = decodeURIComponent(post_content || '').substr(0, 300).concat('...');
    return { __html: decodedContent };
  }

  render() {
    const { loading, error, errorMessage, data } = this.props;
    const { post_title, post_author, post_date, featured_image } = data || {};
    const hasImageClass = featured_image ? 'has-image' : '';
    const className = this.props.className ? this.props.className : '';

    return (
      <article className={`blog-preview-item opaque-container ${className} ${hasImageClass}`} onClick={this.handleBlogItemClick}>
        <PageLoader loading={loading} error={error} errorMessage={errorMessage} size="medium">
          {featured_image && <FeaturedImage imageUrl={featured_image} />}
          <div className="blog-content">
            <h4>{post_title}</h4>
            <div dangerouslySetInnerHTML={this.createMarkup()} />
          </div>
          <div className="blog-post-footer">
            <span className="author">{post_author}</span>
            <span className="posted-date">
              <FormattedDate
                value={(post_date || '').replace(' ', 'T')}
                year="numeric"
                month="long"
                day="2-digit"
              />
            </span>
          </div>
        </PageLoader>
      </article>
    );
  }
};

export default BlogPreviewItem;
