import ReactDOM from 'react-dom';
import React, { useEffect } from 'react';

const container = document.body;
const overlayRoot = document.createElement('div');
overlayRoot.setAttribute('class', 'overlay-portal');

const NavOverlay = props => {
  useEffect(() => {
    container.appendChild(overlayRoot);
    return () => {
      overlayRoot.parentNode.removeChild(overlayRoot);
    };
  });

  return ReactDOM.createPortal(
    <div className={`dimmer-overlay ${props.navVisibilityClass}`} />,
    overlayRoot,
  );
};

export default NavOverlay;
