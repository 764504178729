import React, { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { updateInputField } from '../../../redux/actions/ugcForm';

const InputField = forwardRef(({messageIds, inputName}, ref) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const inputValue = useSelector(state => state.ugcForm.inputs?.[inputName] || '');
  const validationError = useSelector(state => state.ugcForm.validationErrors?.[inputName]);
  const { labelId, placeholderId, errorId } = messageIds;
  const messageIdsArr = Object.keys(messageIds).map(id => messageIds[id]);
  const messages = defineMessages(messageIdsArr.reduce((obj, key) => {
    obj[key] = { id: key };
    return obj;
  }, {}));

  const handleInputChange = ({ target }) => {
    const { name, value } = target;
    dispatch(updateInputField({ name, value }));
  };

  return (
    <label className={validationError ? 'invalid' : ''}>
      {labelId && intl.formatMessage(messages[labelId])}
      <input
        ref={ref}
        name={inputName}
        type="text"
        onChange={handleInputChange}
        placeholder={intl.formatMessage(messages[placeholderId])}
        value={inputValue}
      />
      {validationError && <em className="error-message">{intl.formatMessage(messages[errorId])}</em>}
    </label>
  );
});

export default InputField;
