import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage} from 'react-intl';
import { getBlogDetail, goToBlog } from '../../redux/actions/blog';
import PageLoader from '../loader/PageLoader';
import PostDetailContainer from '../shared/PostDetailContainer';

class BlogDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.handleGoToBlogHomeClick = this.handleGoToBlogHomeClick.bind(this);
  }

  componentDidMount() {
    const id = this.props?.match?.params?.id;
    this.props.dispatch(getBlogDetail(id));
  }

  handleGoToBlogHomeClick() {
    this.props.dispatch(goToBlog());
  }

  createMarkup() {
    const decodedContent = decodeURIComponent(this.props.postContent || '');
    return { __html: decodedContent };
  }

  render() {
    const { loading, error, errorMessage, postTitle, postDate, postAuthor, featuredImage, pageNotFound } = this.props;
    const content = <div dangerouslySetInnerHTML={this.createMarkup()} />;
    const date = this.props.intl.formatDate((postDate || '').replace(' ', 'T'), {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    });

    return (
      <PageLoader loading={loading} error={error} errorMessage={errorMessage} pageNotFound={pageNotFound} fadeOut={true}>
        <section className="blog-detail-page">
          <button className="button pill-button primary" onClick={this.handleGoToBlogHomeClick}>
            <FormattedMessage id="allBlogPosts" defaultMessage="All Blog Posts" />
          </button>
          <PostDetailContainer
            header={postTitle}
            subheader={`${postAuthor} - ${date}`}
            content={content}
            featuredImage={featuredImage}
          />
        </section>
      </PageLoader>
    );
  }
};

const mapStateToProps = ({blogDetail}) => {
  return blogDetail;
};

export default connect(mapStateToProps)(injectIntl(BlogDetailContainer));
