import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import ContactUsFormContainer from "../shared/contactUsForm/ContactUsFormContainer";

// When the Contact Us Modal is open,
// this component ignores contactUs store updates
const DashboardContactForm = ({ contactUsModalOpen }) => {
  return (
    <div className="contact-form col-12 col-md-5 order-5 order-md-2">
      <div className="opaque-container">
        <div className="header-container">
          <div className="header-icon">
            <i className="fas fa-envelope" />
          </div>
          <h3 className="header-title">
            <FormattedMessage id="contactUs" defaultMessage="Contact Us" />
          </h3>
        </div>
        <ContactUsFormContainer
          maxRows={5}
          location="dashboard"
          ignoreUpdates={contactUsModalOpen}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ contactUs }) => {
  return {
    contactUsModalOpen: contactUs.contactUsModalOpen || false,
  };
};

export default connect(mapStateToProps)(DashboardContactForm);
