import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { navLinkItemClick } from "../../redux/actions/ga";
import Tooltip from "../shared/tooltip/Tooltip";
import BREAKPOINTS from "../../constants/breakpoints";

const navIcons = {
  dashboard: "fa-sharp fa-solid fa-grid-2",
  notifications: "far fa-calendar-check",
  documents: "fa fa-file-invoice-dollar",
  reports: "fa fa-chart-line",
  opportunities: "fa fa-shopping-cart",
  resources: "fa fa-toolbox",
};

const NavItems = ({ menuItems = [], activePage = "" }) => {
  const dispatch = useDispatch();
  const navVisibilityClass = useSelector(
    (state) => state.mainNav.navVisibilityClass,
  );
  const disableTooltip =
    navVisibilityClass === "opened" || window.innerWidth < BREAKPOINTS.DESKTOP;

  return menuItems.map((menuItem) => {
    const menuClassName = menuItem.toLowerCase().replace(/ /g, "-");
    const isActive = activePage === menuItem;

    return (
      <li
        key={menuClassName}
        className={`${menuClassName} ${isActive ? "active" : ""}`}
      >
        <NavLink
          activeClassName="selected"
          onClick={() => {
            dispatch(navLinkItemClick({ item: menuItem }));
          }}
          to={`/${menuClassName}`}
        >
          <Tooltip
            className="nav-tooltip"
            content={<FormattedMessage id={menuClassName} />}
            placement="right"
            disable={disableTooltip}
          >
            <span className="icon">
              <i className={navIcons[menuClassName] || "fa fa-th-large"} />
            </span>
          </Tooltip>
          <span className="link-text">
            <FormattedMessage id={menuClassName} />
          </span>
        </NavLink>
      </li>
    );
  });
};

export default NavItems;
